
class AnimalAction {
    /**
     * Animal Action Class
     */
    constructor(axios) {
        this.http = axios;
        
    }
    /**
     * Create one animal
     * @param {Object} data 
     * @returns Promise object
     */
    create(data) {
        return this.http.post('/api/animalbase/create.php', data);
    };
    /**
     * Read all animals
     * @returns Promise object
     */
    read() {
        return this.http.get('/api/animalbase/read.php');
    };
    /**
     * Read all owner animals
     * @param {string} ownerId owner id
     * @returns Promise object
     */
     readOwnerAnimals(id) {
        return this.http.get(`/api/animalbase/read_by_index.php?id=${id}`);
    };
    /**
     * Update one animal
     * @param {Object} data 
     * @returns Promise object
     */
    update(data) {
        return this.http.post('/api/animalbase/update.php', data);
    };
    /**
     * Delete one animal - NOT_IMPLEMENTED!
     * @param {Object} id animal id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };

}

export default AnimalAction;