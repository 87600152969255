import React from 'react';
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import DatabaseService from "../../../services/database.service"
import store from 'store'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class ImageUpload extends React.Component {


  constructor(props){
    super(props);
    this.state = {
    previewVisible: false,
    previewImage: '',
    fileList: this.parsePicturesJson(),
    dbList: this.parsePicturesJson()
  };
}

parsePicturesJson(){
  try{
      const parsedJson= JSON.parse(this.props.vetprofile.praxisbilder);
      return parsedJson.vetPictures;
  }
  catch(error){
    return [];
  }
}

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };


  handleChange = ({ file, fileList }) =>{
     this.setState({ fileList });
     if( file.response !== undefined && (this.state.dbList.length===0 || this.state.dbList[this.state.dbList.length-1].uid !== file.uid)){
        var newList= this.state.dbList.concat({ uid: file.uid, url: file.response.url});
        this.setState({ dbList:  newList});
        const data={vetPictures: newList };
        DatabaseService.vet.profile.update({vetID: store.get('usrId'), praxisbilder: JSON.stringify(data)})
    }
     console.log(JSON.stringify(this.state.dbList));
}


  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Praxisbild Hochladen</div>
      </div>
    );
    return (
      <div>
        <Upload
          action="http://localhost:8080/uploadVetPictures"
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          disabled={this.props.disabled}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export default ImageUpload
