import React from 'react';
import { List, Button,Collapse, Card, Skeleton,Descriptions, Typography, Row, Col, Select } from 'antd';
import {
    CloseOutlined,
    CheckOutlined,
} from '@ant-design/icons';
import AnimalsList from "./AnimalsList"
import DatabaseService from "../../../services/database.service";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import store from 'store';
import profile from "../../../Profile_Pic.png";
import Paper from '@material-ui/core/Paper';
import { Divider } from '@material-ui/core';
import HistoryModal from "../HistoryModal";
import { ButtonBase } from '@material-ui/core';
// https://github.com/prettymuchbryce/http-status-codes
import { StatusCodes as HttpStatus } from 'http-status-codes';

const {Option} = Select

class CallHistoryCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            calls : [],
            usrData : [],
            loading:true,
            selectedItem: null,
            dialogOpen:false
        }

        DatabaseService.callHistory
            .read(store.get('usrId'))
            .then(response => {
                console.log("CallHistoryCard.js: callHistory.read() response ", response);
                let callList = []
                if(response.status === HttpStatus.OK) {
                    callList = response.data.records.map(obj => {
                        const startObj = new Date();
                        startObj.setTime(obj.start);
                        const endObj = new Date();
                        endObj.setTime(obj.end);
                        obj.start = startObj;
                        obj.end = endObj;
                        console.log("CallHistoryCard.js: callHistory.read() then obj ", obj);
                        return obj;
                    });
                }
                this.setState({
                    calls: callList,
                    usrData: callList,
                    loading: false
                });
            })
            .catch(e => {
                console.log(e);
                this.setState({loading: false});
            });
    }


    render() {
        return(
            <Paper elevation={3} style={{display:"flex", flexDirection:"column", justifyContent:"space-around", padding: 25, overflow:"auto"}}>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", marginBottom:30}}>
                    <span style={{ fontSize:20, fontWeight:"bold"}}>Verlauf [CallHistoryCard.js]</span>
                    <Select defaultValue="heute" style={{ width: 120 }} onChange={this.handleDayChange}>
                        <Option value="heute">Heute</Option>
                    </Select>
                </div>
                <List
                    loading={this.state.loading}
                    itemLayout="horizontal"
                    dataSource={this.state.usrData}

                    renderItem={item => (
                        <List.Item>
                            <ButtonBase style={{width:"100%"}} onClick={()=>this.setState({dialogOpen: true, selectedItem:item})}>
                                {item.recording && <FiberManualRecordIcon style={{color:"red", marginRight:7}}/>}
                                <div style={{display:"flex", width:"100%"}}>
                                    <span style={{ display:"flex", justifyContent:"flex-start", fontWeight: "bold", flex:1}}>{item.vorname + " " + item.nachname +", " + item.name}</span>
                                    <span style={{ flex:1}}>{item.besuchsgrund}</span>
                                    <span style={{ flex:1}}>Abgehalten</span>
                                    <span style={{ flex:1}}>{item.start.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })}-{item.end.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })}</span>
                                    <span style={{ flex:1}}>{item.start.toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</span>
                                </div>
                            </ButtonBase>
                        </List.Item>
                    )}
                />
                    <HistoryModal open={this.state.dialogOpen} onClose={()=>this.setState({dialogOpen:false})} selectedEvent={this.state.selectedItem} user={this.state.selectedItem} />
            </Paper>
        );
    }

}

export default CallHistoryCard
