import React, { Component, useState } from "react";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { List, Card, Divider, Typography, Avatar } from 'antd';
import DatabaseService from "../../../services/database.service";
import FileList from "./filesList"
import {
    UserOutlined
} from '@ant-design/icons';
import "./AnimalsList.css"

const { Title } = Typography;
const gridStyle = {
    width: '50%',
    textAlign: 'center',
    padding: 0
};

class AnimalsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            animalInfo: [{ name: "", tierart: "", tierrasse: "", alter: "" }],
        }

        DatabaseService.AnimalOwner
            .readAllAnimals(this.props.usrID)
            .then(response => {
                console.log(response.data);
                this.setState({ animalInfo: response.data });
            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {
        return (
            <AnimateSharedLayout>
                <motion.list layout initial={{ borderRadius: 25 }}>
                    {this.state.animalInfo.map(item => (
                        <Item key={item} />
                    ))}
                </motion.list>
            </AnimateSharedLayout>
        );
    }

}

function Item() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
        <motion.listitem layout onClick={toggleOpen} initial={{ borderRadius: 10 }}>
            <motion.div style={{ height: "40px" }} layout >
                <Avatar size={40} icon={<UserOutlined />} />
                <span style={{ marginLeft: 15 }}>Xavi, Mops</span>
            </motion.div>
            <AnimatePresence>{isOpen && <Content />}</AnimatePresence>
        </motion.listitem>
    );
}

function Content() {
    return (
        <motion.div
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ display: "flex", flexDirection: "column" }}
        >
            <div style={{ height: "2px", backgroundColor: "grey", margin: 15 }} />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                <span>Alter</span>
                <span>5</span>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                <span>Tierrasse</span>
                <span>mops</span>
            </div>
        </motion.div>
    );
}

export default AnimalsList;
