import React from 'react';
import {Button} from "antd"
import { motion, AnimateSharedLayout } from "framer-motion";
import MeinKonto from "./MeinKonto"
import MeinProfil from "./MeinProfil";
import MeinePraxis from './MeinePraxis';
import Einstellungen from "./Einstellungen"
import databaseService from '../../../services/database.service';
import { ButtonBase } from '@material-ui/core';
import {  Link } from "react-router-dom";
import store from "store";

const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30
};

class Settings extends React.Component {

    constructor(props) {
        super(props);
        console.log("Settings.js: this.props ", this.props);
        this.state = {
            currentHeaderKey:"meinkonto"
        }
    }


    render() {
       return (
            <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", width:"100%", margin:18}}>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", width:"100%", fontSize: 22}}>
                    <AnimateSharedLayout>
                        <ButtonBase onClick={()=> this.setState({currentHeaderKey:'meinkonto'})} style={{ marginTop:10}}>
                            <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                                <span style={{fontWeight:"bold"}}>Mein Konto</span>
                                {this.state.currentHeaderKey==='meinkonto' && <motion.div className="horizontalLineMenu" layoutId="marker" transition={spring}/>}
                            </div>
                        </ButtonBase>
                        <ButtonBase onClick={()=> this.setState({currentHeaderKey:'meinprofil'})} style={{ marginTop:10, marginLeft:30}}>
                            <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                                <span style={{fontWeight:"bold"}}>Mein Profil</span>
                                {this.state.currentHeaderKey==='meinprofil'  && <motion.div className="horizontalLineMenu" layoutId="marker" transition={spring}/>}
                            </div>
                        </ButtonBase>
                        <ButtonBase onClick={()=> this.setState({currentHeaderKey:'praxis'})} style={{ marginTop:10, marginLeft:30}}>
                            <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                                <span style={{fontWeight:"bold"}}>Meine Praxis</span>
                                {this.state.currentHeaderKey==='praxis'  && <motion.div className="horizontalLineMenu" layoutId="marker" transition={spring}/>}
                            </div>
                        </ButtonBase>
                        <ButtonBase onClick={()=> this.setState({currentHeaderKey:'einstellungen'})} style={{ marginTop:10, marginLeft:30}}>
                            <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
                                <span style={{fontWeight:"bold"}}>Einstellungen</span>
                                {this.state.currentHeaderKey==='einstellungen'  && <motion.div className="horizontalLineMenu" layoutId="marker" transition={spring}/>}
                            </div>
                        </ButtonBase>
                    </AnimateSharedLayout>
                </div>
                <div>
                </div>
                {this.state.currentHeaderKey==="meinkonto" && <MeinKonto doctor={this.props.doctor} vetProfile={this.props.vetProfile} onChange={(data) => this.props.onChange('meinkonto', data)} />}
                {this.state.currentHeaderKey==="meinprofil" && <MeinProfil doctor={this.props.doctor} vetProfile={this.props.vetProfile} clinicProfile={this.props.clinicProfile} onChange={(data) => this.props.onChange('meinprofil', data)} />}
                {this.state.currentHeaderKey==="praxis" && <MeinePraxis doctor={this.props.doctor} clinic={this.props.clinic} clinicProfile={this.props.clinicProfile} onChange={(data) => this.props.onChange('praxis', data)} />}
                {this.state.currentHeaderKey==="einstellungen" && <Einstellungen doctor={this.props.doctor} vetProfile={this.props.vetProfile} onChange={(data) => this.props.onChange('meinprofil', data)} />}
            </div>
        );
    }

}

export default Settings
