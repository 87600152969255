import React from 'react';
import { List, Avatar, Collapse, Card, Skeleton, Descriptions, Typography, Row, Col, Divider } from 'antd';
import {
    CloseOutlined,
    CheckOutlined,
} from '@ant-design/icons';
import DatabaseService from "../../../services/database.service";
import Paper from '@material-ui/core/Paper';
import store from 'store';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { ButtonBase } from '@material-ui/core';
import UserProfileModal from "../UserProfileModal";
import { connect } from 'react-redux'

const { Panel } = Collapse;
const { Title } = Typography;
const { Text } = Typography;
const { Meta } = Card;

function mapStateToProps(state) {
    return { events: state.events }
}

class AppointmentsrequestCard extends React.Component {

    constructor(props) {
        super(props);
        console.log("AppointmentsrequestCard.js: props: ", this.props);
        this.state = {
            dialogOpen: false,
            nextEvent: {},
            eventCount: 0,
            requests: []
        }
    }

    componentDidMount() {
        this.filterEvents();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.events.length !== this.props.events.length)
            this.filterEvents()
    }

    /**
     * Filter unconfirmed events from now on
     */
    filterEvents() {
        const now = new Date();
        const events = this.props.events.filter((singleEvent, index) => {
            if (singleEvent.start.valueOf() > now.valueOf() && !Number(singleEvent.confirmed)) {
                return true;
            }
            else {
                return false;
            }
        });
        console.log("AppointmentsrequestCard.js: filterEvents() events ", events);
        this.setState({ eventCount: events.length, requests: events });
    }


    handleAccept(item) {
        DatabaseService.vet.event.update(item.id, { confirmed: true });
        DatabaseService.AnimalOwner
            .readOne(item.userID)
            .then(response => {
                //TODO: Test if user already exists
                DatabaseService.vet.patient
                    .create({
                        vetID: item.vetID,
                        userID: item.userID,
                        nachname: response.data.nachname,
                        vorname: response.data.vorname
                    })
                    .catch(e => {
                        console.log(e);
                    });
            })
            .catch(e => {
                console.log(e);
            });

    };

    handleDecline(item) {
        DatabaseService.vet.event
            .update(item.id, { confirmed: false })
            .catch(e => {
                console.log(e);
            });
    }

    isToday = (date) => {
        const today = new Date()
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    render() {
        if (this.props.events.length === 0)
            return (
                <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", padding: 25 }}>
                    <span style={{ fontSize: 20, fontWeight: "bold", marginBottom: 30 }}>Termin-Anfragen [AppointmentsrequestCard.js]</span>
                    <span style={{ fontWeight: "bold" }}>Keine Terminanfragen</span>
                </Paper>
            );
        return (
            <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", padding: 25, overflow: "auto" }}>
                <span style={{ fontSize: 20, fontWeight: "bold", marginBottom: 30 }}>Termin-Anfragen ({this.state.eventCount})</span>
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.requests}
                    renderItem={item => (
                        <List.Item style={{ width: "100%" }} >
                            <ButtonBase style={{ width: "100%" }} onClick={() => this.setState({ dialogOpen: true, nextEvent: item })}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%" }}>
                                    <div className="verticalLine" style={{ borderColor: "#25D2AA" }} />
                                    <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                        <span style={{ fontWeight: "bold" }}>{item.besuchsgrund}</span>
                                        <span >{item.name + ", " + item.vorname + " " + item.nachname}</span>
                                        <span style={{ color: "grey" }}>{item.tierart + ", " + item.tierAlter + " Jahre"}</span>
                                    </div>
                                    <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                        <span style={{ fontWeight: "bold" }}>
                                            {this.isToday(item.start) ? "Heute" : item.start.toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                                        </span>
                                        <span>{item.start.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })}</span>
                                        <span style={{ color: "grey" }}>{item.end.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })}</span>
                                    </div>
                                </div>
                            </ButtonBase>
                        </List.Item>
                    )}
                />
                <UserProfileModal startConf={this.props.startConf} open={this.state.dialogOpen} onClose={() => this.setState({ dialogOpen: false })} selectedEvent={this.state.nextEvent} user={this.state.nextEvent} />
            </Paper>
        );

        // return (
        //     <Card title={<Title level={3}>Anfragen</Title>} bodyStyle={{ height: "89%", overflow: "auto", padding: 10 }} style={{ height: "100%" }}>
        //         <List
        //             itemLayout="horizontal"
        //             dataSource={this.state.requests}
        //             renderItem={item => (
        //                 <List.Item style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
        //                     <Card
        //                         style={{ textAlign: "center" }}
        //                         actions={[
        //                             <CloseOutlined onClick={() => this.handleDecline(item)} />,
        //                             <DateRangeIcon />,
        //                             <CheckOutlined onClick={() => this.handleAccept(item)} />,
        //                         ]}
        //                     >
        //                         <Skeleton loading={!this.state.loading} active>
        //                             {this.state.loading ?
        //                                 <Meta
        //                                     title={<div style={{ display: "flex", flexDirection: "column", fontSize: 20 }}>
        //                                         <Text strong>{item.start.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" }) + " - " + item.end.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })}</Text>
        //                                         <Text strong>{item.start.toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Text>
        //                                     </div>}
        //                                     description={<div style={{ display: "flex", width: "300px", flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
        //                                         <span style={{ color: "#1e90ff", textAlign: "center", fontSize: 25 }}>Besitzer:</span>
        //                                         <span style={{ color: "black", textAlign: "center", fontSize: 25, marginBottom: 15 }}>{this.state.users.get(item.userID).vorname + " " + this.state.users.get(item.userID).nachname}</span>
        //                                         <span style={{ color: "#1e90ff", textAlign: "center", fontSize: 25 }}>Patient:</span>
        //                                         <div style={{ color: "black", display: "flex", flexDirection: 'column', justifyContent: 'space-around', alignContent: 'stretch', height: "90%", width: "90%" }}>
        //                                             <span style={{ color: "#1e90ff", fontSize: 30, fontWeight: 'bold', textAlign: 'center' }}>{this.state.pets.get(item.petId).name}</span>
        //                                             <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', margin: 8 }}>
        //                                                 <div>
        //                                                     <span style={{ fontSize: 25, color: "#1e90ff" }}>Tierart: </span>
        //                                                     <span style={{ color: "black", fontSize: 25 }}>{this.state.pets.get(item.petId).tierart}</span>
        //                                                 </div>
        //                                                 <div>
        //                                                     <span style={{ fontSize: 25, color: "#1e90ff" }}>Alter: </span>
        //                                                     <span style={{ color: "black", fontSize: 25 }}>{this.state.pets.get(item.petId).tierAlter}</span>
        //                                                 </div>
        //                                             </div>
        //                                         </div>
        //                                     </div>}
        //                                 />
        //                                 :
        //                                 <div />}
        //                         </Skeleton>
        //                     </Card>
        //                 </List.Item>
        //             )}
        //         />
        //     </Card>
        // );
    }

}

export default connect(mapStateToProps)(AppointmentsrequestCard)
