
class NotificationAction {
    /**
     * Notification Action Class
     */
    constructor(axios) {
        this.http = axios;        
    }
    
    
    /**
     * Create one notification
     * @param {Object} data 
     * @returns Promise object
     */
    create(data) {
        return this.http.post("/api/notifications/create.php", data);
    };
    /**
     * Read all notifications - NOT_IMPLEMENTED!
     * @returns Promise object
     */
    read() {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Read one notification
     * @param {Object} id notification id
     * @returns Promise object
     */
    readOne(id) {
        return this.http.get(`/api/notifications/read_by_index.php?id=${id}`);
    };
    /**
     * Update one notification - NOT_IMPLEMENTED!
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Delete one notification - NOT_IMPLEMENTED!
     * @param {Object} id notification id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };


}

export default NotificationAction;