// @flow

import React from 'react';

import { IconCheck, IconExclamationSolid } from './icons';

/**
 * React {@code Component} representing an entry for the audio settings.
 *
 * @returns { ReactElement}
 */
export default function AudioSettingsEntry({ children, hasError, isSelected }) {
    const className = `audio-preview-entry ${isSelected
        ? 'audio-preview-entry--selected' : ''}`;

    return (
        <div className = { className }>
            {isSelected && (
                <IconCheck
                    className = 'audio-preview-icon audio-preview-icon--check'
                    color = '#1C2025'/>
            )}
            <span className = 'audio-preview-entry-text'>{children}</span>
            {hasError && <IconExclamationSolid
                className = 'audio-preview-icon audio-preview-icon--exclamation'/>}
        </div>
    );
}
