import React from "react"
import { Layout, Menu, Button, Avatar, List, Dropdown, Form, Image } from 'antd';
import { UserOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import Divider from '@material-ui/core/Divider';
import DatabaseService from "../../../services/database.service"
import profile from "../../../Profile_Pic.png";
import { ButtonBase } from "@material-ui/core";

class ProfilePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            clinicInfo: {},
            leistungsspektrum: [],
            oeffnungszeiten: []
        }

        DatabaseService.vet.profile
            .readOne(this.props.doctor)
            .then(response => {
                this.setState({
                    clinicInfo: response.data.clinic,
                    fileList: this.parsePicturesJson(response.data.vet.vetPictures),
                    name: response.data.vet.name,
                    about: response.data.vet.about,
                    leistungsspektrumDesc: response.data.vet.leistungsspektrum,
                    website: response.data.vet.website,
                    zugangsinformationen: response.data.vet.zugangsinformationen,
                    behandlerprofil: response.data.vet.behandlerprofil,
                    leistungsspektrum: this.parseJson(response.data.vet.leistungsspektrumTags),
                    oeffnungszeiten: this.parseJson(response.data.vet.oeffnungszeiten),
                    sprachenString: response.data.vet.sprachen,
                    profilePic: response.data.vet.profilePic,
                    about: response.data.vet.about,
                    leistungsspektrumDesc: response.data.vet.leistungsspektrum,
                    website: response.data.vet.website,
                    zugangsinformationen: response.data.vet.zugangsinformationen,
                    behandlerprofil: response.data.vet.behandlerprofil,
                    sprachenString: response.data.vet.sprachen,
                    addresse: response.data.vet.addresse
                })
            })
            .catch(e => {
                console.log(e);
            });
    }

    parseJson(value) {
        try {
            return JSON.parse(value)
        }
        catch (error) {
            return [];
        }
    }

    parsePicturesJson(value) {
        try {
            const parsedJson = JSON.parse(value);
            return parsedJson.vetPictures;
        }
        catch (error) {
            return [];
        }
    }

    render() {
        return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", width: "100%", backgroundColor: "#fffcf7" }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch", width: "70%", marginRight: 20 }}>

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: 25, margin: 30 }}>
                        <Avatar size={150} src={this.state.profilePic ? this.state.profilePic : profile} />
                        <div style={{ display: "flex", marginLeft: 20, flexDirection: "column" }}>
                            <span style={{ fontWeight: "bold", fontSize: 20 }}>{this.state.name}</span>
                            <span style={{ fontWeight: "bold", marginTop: 7 }}>Facharzt für Innere Medizin</span>
                            <span style={{ color: "grey", marginTop: 7 }}>{this.state.addresse}</span>
                            <span style={{ color: "grey", width: "500px", marginTop: 14 }}>{this.state.about}</span>
                        </div>
                    </div>
                    <Divider />
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 25, margin: 30 }}>
                        <span style={{ fontWeight: "bold", fontSize: 20 }}>Leistungsspektrum</span>
                        <span style={{ color: "grey", marginTop: 7 }}>{this.state.leistungsspektrumDesc}</span>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {this.state.leistungsspektrum && this.state.leistungsspektrum.map(elem => {
                                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", borderRadius: "3px", backgroundColor: "#f4f1ea", padding: 5, margin: 10 }}>
                                    <span style={{ fontSize: 17, margin: 4 }}>{elem}</span>
                                </div>
                            })
                            }
                        </div>
                    </div>
                    <Divider />
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: 25, margin: 30 }}>
                        <span style={{ fontWeight: "bold", fontSize: 20 }}>Addresse und Zugangsinformation</span>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 15 }}>
                            <ButtonBase onClick={() => this.props.selectClinic(undefined, { key: this.state.clinicInfo.clinicID })}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                    <span style={{ fontWeight: "bold", marginTop: 20 }}>{this.state.clinicInfo.name}</span>
                                    <span style={{ color: "grey", marginTop: 7 }}>{this.state.clinicInfo.addresse}</span>
                                </div>
                            </ButtonBase>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginLeft: 70 }}>
                                <span style={{ fontWeight: "bold", marginTop: 20 }}>Zugangsinformationen</span>
                                <span style={{ color: "grey", marginTop: 7 }}>{this.state.zugangsinformationen}</span>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 25, margin: 30 }}>
                        <span style={{ fontWeight: "bold", fontSize: 20 }}>Behandlerprofil</span>
                        <span style={{ width: "500px", marginTop: 7 }}>{this.state.behandlerprofil} </span>
                        <div style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
                            {this.state.fileList.map(image => {
                                return (<Image
                                    style={{ margin: 10 }}
                                    height={100}
                                    src={image.url}
                                />);
                            })}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
                            <div style={{ display: "flex", flexDirection: "column", width: "20vw" }}>
                                <span style={{ fontWeight: "bold", marginTop: 7 }}>Gesprochene Sprachen</span>
                                <span style={{ marginTop: 7 }}>{this.state.sprachenString}</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ fontWeight: "bold", marginTop: 7 }}>Website</span>
                                <span style={{ width: "500px", marginTop: 7, color: "#25D2AA" }}>Website öffnen</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProfilePage