
class VetMeetingAction {
    /**
     * VetMeeting Action Class
     */
    constructor(axios) {
        this.http = axios;
    }


    /**
     * Create one vet meeting
     * @param {Object} data
     * @returns Promise object
     */
    create(data) {
        return this.http.post("/api/vetmeetings/create.php", data);
    };
    /**
     * Read all meetings belong to vet
     * @param {Object} vetId vet id
     * @returns Promise object
     */
    read(vetId) {
        return this.http.get(`/api/vetmeetings/read_by_index.php?id=${vetId}`);
    };
    /**
     * Read one vet meeting
     * @param {Object} id vet id
     * @param {Object} meetingId meeting id
     * @returns Promise object
     */
    readOne(vetId, meetingId) {
        return this.http.get(`/api/vetmeetings/get_current_meeting.php?id=${vetId}&meetingID=${meetingId}`);
    };
    /**
     * Read one meeting
     * @param {Object} meetingId meeting id
     * @returns Promise object
     */
    readbymeetid(meetingId) {
        return this.http.get(`/api/vetmeetings/get_by_id.php?meetingID=${meetingId}`);
    };
    /**
     * Update one vet meeting
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Update confirmed value of meeting
     * @param {Object} meetingId meetind id
     * @returns Promise object
     */
     updateConfirmed(meetingId) {
        return this.http.get(`/api/vetmeetings/update_confirmed.php?meetingID=${meetingId}`);
    };
    /**
     * Delete one vet meeting
     * @param {Object} id vet id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
}

export default VetMeetingAction;
