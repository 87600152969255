import React from "react";
import Logo from "./assets/HelloVetLogo.png"
import CheckIcon from "./assets/CheckIcon.png"
import Background from "./assets/BackgroundLeft.png"
import Corner from "./assets/InfosCorner.png"
import { Form, Input, Button, Checkbox, Select } from 'antd';
import DatabaseService from "../services/database.service";
import {  Redirect } from "react-router-dom";
import Axios from "axios";
import store from "store"
import { sha256 } from 'js-sha256';
import ChatMGMT from "../http-chat-mgmt"

import whereAmI from "../whereAmI";

const { Option } = Select;

// TODO: replace clinicId
const FAKE_CLINIC_ID = 1;
const FAKE_VISIBLE_FIELDS = 0;


class Registrieren extends React.Component  {

    constructor(props) {
        super(props);
        this.lastFetchId = 0;
        this.state = {
            data: [],
            value: [],
            fetching: false,
            newClinicSelected:false,
            submitted:false
        };
        console.log("ChatMGMT ", ChatMGMT.defaults);
    }

    async rollback(vetId) {
        console.log("Rollback vet ", vetId);
        await DatabaseService.vet.delete(vetId);
        await DatabaseService.vet.profile.delete(vetId);
    }

    onFinish = values => {
        DatabaseService.vet
            .checkEmail(values.email)
            .then(response => {
                if (response.data.result) {
                    console.log("E-mail verfügbar!");
                    return;
                }
                else {
                    const data = {
                        titel: values.titel,
                        vorname: values.vorname,
                        nachname: values.nachname,
                        fachrichtung: values.beschaeftigung,
                        zusatzausbildung: "",
                        email: values.email,
                        passwort: sha256(values.passwort),
                        clinicID: FAKE_CLINIC_ID,
                        clinicAdmin: false,
                        clinicAccepted: false
                    };

                    DatabaseService.vet
                        .create(data)
                        .then(response => {
                            console.log("Vet created ", response);
                            const vetId = response.data.id;
                            // Deaktiviert, solande der Rocket Chat noch nicht erreichbar ist
                            ChatMGMT.post('/create-account', {
                                mail:values.email,
                                name: (values.titel ? values.titel + " " : "") + values.vorname + " " + values.nachname,
                                password: sha256(data.passwort), // das gehashte Passwort, wird nochmal gehasht
                                username: "vet." + response.data.id
                            })
                            .then(x => {
                                // TODO: Platzhalter clinic anpassen. Wie kann eine Klinik schon bei der Registrierung eines Arztes zugewiesen werden, wenn es noch gar keine gibt?
                                let profileData = {
                                    vetID: response.data.id,
                                    name: (values.titel ? values.titel + " " : "") + values.vorname + " " + values.nachname,
                                    ausbildung: values.beschaeftigung,
                                    clinic: FAKE_CLINIC_ID,
                                    visibleFields: FAKE_VISIBLE_FIELDS,
                                };
                                DatabaseService.vet.profile
                                    .create(profileData)
                                    .then(profileResponse => {
                                        DatabaseService.registration.mail
                                        .create({
                                            mail: values.email,
                                            token: response.data.token,
                                            vetID: vetId,
                                            origin: whereAmI()
                                        });
                                        this.setState({
                                            submitted: true
                                        });
                                    })
                                    .catch(e => {
                                        console.log("Registrieren.js: vet.profile.create() ", e);
                                        this.rollback(vetId);
                                        return;
                                    });
                            })
                            .catch(async e => {
                                // TODO: rollback createVets() -> ~deleteVets()
                                console.log("Registrieren.js: /create-account ", e);
                                this.rollback(vetId);
                            });
                        })
                        .catch(e => {
                            console.log("Registrieren.js: vet.create() ", e);
                            return;
                        });
                };  // else
            })
            .catch(e => {
                console.log("Registrieren.js: vet.checkEmail() ", e);
                return;
            });
    };

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    render() {
        const { fetching, data, value } = this.state;
        if(this.state.submitted){
            return(
                <Redirect to="validation-required"/>
            );
        }

        return (
            <div style={{ width: "100%", height: "100vh", display: "flex", flexDirection: "row" }}>
                <img src={Corner} style={{ position: "absolute", right: "0px", top: "0px", height: "15%", zIndex: 0 }} />
                <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundImage: `url(${Background})`, backgroundPosition: "left center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    <img src={Logo} style={{ width: "400px" }} />
                    <div style={{ display: "flex", flexDirection: "column", width: "500px" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <img src={CheckIcon} style={{ height: "80px", marginTop: 15 }} />
                            <span style={{ marginTop: 15, fontSize: 22, fontFamily: "WorkSans" }} >Bequem von Zuhause ohne Anfahrt und Wartezimmerstress für dein Tier</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <img src={CheckIcon} style={{ height: "80px", marginTop: 15 }} />
                            <span style={{ marginTop: 15, fontSize: 22, fontFamily: "WorkSans" }} >Beratung per Videochat durch erfahrene Tierärzte</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <img src={CheckIcon} style={{ height: "80px", marginTop: 15 }} />
                            <span style={{ marginTop: 15, fontSize: 22, fontFamily: "WorkSans" }} >Löst viele Probleme ohne weiteren Arztbesuch im Anschluss</span>
                        </div>
                    </div>
                </div>
                <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <span style={{ marginBottom: 30, fontSize: 22, fontFamily: "WorkSans", fontWeight: "bold" }} >Jetzt kostenlos registrieren</span>
                    <Form
                        layout="vertical"
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >

                        <Form.Item
                            label="Art der Beschäftigung"
                            name="beschaeftigung"
                            rules={[{ required: true, message: 'Bitte eine Auswahl treffen!' }]}
                        >
                            <Select placeholder="Bitte auswählen">
                                <Select.Option value="tierarzt">Tierarzt</Select.Option>
                                <Select.Option value="tfa">Tierfachangestellter</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Titel"
                            name="titel"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Vorname"
                            name="vorname"
                            rules={[{ required: true, message: 'Bitte Vorname eingeben!' }]}
                            style={{ display: 'inline-block', width: 'calc(50% - 10px)' }}
                        >
                            <Input placeholder="Max" />
                        </Form.Item>
                        <Form.Item
                            label="Nachname"
                            name="nachname"
                            rules={[{ required: true, message: 'Bitte Nachname eingeben!' }]}
                            style={{ display: 'inline-block', width: 'calc(50% - 10px)', margin: '0 10px' }}
                        >
                            <Input placeholder="Mustermann" />
                        </Form.Item>

                        <Form.Item
                            label="E-Mail Addresse"
                            name="email"
                            rules={[{ required: true, message: 'Bitte E-Mail eingeben!' }]}
                        >
                            <Input placeholder="max.mustermann@net.de" />
                        </Form.Item>

                        <Form.Item
                            label="Passwort"
                            name="passwort"
                            rules={[{ required: true, message: 'Bitte Passwort eingeben!' }]}
                        >
                            <Input.Password />
                        </Form.Item>


                        <Form.Item
                            name="agb"
                            valuePropName="checked"
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject('Geschäftsbedingungen zustimmen!'),
                                },
                            ]}
                        >
                            <Checkbox>Ich habe die AGBs gelesen und stimme zu</Checkbox>
                        </Form.Item>

                        <Form.Item >
                            <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "45px", fontSize: 18 }} shape="round" htmlType="submit" block>
                                Kostenlos registrieren
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }
}

export default Registrieren
