class DocumentsAction {
    /**
     * VetPatient Action Class
     */
    constructor(axios) {
        this.http = axios;
    }

    /**
     * Create one file belong to vet
     * @param {Object} data
     * @returns Promise object
     */
    create(data) {
        return this.http.post("/api/documents/create.php", data);
    };

    /**
     * Read all files belong to vet
     * @param {Object} vetId vet id
     * @returns Promise object
     */
     read(vetId) {
        return this.http.get(`/api/documents/read_by_index.php?id=${vetId}`);
    };
}

export default DocumentsAction;
