import React from "react"
import _ from 'lodash'
import MicOffIcon from '@material-ui/icons/MicOff';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

class Remote extends React.Component {
    constructor(props) {
        super(props);
        console.log("Remote.js: props: ", this.props);
        this.state = {
            videoID: "",
            audioID: "",
            isMuted: false
        }
        this.videoRef = React.createRef()
        this.audioRef = React.createRef()
    }

    componentDidMount() {
        console.log("Remote.js: componentDidMount(): this.videoRef ", this.videoRef);
        let videoTrack = _.find(this.props.tracks, { type: 'video' })
        let audioTrack = _.find(this.props.tracks, { type: 'audio' })

        if (audioTrack || audioTrack) {
            if (videoTrack) {
                if (this.videoRef.current)
                    videoTrack.track.attach(this.videoRef.current)

                this.setState({ videoID: videoTrack.id });
            }
            if (audioTrack) {
                if (this.audioRef.current)
                    audioTrack.track.attach(this.audioRef.current)

                this.setState({ audioID: audioTrack.id });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tracksUpdated !== this.props.tracksUpdated) {
            let videoTrack = _.find(this.props.tracks, { type: 'video' })
            let audioTrack = _.find(this.props.tracks, { type: 'audio' })

            if (audioTrack || audioTrack) {
                if (videoTrack) {
                    if (this.videoRef.current)
                        videoTrack.track.attach(this.videoRef.current)

                    this.setState({ videoID: videoTrack.id });
                }
                if (audioTrack) {
                    if (this.audioRef.current)
                        audioTrack.track.attach(this.audioRef.current)

                    this.setState({ audioID: audioTrack.id });
                }
            }
        }

    }

    componentWillUnmount() {
        let videoTrack = _.find(this.props.tracks, { id: this.state.videoID })
        let audioTrack = _.find(this.props.tracks, { id: this.state.audioID })
        if (videoTrack) {
            try {
                if (this.videoRef.current) {
                    videoTrack.track.detach(this.videoRef.current)
                }
            } catch (error) {
                console.log(error.message)
            }
        }
        if (audioTrack) {
            try {
                if (this.audioRef.current) {
                    audioTrack.track.detach(this.audioRef.current)
                }
            } catch (error) {
                console.log(error.message)
            }
        }
    }

    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                    <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <IconButton onClick={() => this.setState({ isMuted: !this.state.isMuted })}>
                            <MicOffIcon />
                        </IconButton >
                    </div>
                    <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <span>{this.props.participant && this.props.participant.getDisplayName()}</span>
                    </div>
                    <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button variant="contained" color="primary" onClick={() => this.props.kickParticipant(this.props.participantId)}>
                            Kick
                        </Button>
                    </div>
                </div>
                <div style={{ backgroundColor: "white", width: "100%", height: "100%", borderRadius: 5, borderColor: this.props.currentSpeaker === this.props.participantId ? "#1890ff" : "black", borderStyle: "solid", borderWidth: "3px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
                    <video autoPlay='1' ref={this.videoRef} style={{ width: "100%", height: "100%", borderRadius: 2 }} />
                    <audio autoPlay='1' muted={this.state.isMuted} ref={this.audioRef} />
                </div>
            </div>
        );
    }
}

export default Remote
