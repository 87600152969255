import { createSlice } from '@reduxjs/toolkit'

const eventsSlice = createSlice({
    name: 'events',
    initialState: [],
    reducers: {
        initialize: (state, action) => {
            var mappedData = action.payload.map((singleEvent) => {
                return {
                    ...singleEvent,
                    confirmed: singleEvent.confirmed,
                    title: Number(singleEvent.confirmed) ? "Bestätigt" : "Terminanfrage",
                    start: new Date(singleEvent.time),
                    end: new Date(new Date(singleEvent.time).getTime() + singleEvent.slotlength * 60000)
                };
            });

            mappedData.sort(function (a, b) {
                return b.start.valueOf() - a.start.valueOf();
            });

            return mappedData;
        },
        addEvent: (state, action) => {
            var appEvent = {
                ...action.payload,
                confirmed: action.payload.confirmed,
                title: Number(action.payload.confirmed) ? "Bestätigt" : "Terminanfrage",
                start: new Date(action.payload.time),
                end: new Date(new Date(action.payload.time).getTime() + action.payload.slotlength * 60000)
            };

            state.push(appEvent);

            state.sort(function (a, b) {
                return b.start.valueOf() - a.start.valueOf();
            });

            return state;
        }
    }
})

export const { initialize, addEvent } = eventsSlice.actions

export default eventsSlice.reducer
