import axios from "axios";
import 'dotenv/config';
import whereAmI from "./whereAmI";

export default axios.create({
    baseURL: whereAmI() + process.env.REACT_APP_API_QUERY_URL,
    headers: {
        "Content-type": "application/json"
    }
});
