import React from 'react';
import Paper from '@material-ui/core/Paper';
import { List, Avatar, Collapse, Card, Skeleton, Descriptions, Typography, Row, Col, Divider } from 'antd';
import { Calendar, Views, momentLocalizer, dateFnsLocalizer } from "../react-big-calender/index";
import "../react-big-calender/sass/styles.scss";
import EventDialog from "./eventDialog";
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import UserProfileModal from "./UserProfileModal"
import moment from "moment"
import 'moment/locale/de';

/*
const locales = {
    'de-DE': require('date-fns/locale/de-DE'),
}
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})
*/

const localizer = momentLocalizer(moment);

const { Panel } = Collapse;
const { Title } = Typography;
const { Meta } = Card;

class CalendarCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openEvent: false,
            calendarevents: []
        };

        for (var i=0;i<this.props.events.length;i++) {
            var start = new Date(this.props.events[i].time);
            var ms = start.getTime();
            ms = ms + this.props.events[i].slotlength * 60 * 1000;
            var end = new Date(ms);

            if (this.props.events[i].confirmed == 1) {
                this.state.calendarevents.push({
                    'title': this.props.events[i].besuchsgrund + " " + this.props.events[i].name + " | " + this.props.events[i].vorname + " " + this.props.events[i].nachname,
                    'allDay': false,
                    'start': start,
                    'end': end
                });
            }
        }
    }

    handleEventClose() {
        this.setState({ openEvent: false });
    }

    render() {
        return (
            <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: 25, overflow: "hidden", height: "70vh", width: "60vw" }}>
                    <span style={{ fontSize: 20, fontWeight: "bold" }}>Kalender</span>
                    <Calendar
                        selectable
                        localizer={localizer}
                        events={this.state.calendarevents}
                        defaultView={Views.WEEK}
                        culture={'de'}
                        scrollToTime={new Date(1970, 1, 1, 6)}
                        defaultDate={new Date()}
                        views={["week", "day"]}
                        style={{ padding: 20, width: "100%", backgroundColor: "white" }}
                        onSelectEvent={event => this.setState({ currentEvent: event, openEvent: true })}
                    />
                    <UserProfileModal open={this.state.openEvent} onClose={() => this.handleEventClose()} selectedEvent={this.state.currentEvent} user={this.state.currentEvent} />
                </Paper>
            </div>
        );
    }

}

export default CalendarCard
