import axios from "axios";
import 'dotenv/config';

const hostname = window.location.hostname.replace("/register-validated", "");
// console.log("http-common-origin.js: hostname ", window.location.hostname, hostname);

/**
 * @description Workaround: Im Registrierungsschritt Nr 2 muss die URL angepasst werden.
 * Die Portnummer und ein Teil des vorher durch die API hinzugefügten Query
 * 'register-validated' entfernt.
 * @returns Axios Instanz mit protocol + hostname + REACT_APP_API_QUERY_URL
 */
export default axios.create({
    baseURL: window.location.protocol + '//' + hostname + process.env.REACT_APP_API_QUERY_URL,
    headers: {
        "Content-type": "application/json"
    }
});