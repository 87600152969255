import React from 'react';
import ReactDOM from 'react-dom'

function copyStyles(sourceDoc, targetDoc) {
    Array.from(sourceDoc.querySelectorAll('link[rel="stylesheet"], style'))
        .forEach(link => {
            targetDoc.head.appendChild(link.cloneNode(true));
        })
}

class WindowPortal extends React.PureComponent {
    constructor(props) {
        super(props);
        console.log("Portal.js: props: ", this.props);
        // STEP 1: create a container <div>
        this.containerEl = document.createElement('div');
        this.containerEl.style.cssText = "height: 100%; width: 100%";
        this.externalWindow = null;
    }

    render() {
        // STEP 2: append props.children to the container <div> that isn't mounted anywhere yet
        return ReactDOM.createPortal(this.props.children, this.containerEl);
    }

    componentDidMount() {
        window.addEventListener("beforeunload", (ev) => {
            if (this.externalWindow && !this.externalWindow.closed) {
                this.externalWindow.close();
            }
        });
        const node = document.getElementById("callDiv")
        if (node === null) {
            return
        }
        node.appendChild(this.containerEl);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.launchWindow && prevProps.launchWindow) {
            this.externalWindow.close();
        }
        if (this.props.launchWindow && !prevProps.launchWindow) {
            this.externalWindow = window.open('', '', 'width=1400,height=1400,left=200,top=200');
            if (!this.externalWindow)
                return;
            this.externalWindow.addEventListener("beforeunload", (ev) => {
                const node = document.getElementById("callDiv")
                if (node === null) {
                    return
                }
                node.appendChild(this.containerEl);
                this.props.windowClosed();
            });
            this.externalWindow.document.body.appendChild(this.containerEl);
            copyStyles(document, this.externalWindow.document);
        }
    }

}

export default WindowPortal
