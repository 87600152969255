/**
 * Whereami pulls out the origin of the website
 * @returns the origin of the website, without portnumber and without trailing slash
 */


export default function () {
    // der Doofe Internetexplorer kennt das window.origin nicht, daher müssen wir uns das selber bauen
    if (!window.location.origin) {
        //window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
        console.error("window.origin was not filled!!");
    }

    const origin = window.location.origin;
    console.log("WhereAmI.js origin: " + origin);
    let retwert = origin;

    // erst mal den Port abschneiden
    let i = origin.indexOf(":", 8);
    if (i > 0) {
        retwert = origin.substring(0, i);
    }

    const pathname = window.location.pathname;
    // und dann noch das trailing slash, falls es da ist
    i = pathname.indexOf("/", 1);
    if (i > 0) {
        retwert += pathname.substring(0, i);
    }
    console.log("WhereAmI.js new: " + retwert);

    return origin;
    // return retwert;
}
