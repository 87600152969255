import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DatabaseService from "../../services/database.service";
import Paper from '@material-ui/core/Paper';


function EventDialog(props) {
  const { onClose, open } = props;
  const [usrNachname, setUsrNachname] = React.useState("");
  const [usrVorname, setUsrVorname] = React.useState("");
  const [petName, setPetName] = React.useState("");
  const [petAge, setPetAge] = React.useState("");
  const [petBreed, setPetBreed] = React.useState("");

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText("#D2D2D2"),
      backgroundColor: "#1e90ff",
      "&:hover": {
        backgroundColor: "#9A9A9A",
      },
    },
  }))(Button);


  const handleClose = () => {
    props.updateEvents();
    onClose();
  };

  const handleAccept = () => {
    DatabaseService.vet.event.update(props.event.id, { confirmed: true });
    console.log(props.event.userID);
    DatabaseService.AnimalOwner.readOne(props.event.userID)
      .then(response => {
        //TODO: Test if user already exists
        DatabaseService.vet.patient
        .create({
          vetID: props.event.vetID,
          userID: props.event.userID,
          nachname: response.data.nachname,
          vorname: response.data.vorname
        });
        handleClose();
      })
      .catch(e => {
        console.log(e);
      });

  };

  var acceptButton;

  if (Number(props.event.confirmed)) {
    acceptButton = null;
  }
  else {
    acceptButton = <ColorButton
      onClick={handleAccept}
      style={{ margin: 10 }}>
      Annehmen
                </ColorButton>;
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{ color: "#1e90ff", textAlign: "center", fontSize: 30 }} id="simple-dialog-title">{props.event.start.toLocaleString('de-DE', { weekday: "short", hour: "numeric", minute: "2-digit" })} - {props.event.end.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })}</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", width: "300px", flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ color: "#1e90ff", textAlign: "center", fontSize: 25 }}>Besitzer:</span>
          <span style={{ textAlign: "center", fontSize: 25, marginBottom: 15 }}>{usrVorname} {usrNachname}</span>
          <span style={{ color: "#1e90ff", textAlign: "center", fontSize: 25 }}>Patient:</span>
          <Paper style={{ display: "flex", flexDirection: 'column', justifyContent: 'space-around', alignContent: 'stretch', height: "90%", width: "90%" }}>
            <span style={{ color: "#1e90ff", fontSize: 30, fontWeight: 'bold', textAlign: 'center' }}>{petName}</span>
            <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', margin: 8 }}>
              <div>
                <span style={{ fontSize: 25, color: "#1e90ff" }}>Tierart: </span>
                <span style={{ fontSize: 25 }}>{petBreed}</span>
              </div>
              <div>
                <span style={{ fontSize: 25, color: "#1e90ff" }}>Alter: </span>
                <span style={{ fontSize: 25 }}>{petAge}</span>
              </div>
            </div>
          </Paper>
          <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-around' }}>
            {acceptButton}
            <ColorButton
              onClick={() => { DatabaseService.vet.event.update(props.event.id, { confirmed: false }); handleClose() }}
              style={{ margin: 10 }}>
              Ablehnen
            </ColorButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

EventDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default EventDialog
