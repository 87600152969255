
class ClinicProfileAction {
    /**
     * ClinicProfile Action Class
     */
    constructor(axios) {
        this.http = axios;
    }


    /**
     * Create one clinic profile
     * @param {Object} data
     * @returns Promise object
     */
    create(data) {
        return this.http.post("/api/clinicprofile/create.php", data);
    };
    /**
     * Read all clinics by id
     * @param {Object} clinicId clinic profile id
     * @returns Promise object
     */
    read(clinicId) {
        return this.http.get(`/api/clinicprofile/read_by_index.php?id=${clinicId}`);
    };
    /**
     * Read all clinics
     * @returns Promise object
     */
    readAll() {
        return this.http.get(`/api/clinicprofile/read.php`);
    };
    /**
     * Read one clinic profile
     * @param {Object} id clinic profile id
     * @returns Promise object
     */
    readOne(clinicId) {
        return this.http.get(`/api/clinicprofile/read_by_index_clinic.php?id=${clinicId}`);
    };
    /**
     * Update one clinic profile
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return this.http.post("/api/clinicprofile/update.php", data);
    };
    /**
     * Delete one clinic profile
     * @param {Object} id clinic profile id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Read all accepted vets, but not admin
     * @param {Object} clinicId clinic profile id
     * @returns Promise object
     */
    readAccepted(clinicId) {
        return this.http.get(`/api/clinicprofile/get_personal.php?id=${clinicId}`);
    };
    /**
     * Read all accepted vets, marked as admin
     * @param {Object} clinicId clinic profile id
     * @returns Promise object
     */
    readAdmin(clinicId) {
        return this.http.get(`/api/clinicprofile/get_staff.php?id=${clinicId}`);
    };

}

export default ClinicProfileAction;
