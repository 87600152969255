import React from "react"
import { List, Card, Avatar, Tooltip, Form, Button, Modal, Input, Select } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import store from 'store'
import {  Redirect, useParams } from "react-router-dom";
import {isMobile} from 'react-device-detect';
import Paper from '@material-ui/core/Paper';
import DatabaseService from '../../../services/database.service'
import Popup from "../../react-big-calender/Popup";
import "../PopUp.css";

const {Option} = Select;

class Profil extends React.Component{

    constructor(props) {
        super(props);
    }

    onFinish = data => {
        console.log(this.props.doctor, data);
        const submitData = {
            id: this.props.doctor.id,
            titel: data.titel,
            vorname: data.vorname,
            nachname: data.nachname,
            name: [data.titel, data.vorname,data.nachname].join(' '),
            email: data.email,
            mobiltelefon: data.mobiltelefon,
            geschlecht: data.geschlecht,
            clinicID: this.props.doctor.clinicID,
            clinicAdmin: this.props.doctor.clinicAdmin,
            clinicAccepted: this.props.doctor.clinicAccepted || "0",
            token: this.props.doctor.token,
            verifiziert: this.props.doctor.verifiziert
        }
        console.log("submitData ", submitData);

        DatabaseService.vet
            .update(submitData)
            .then(response => {
                console.log("response ", response.data);
                this.props.onChange(submitData);
                this.showpopup("myPopupconfirm");
            })
            .catch(e => {
                console.log(e);
            });
    }

    onFinishFailed = data => {
        console.log(data);
    }

    async showpopup(id) {
        var popup = document.getElementById(id);

        if (!popup.classList.contains("show")) {
            popup.classList.add("show");

            let promise = new Promise((resolve, reject) => {
                setTimeout(() => resolve("done!"), 5000)
              });

            let result = await promise;

            popup.classList.remove("show");
        }
    }

    render(){
        console.log("MeinKonto.js: render() ", this.props);
        return(
            <Paper elevation={3} style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", padding: 25, margin:30, overflow:"auto", width: isMobile ? "90%" : "70%", height:"90%"}}>
                <span style={{ fontSize:22, fontWeight:"bold"}}>Profil</span>
                <span style={{color:"grey", marginTop:7}}>In diesem Bereich können Sie Ihre Daten ändern oder aktualisieren</span>
                <Form
                    layout = "vertical"
                    onFinish = {this.onFinish}
                    onFinishFailed = {this.onFinishFailed}
                    style = {{width: isMobile ? "90%" : "70%", marginTop:20}}
                    requiredMark = {false}
                    initialValues = {{
                        titel: this.props.doctor.titel,
                        vorname: this.props.doctor.vorname,
                        nachname: this.props.doctor.nachname,
                        email: this.props.doctor.email,
                        mobiltelefon: this.props.vetProfile.telefon,
                        geschlecht: this.props.vetProfile.geschlecht
                    }}
                >
                    <Form.Item
                        label="Geschlecht"
                        name="geschlecht"
                        rules={[{ required: true, message: 'Bitte eine Auswahl treffen!' }]}
                    >
                        <Select placeholder="Bitte auswählen">
                            <Select.Option value="männlich">Männlich</Select.Option>
                            <Select.Option value="weiblich">Weiblich</Select.Option>
                            <Select.Option value="unbestimmt">Unbestimmt</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Titel"
                        name="titel"
                        rules={[{ required: true, message: 'Bitte eine Auswahl treffen!' }]}
                    >
                        <Select placeholder="Bitte auswählen">
                            <Select.Option value="Dr.">Dr.</Select.Option>
                            <Select.Option value="Prof.">Prof.</Select.Option>
                            <Select.Option value="unbestimmt">Kein Titel</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Vorname"
                        name="vorname"
                        rules={[{ required: true, message: 'Bitte Vorname eingeben!' }]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Nachname"
                        name="nachname"
                        rules={[{ required: true, message: 'Bitte Nachname eingeben!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="E-Mail Addresse"
                        name="email"
                        rules={[{ required: true, message: 'Bitte E-Mail eingeben!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Mobiltelefon"
                        name="mobiltelefon"
                        rules={[{ required: true, message: 'Bitte Mobiltelefon eingeben!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item >
                        <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "45px", fontSize: 18 }} shape="round" htmlType="submit" block>
                            Speichern
                        </Button>
                        <div class="popup">
                            <span class="popuptext" id="myPopupconfirm">Mein Konto gespeichert!</span>
                        </div>
                    </Form.Item>
                </Form>
            </Paper>
        );
    }

}

export default Profil
