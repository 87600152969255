import React from "react"
import Video from "./SelectionVideo"
import Grid from '@material-ui/core/Grid';
import { ButtonBase } from '@material-ui/core';

class CameraSelection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            videoTracks: [],
            selectedTrack : ""
        }
    }

    async componentDidMount() {
        const localTracks = await this.createLocalVideoTracks(this.props.videoInputs, 5000)
        this.setState({ videoTracks: localTracks, selectedTrack: localTracks[0].deviceId });
    }

    createLocalTrack( deviceId, timeout, additionalOptions) {
        return (
            window.JitsiMeetJS.createLocalTracks({
                cameraDeviceId: deviceId,
                devices: [ 'video' ],
    
                // eslint-disable-next-line camelcase
                firefox_fake_device:
                    window.config && window.config.firefox_fake_device,
                timeout,
                ...additionalOptions
            })
                .then(([ jitsiLocalTrack ]) => jitsiLocalTrack));
    }
    
    createLocalVideoTracks(ids, timeout) {
        return Promise.all(ids.map(device => this.createLocalTrack(device.deviceId, timeout)
                       .then(jitsiTrack => {
                           return {
                               jitsiTrack,
                               device
                           };
                       })
                       .catch(() => {
                           return {
                               jitsiTrack: null,
                               device,
                               error: 'deviceSelection.previewUnavailable'
                           };
                       })));
    }

    trackSelected = trackId => {
        this.setState({selectedTrack:trackId});
    }

    render() {
        return (
            <div>
                <Grid container spacing={3} style={{ padding:20 }}>
                    {this.state.videoTracks.map((track, index) => {
                        return (
                            <Grid item xs={6} style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                                <ButtonBase onClick={()=>this.trackSelected(track.device.deviceId)} style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                                <span>{track.device.label}</span>
                                <div style={{ backgroundColor: "white", width: "100%", height: "100%", borderRadius: 5, borderColor: "#1890ff", borderStyle: track.device.deviceId===this.state.selectedTrack ? "solid" : "none" , borderWidth: "3px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
                                    <Video
                                        key={index}
                                        playsinline={true}
                                        videoTrack={track.jitsiTrack} />
                                </div>
                                </ButtonBase>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        );
    }
}

export default CameraSelection