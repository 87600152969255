
class AnimalOwnerEventAction {
    /**
     * AnimalOwnerEvent Action Class
     */
    constructor(axios) {
        this.http = axios;        
    }

    /**
     * Create one owner event
     * @param {Object} data 
     * @returns Promise object
     */
    create(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Read all events belong to owner
     * @param {Object} vetId vet id
     * @param {Object} ownerId owner id
     * @returns Promise object
     */
    read(vetId, ownerId) {
        return this.http.get(`/api/events/read_by_index_user_vet.php?id=${ownerId}&vetID=${vetId}`);
    };
    /**
     * Read one owner event
     * @param {Object} id owner id
     * @returns Promise object
     */
    readOne(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Update one owner event
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Delete one owner event
     * @param {Object} id owner id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };

}

export default AnimalOwnerEventAction;