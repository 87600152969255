import React, { Component } from "react"
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Divider, List, Button, Input } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { ButtonBase } from '@material-ui/core';
import {
    UserOutlined
} from '@ant-design/icons';
import Paper from '@material-ui/core/Paper';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DatabaseService from "../../services/database.service"
import profile from "../../Profile_Pic.png"
import { isMobile } from 'react-device-detect';
import store from 'store'
import RichTextEditor from 'react-rte';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <span style={{ fontSize: 20, fontWeight: "bold" }}>{children}</span>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


class HistoryModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedEvent: { eventID: -1 },
            value: RichTextEditor.createEmptyValue()
        }

    }

    parseJson(jsonString) {
        try {
            return JSON.parse(jsonString);
        }
        catch (e) {
            console.log(e);
        }
    }

    onChange = (value) => {
        this.setState({ value });
    };

    loadData() {
        this.setState({selectedEvent:{...this.props.selectedEvent, fields: this.parseJson(this.props.selectedEvent.form)}});
    }

    render() {
        if (!this.props.user) {
            return (
                <Dialog onEnter={() => this.loadData()} onClose={this.props.onClose} paper={{ backgroundColor: "#fffcf7" }} aria-labelledby="customized-dialog-title" open={this.props.open} maxWidth={"lg"}>
                    <DialogTitle id="customized-dialog-title" onClose={this.props.onClose}>
                        Termin
  </DialogTitle>
                </Dialog>
            )
        }
        return (
            <Dialog onEnter={() => this.loadData()} onClose={this.props.onClose} paper={{ backgroundColor: "#fffcf7" }} aria-labelledby="customized-dialog-title" open={this.props.open} maxWidth={"lg"}>
                <DialogTitle id="customized-dialog-title" onClose={this.props.onClose}>
                Termin
                </DialogTitle>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", width: "100%", padding: 40 }}>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                            <Avatar size={50} src={profile} />
                            <div style={{ display: "flex", marginLeft: 20, flexDirection: "column" }}>
                                <span style={{ fontWeight: "bold", fontSize: 20 }}>{this.props.user.vorname + " " + this.props.user.nachname}</span>
                                <span style={{ fontWeight: "bold", marginTop: 7 }}>E-Mail: {this.props.user.email}</span>
                                <span style={{ fontWeight: "bold", marginTop: 7 }}>Benutzername: {this.props.user.username}</span>
                                {//<span style={{color:"grey", marginTop:7}}>Herrmann-Hesse-Straße 4, 10437 Berlin</span>
                                }
                            </div>
                        </div>
                        {this.state.selectedEvent.doc &&
                        <>
                        <Divider />
                        <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", padding: 25, overflow: "auto" }}>
                            <span style={{ fontSize: 20, fontWeight: "bold", marginBottom: 30 }}>Dokumentation</span>
                            <RichTextEditor
                                value={this.state.value}
                                onChange={this.onChange}
                            />
                        </Paper>
                        </>
                        }
                        {this.state.selectedEvent.recording &&
                        <>
                        <Divider />
                        <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", padding: 25, overflow: "auto", width: "100%" }}>
                            <span style={{ fontSize: 20, fontWeight: "bold", marginBottom: 30 }}>Aufzeichnung</span>
                            <div>
                                <Button type="primary" shape="round" icon={<DownloadOutlined />} >
                                    Download
                                </Button>
                            </div>
                        </Paper>
                        </>
                    }
                    </div>
                    {this.state.selectedEvent.eventID !== -1 &&
                        <Paper elevation={3} style={{ margin: 20 }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: 30 }}>
                                <span style={{ fontWeight: "bold", fontSize: 20 }}>Heute, {this.state.selectedEvent.start.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })} - {this.state.selectedEvent.end.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })}</span>
                                <span style={{ fontWeight: "bold", fontSize: 20, textAlign: "center", marginTop: 10 }}>{this.state.selectedEvent.besuchsgrund}</span>
                                <span style={{ fontSize: 20, textAlign: "center", marginTop: 10 }}>{this.state.selectedEvent.name + ", " + this.state.selectedEvent.tierAlter + " Jahre"}</span>
                                <span style={{ textAlign: "center", fontSize: 20 }}>{this.state.selectedEvent.tierrasse + ", " + this.state.selectedEvent.tierart}</span>
                                <div style={{ height: "6px", backgroundColor: "orange", width: "200px", margin: 15 }} />
                                {this.state.selectedEvent.fields && this.state.selectedEvent.fields.form.map(elem =>
                                    <>
                                        <span style={{ fontSize: 20 }}>{elem.feldName}</span>
                                        <Input value={elem.feldData} />
                                        <Divider />
                                    </>
                                )}
                            </div>
                        </Paper>
                    }

                </div>
            </Dialog>
        )
    }
}

export default HistoryModal