
class RegistrationMailAction {
    /**
     * RegistrationMail Action Class
     */
    constructor(axios) {
        this.http = axios;        
    }

    
    /**
     * Create one registration mail
     * @param {Object} data 
     * @returns Promise object
     */
    create(data) {
        return this.http.post("/api/mailservice/registration_mail.php", data);
    };
    /**
     * Read all registration mail
     * @returns Promise object
     */
    read() {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Read one registration mail
     * @param {Object} id mail id
     * @returns Promise object
     */
    readOne(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Update one registration mail
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Delete one registration mail
     * @param {Object} id mail id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };


}

export default RegistrationMailAction;