import React from 'react';
import Paper from '@material-ui/core/Paper';
import {Button, Divider} from "antd"
import DatabaseService from "../../../services/database.service";
import UserProfileModal from "../UserProfileModal"
import { connect } from 'react-redux'

function mapStateToProps(state) {
  return { events: state.events }
}

class NextPatient extends React.Component {

  constructor(props){
    super(props);
    this.state={
      loading:true,
      usersLoaded: false,
      petsLoaded: false,
      requests: [],
      user: this.props.nextEvent,
      pet: this.props.nextEvent,
      dialogOpen: false,
      nextEvent: {},
      noNextEvent:true
    }
  }

  isToday = (date) => {
    const today = new Date()
    return date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();
};

render(){
  var noNextEvent=true;
  var nextEvent=null;

  if(this.props.events.length!==0){
  const now = new Date
  var index = this.props.events.findIndex((elem)=>{
      if(now.valueOf()>elem.start.valueOf()){
          return true;
      }
      return false;
  });
  if(--index > -1){
    noNextEvent=false;
    nextEvent=this.props.events[index];
  }
  else{
    if(this.props.events[this.props.events.length-1].start.valueOf() > now.valueOf()){
      noNextEvent=false;
      nextEvent=this.props.events[this.props.events.length-1];
    }
    else if(this.props.events[0].start.valueOf() > now.valueOf()){
      noNextEvent=false;
      nextEvent=this.props.events[0];
    }
  }
}


  if(noNextEvent)
  return(
    <Paper elevation={3} style={{display:"flex", flexDirection:"column", justifyContent:"space-around", padding: 25}}>
    <span style={{ fontSize:20, fontWeight:"bold", marginBottom:30}}>{this.props.currentKey==="patienten" ? "Nächster Patient [NextPatient.js]" : "Nächster Termin [NextPatient.js]"}</span>
    <span style={{fontWeight:"bold"}}>Keine bevorstehenden Termine</span>
</Paper>
  );
  
  return(
    <Paper elevation={3} style={{display:"flex", flexDirection:"column", justifyContent:"space-around", padding: 25}}>
          <span style={{ fontSize:20, fontWeight:"bold", marginBottom:30}}>{this.props.currentKey==="patienten" ? "Nächster Patient" : "Nächster Termin"}</span>
          <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start"}}>
            <div className="verticalLine"/>
            <div style={{flex:1, display:"flex", flexDirection:"column"}}>
          <span style={{fontWeight:"bold"}}>{nextEvent.besuchsgrund}</span>
          <span>{nextEvent.name + ", " + nextEvent.vorname+ " " + nextEvent.nachname}</span>
          <span style={{color:"grey"}}>{nextEvent.tierart + ", " + nextEvent.tierAlter+ " Jahre"}</span>
          </div>
          <div style={{flex:1, display:"flex", flexDirection:"column", alignItems:"flex-end"}}>
          <span style={{fontWeight:"bold"}}>{this.isToday(nextEvent.start) ? "Heute" : nextEvent.start.toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</span>
          <span>{nextEvent.start.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })}</span>
          <span style={{color:"grey"}}>{nextEvent.end.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })}</span>
          </div>
          </div>
          <Divider />
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around"}}>
          <Button size="large" onClick={()=>this.setState({dialogOpen:true})} style={{ borderColor: "black", color: "black", height:"50px" }} ghost shape="round">Details anzeigen</Button>
          <Button size="large"  style={{ backgroundColor: "#25D2AA", color: "white", height:"50px" }} shape="round" onClick={()=> this.props.startConf(nextEvent.userID, nextEvent)}>Videoanruf starten</Button>
          </div>
          <UserProfileModal startConf={this.props.startConf} open={this.state.dialogOpen} onClose={()=>this.setState({dialogOpen:false})} selectedEvent={nextEvent} user={nextEvent} />
    </Paper>
  );
}

}

export default connect(mapStateToProps)(NextPatient)