import React, { Component } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Input, Button, Space, Tabs, TimePicker, Select, Menu } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import { Formik, Field, Form as FormikForm, ErrorMessage, FieldArray } from 'formik';
import DatabaseService from "../../../services/database.service"
import store from 'store'
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import "../PopUp.css";

const { Option } = Select;
const { TabPane } = Tabs;
const { SubMenu } = Menu;
const { RangePicker } = TimePicker;
const format = 'HH:mm';

class Einstellungen extends Component {


    constructor(props) {
        super(props);
        console.log("Einstellungen.js: this.props ", this.props);
        this.state = {
            vet: this.props.doctor,
            initialVals: { form: [] },
            formInitialized: false,
            slotlaengeEdited: false,
            gebuehrEdited: false,
            current: 'allgemein',
            availGeneral: [],
            availMonday: [],
            availTuesday: [],
            availWednesday: [],
            availThursday: [],
            availFriday: [],
            availSaturday: [],
            availSunday: [],
            currentTimespan: [],
            slotlaenge: this.props.vetProfile.slotlaenge,
            gebuehr: this.props.vetProfile.gebuehr,
            optionsState: {},
            sel: []

        }
    }

    componentDidMount() {
        try {
            /*
            if (this.props.vetProfile.form)
                this.setState({ initialVals: JSON.parse(this.props.vetProfile.form) });
            this.setState({ formInitialized: true });
            */

            this.updateForm();
            this.updateAvailability();
        }
        catch (e) {
            console.log(e)
        }
    }

    updateAvailability() {
        DatabaseService.vet.profile
            .readOne(store.get('usrId'))
            .then(response => {
                var oeffnungszeiten = JSON.parse(response.data.oeffnungszeiten);
                Object.keys(oeffnungszeiten).map((key) =>
                oeffnungszeiten[key] = [moment(oeffnungszeiten[key][0]), moment(oeffnungszeiten[key][1])]
                );

                this.setState({
                    availGeneral: oeffnungszeiten.availGeneral,
                    availMonday: oeffnungszeiten.availMonday,
                    availTuesday: oeffnungszeiten.availTuesday,
                    availWednesday: oeffnungszeiten.availWednesday,
                    availThursday: oeffnungszeiten.availThursday,
                    availFriday: oeffnungszeiten.availFriday,
                    availSaturday: oeffnungszeiten.availSaturday,
                    availSunday: oeffnungszeiten.availSunday,
                    currentTimespan: oeffnungszeiten.availGeneral
                });
            })
            .catch(e => {
                console.log(e);
            })
    }

    updateForm() {
        DatabaseService.vet.profile
            .readOne(store.get('usrId'))
            .then(response => {
                if (response.data.form != null) {
                    this.setState({ initialVals: JSON.parse(response.data.form) });

                    var items = [];

                    for (let i=0;i<this.state.initialVals.form.length;i++) {
                        if (this.state.initialVals.form[i].feldTyp == "text") items[i] = "Text";
                        else if(this.state.initialVals.form[i].feldTyp == "number") items[i] = "Zahl";
                    }

                    this.setState({ sel: items });
                }

                this.setState({ formInitialized: true });
            })
    }

    updateData() {
        DatabaseService.vet
            .readOne(store.get('usrId'))
            .then(response => {
                this.setState({ vet: response.data });
            })
            .catch(e => {
                console.log(e);
            });
    }

    callback(key) {
        console.log(key);
    }

    onFinishForm = values => {

        var form = JSON.stringify(values);

        console.log('Received values of form:', form);

        DatabaseService.vet.profile
            .update({ vetID: store.get('usrId'), form: form })
            .then(response => {
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });

        this.showpopup("myPopupAnamnese");
    };

    onFinish(values) {
        const data = {
            start: values.ab.format(format),
            end: values.bis.format(format),
            slotlength: values.slotaenge,
            rate: values.gebuehr
        }
        // FIXME: api endpoint not available
        DatabaseService.updateVetParameters(store.get('usrId'), data)
            .then(response => {
                console.log(response.data);
                this.updateData();
                this.props.onClose();
            })
            .catch(e => {
                console.log(e);
            });
    };

    saveEventoptions() {
        var success = true;

        DatabaseService.vet.profile
            .update({ vetID: this.props.doctor.id, slotlaenge: this.state.slotlaenge })
            .then(() => {
                this.props.onChange({
                    slotlaenge: this.state.slotlaenge,
                });
            })
            .catch(e => {
                console.log(e);
                success = false;
            });
        DatabaseService.vet.profile
            .update({ vetID: this.props.doctor.id, gebuehr: this.state.gebuehr })
            .then(() => {
                this.props.onChange({
                    gebuehr: this.state.gebuehr,
                });
            })
            .catch(e => {
                console.log(e);
                success = false;
            });

        if (success) {
            this.showpopup("myPopupconfirmEventoptions");
        }
    }

    saveAvailability() {
        const jsonString = JSON.stringify(
            {
                availGeneral: this.state.availGeneral,
                availMonday: this.state.availMonday,
                availTuesday: this.state.availTuesday,
                availWednesday: this.state.availWednesday,
                availThursday: this.state.availThursday,
                availFriday: this.state.availFriday,
                availSaturday: this.state.availSaturday,
                availSunday: this.state.availSunday
            }
        );
        // FIXME: Klären, warum hier auch die Zeiten auftauchen?!
        DatabaseService.vet.profile
            .update({ vetID: this.props.doctor.id, oeffnungszeiten: jsonString })
            .then(response => {
                console.log("response ", response.data);

                this.showpopup("myPopupconfirm");
            })
            .catch(e => console.log(e));
    }


    changeAvailability(av) {
        this.setState({ currentTimespan: av });
        switch (this.state.current) {
            case "allgemein":
                this.setState({
                    availGeneral: av,
                    availMonday: av,
                    availTuesday: av,
                    availWednesday: av,
                    availThursday: av,
                    availFriday: av,
                    availSaturday: av,
                    availSunday: av
                })
                break;
            case "montag":
                this.setState({
                    availMonday: av
                })
                break;
            case "dienstag":
                this.setState({
                    availTuesday: av
                })
                break;
            case "mittwoch":
                this.setState({
                    availWednesday: av
                })
                break;
            case "donnerstag":
                this.setState({
                    availThursday: av
                })
                break;
            case "freitag":
                this.setState({
                    availFriday: av
                })
                break;
            case "samstag":
                this.setState({
                    availSaturday: av
                })
                break;
            case "sonntag":
                this.setState({
                    availSunday: av
                })
                break;
            default:
                break;
        }

        console.log(av)
    }

    handleClick = e => {
        this.setState({ current: e.key });
        switch (e.key) {
            case "allgemein":
                this.setState({
                    currentTimespan: this.state.availGeneral
                })
                break;
            case "montag":
                this.setState({
                    currentTimespan: this.state.availMonday
                })
                break;
            case "dienstag":
                this.setState({
                    currentTimespan: this.state.availTuesday
                })
                break;
            case "mittwoch":
                this.setState({
                    currentTimespan: this.state.availWednesday
                })
                break;
            case "donnerstag":
                this.setState({
                    currentTimespan: this.state.availThursday
                })
                break;
            case "freitag":
                this.setState({
                    currentTimespan: this.state.availFriday
                })
                break;
            case "samstag":
                this.setState({
                    currentTimespan: this.state.availSaturday
                })
                break;
            case "sonntag":
                this.setState({
                    currentTimespan: this.state.availSunday
                })
                break;
            default:
                break;
        }
    };

    async showpopup(id) {
        var popup = document.getElementById(id);

        if (!popup.classList.contains("show")) {
            popup.classList.add("show");

            let promise = new Promise((resolve, reject) => {
                setTimeout(() => resolve("done!"), 5000)
              });

            let result = await promise;

            popup.classList.remove("show");
        }
    }

    getVal(sel, index) {
        if (sel[index]) return sel[index];
        else return "Text";
    }

    render() {

        const { sel } = this.state;

        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Paper elevation={3} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: 25, margin: 30 }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                        <span style={{ fontSize: 20, fontWeight: "bold", marginBottom: 10 }}>Verfügbarkeit
                        <IconButton style={{ color: "#25D2AA" }} onClick={() => this.saveAvailability()}>
                            <DoneIcon />
                            <div class="popup">
                                <span class="popuptext" style={{ fontSize: 12 }} id="myPopupconfirm">Meine Verfügbarkeit gespeichert!</span>
                            </div>
                        </IconButton>
                        </span>
                        <Menu onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
                            <Menu.Item key="allgemein">
                                Allgemein
                            </Menu.Item>
                            <Menu.Item key="montag">
                                Montag
                            </Menu.Item>
                            <Menu.Item key="dienstag">
                                Dienstag
                            </Menu.Item>
                            <Menu.Item key="mittwoch">
                                Mittwoch
                            </Menu.Item>
                            <Menu.Item key="donnerstag">
                                Donnerstag
                            </Menu.Item>
                            <Menu.Item key="freitag">
                                Freitag
                            </Menu.Item>
                            <Menu.Item key="samstag">
                                Samstag
                            </Menu.Item>
                            <Menu.Item key="sonntag">
                                Sonntag
                            </Menu.Item>
                        </Menu>
                        <div style={{ height: "15px" }} />
                        <RangePicker
                            format={format}
                            placeholder={["Von", "Bis"]}
                            value={this.state.currentTimespan}
                            onChange={(moment, string) => this.changeAvailability(moment)}
                        />
                    </div>
                </Paper>
                <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: 25, margin: 30 }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
                        <span style={{ fontSize: 20, fontWeight: "bold", marginBottom: 10 }}>Terminoptionen
                            <IconButton style={{ color: "#25D2AA" }} onClick={() => this.saveEventoptions()}>
                                <DoneIcon />
                                <div class="popup">
                                    <span class="popuptext" style={{ fontSize: 12 }} id="myPopupconfirmEventoptions">Meine Terminoptionen gespeichert!</span>
                                </div>
                            </IconButton>
                        </span>
                        <Input suffix="min" placeholder="Slotlänge" value={this.state.slotlaenge} onChange={({ target: { value } }) => this.setState({ slotlaenge: value })} />
                        <div style={{ height: "10px" }} />

                        <Input suffix="€" placeholder="Grundgebühr" value={this.state.gebuehr} onChange={({ target: { value } }) => this.setState({ gebuehr: value })} />
                    </div>

                </Paper>
                <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 25, margin: 30 }}>
                    <span style={{ fontSize: 22, fontWeight: "bold", marginBottom: 15 }}>Formulare</span>
                    <span style={{ fontWeight: "bold" }}>Anamnese Formular</span>
                    <span style={{ color: "grey", marginTop: 7, marginBottom: 20 }}>In diesem Bereich können Sie ein Formular erstellen, welches der nutzer bei Terminanfrage ausfüllen muss</span>
                    {this.state.formInitialized &&
                        <Formik
                            initialValues={this.state.initialVals}
                            onSubmit={async (values) => {
                                this.onFinishForm(values);
                            }}
                        >
                            {({ values }) => (
                                <FormikForm>
                                    <FieldArray name="form">
                                        {({ insert, remove, push }) => (
                                            <div>
                                                {values.form.length > 0 &&
                                                    values.form.map((form, index) => (
                                                        <div style={{ marginBottom: 8, display: "flex", width: "100%", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} key={index}>
                                                            <Field
                                                                name={`form.${index}.feldName`}
                                                            >
                                                                {({ field, form, meta }) => (
                                                                    <div>
                                                                        <Input {...field} placeholder="Feld Name" />
                                                                    </div>
                                                                )}
                                                            </Field>
                                                            <div style={{ width: 20 }} />
                                                            <Select
                                                                style={{ display: "block" }}
                                                                onChange={(sel) => {
                                                                    var items = this.state.sel;
                                                                    var val;
                                                                    if (sel == "Text") val = "text";
                                                                    else if (sel == "Zahl") val = "number";
                                                                    items[index] = sel;
                                                                    this.setState({ sel: items });
                                                                    form.feldTyp = val;
                                                                }}
                                                                value={this.getVal(sel, index)}
                                                            >
                                                                <Select.Option value="Text" label="Text">
                                                                Text
                                                                </Select.Option>
                                                                <Select.Option value="Zahl" label="Zahl">
                                                                Zahl
                                                                </Select.Option>
                                                            </Select>
                                                            <IconButton component="span" onClick={() => remove(index)}>
                                                                <ClearIcon />
                                                            </IconButton>
                                                        </div>
                                                    ))}
                                                <Button size="large" style={{ borderColor: "black", color: "black" }} ghost shape="round" onClick={() => push({ feldName: "", feldTyp: "text", feldData: "" })}>
                                                    Feld Hinzufügen
                                                </Button>
                                            </div>
                                        )}
                                    </FieldArray>
                                    <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "45px", fontSize: 18, marginTop: 10 }} shape="round" htmlType="submit" >
                                        Update
                                        <div class="popup">
                                            <span class="popuptext" style={{ fontSize: 12 }} id="myPopupAnamnese">Anamnesebogen gespeichert!</span>
                                        </div>
                                    </Button>
                                </FormikForm>
                            )}
                        </Formik>
                    }
                </Paper>
            </div>
        );

    }


}

export default Einstellungen;
