import React from 'react';
import { Card,TimePicker, Switch, Button } from 'antd';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import moment from 'moment';

const gridStyle = {
    width: '50%',
    height:"80px",
    display:"flex",
    flexDirection:"row",
};

const gridStyleSettings = {
    width: '50%',
    height:"80px",
    textAlign: 'center',
};

const { RangePicker } = TimePicker;

const format = 'HH:mm';

class OpeningHours extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.props.oeffnungszeiten ? this.props.oeffnungszeiten : {
            mondayDisabled: false,
            tuesdayDisabled: false,
            wednesdayDisabled: false,
            thursdayDisabled: false,
            fridayDisabled: false,
            saturdayDisabled: true,
            sundayDisabled: true,
            monday: ["09:00", "18:00"],
            tuesday: ["09:00", "18:00"],
            wednesday: ["09:00", "18:00"],
            thursday: ["09:00", "18:00"],
            friday: ["09:00", "18:00"],
            saturday: ["09:00", "12:00"],
            sunday: ["09:00", "12:00"]
        }

    }

    async showpopup(id) {
        var popup = document.getElementById(id);

        if (!popup.classList.contains("show")) {
            popup.classList.add("show");

            let promise = new Promise((resolve, reject) => {
                setTimeout(() => resolve("done!"), 5000)
              });

            let result = await promise;

            popup.classList.remove("show");
        }
    }

    render(){
        return(
            <Card title={ <span style={{ fontSize:22, fontWeight:"bold"}}>{this.props.title || 'Öffnungszeiten' }</span>} style={{maxWidth:"800px", textAlign:"center"}}>

                <Card.Grid hoverable={false} style={gridStyle}>
                    <div style={{flex:1}}>
                        <Switch
                            checkedChildren={<LockOpenIcon style={{padding:2}}/>}
                            unCheckedChildren={<LockIcon style={{padding:2}} />}
                            checked={!this.state.mondayDisabled}
                            disabled={this.props.disabled}
                            onClick={()=>{
                                this.setState({mondayDisabled: !this.state.mondayDisabled})
                            }}
                        />
                    </div>
                    <div style={{flex:1}}>
                        <p style={{fontSize: 20, textAlign:"center"}}>Montag</p>
                    </div>
                    <div style={{flex:1}}/>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyleSettings}>
                    <RangePicker placeholder={["Öffnungszeit", "Schließungszeit"]} format={format} style={{width:"100%"}} disabled={this.state.mondayDisabled || this.props.disabled}
                        value={[moment(this.state.monday[0], format), moment(this.state.monday[1], format)]}
                        onChange={(mom, value) => this.setState({ monday: value })}
                    />
                </Card.Grid>

                <Card.Grid hoverable={false} style={gridStyle}>
                    <div style={{flex:1}}>
                        <Switch
                            checkedChildren={<LockOpenIcon style={{padding:2}}/>}
                            unCheckedChildren={<LockIcon style={{padding:2}} />}
                            checked={!this.state.tuesdayDisabled}
                            disabled={this.props.disabled}
                            onClick={()=>{
                                this.setState({tuesdayDisabled: !this.state.tuesdayDisabled})
                            }}
                        />
                    </div>
                    <div style={{flex:1}}>
                        <p style={{fontSize: 20, textAlign:"center"}}>Dienstag</p>
                    </div>
                    <div style={{flex:1}}/>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyleSettings}>
                    <RangePicker placeholder={["Öffnungszeit", "Schließungszeit"]} format={format} style={{width:"100%"}} disabled={this.state.tuesdayDisabled || this.props.disabled}
                        value={[moment(this.state.tuesday[0], format), moment(this.state.tuesday[1], format)]}
                        onChange={(mom, value) => this.setState({ tuesday: value })}
                    />
                </Card.Grid>

                <Card.Grid hoverable={false} style={gridStyle}>
                    <div style={{flex:1}}>
                        <Switch
                            checkedChildren={<LockOpenIcon style={{padding:2}}/>}
                            unCheckedChildren={<LockIcon style={{padding:2}} />}
                            checked={!this.state.wednesdayDisabled}
                            disabled={this.props.disabled}
                            onClick={()=>{
                                this.setState({wednesdayDisabled: !this.state.wednesdayDisabled})
                            }}
                        />
                    </div>
                    <div style={{flex:1}}>
                        <p style={{fontSize: 20, textAlign:"center"}}>Mittwoch</p>
                    </div>
                    <div style={{flex:1}}/>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyleSettings}>
                    <RangePicker placeholder={["Öffnungszeit", "Schließungszeit"]} format={format} style={{width:"100%"}} disabled={this.state.wednesdayDisabled || this.props.disabled}
                        value={[moment(this.state.wednesday[0], format), moment(this.state.wednesday[1], format)]}
                        onChange={(mom, value) => this.setState({ wednesday: value })}
                    />
                </Card.Grid>

                <Card.Grid hoverable={false} style={gridStyle}>
                    <div style={{flex:1}}>
                        <Switch
                            checkedChildren={<LockOpenIcon style={{padding:2}}/>}
                            unCheckedChildren={<LockIcon style={{padding:2}} />}
                            checked={!this.state.thursdayDisabled}
                            disabled={this.props.disabled}
                            onClick={()=>{
                                this.setState({thursdayDisabled: !this.state.thursdayDisabled})
                            }}
                        />
                    </div>
                    <div style={{flex:1}}>
                        <p style={{fontSize: 20, textAlign:"center"}}>Donnerstag</p>
                    </div>
                    <div style={{flex:1}}/>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyleSettings}>
                    <RangePicker placeholder={["Öffnungszeit", "Schließungszeit"]} format={format} style={{width:"100%"}} disabled={this.state.thursdayDisabled || this.props.disabled}
                        value={[moment(this.state.thursday[0], format), moment(this.state.thursday[1], format)]}
                        onChange={(mom, value) => this.setState({ thursday: value })}
                    />
                </Card.Grid>

                <Card.Grid hoverable={false} style={gridStyle}>
                    <div style={{flex:1}}>
                        <Switch
                            checkedChildren={<LockOpenIcon style={{padding:2}}/>}
                            unCheckedChildren={<LockIcon style={{padding:2}} />}
                            checked={!this.state.fridayDisabled}
                            disabled={this.props.disabled}
                            onClick={()=>{
                                this.setState({fridayDisabled: !this.state.fridayDisabled})
                            }}
                        />
                    </div>
                    <div style={{flex:1}}>
                        <p style={{fontSize: 20, textAlign:"center"}}>Freitag</p>
                    </div>
                    <div style={{flex:1}}/>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyleSettings}>
                    <RangePicker placeholder={["Öffnungszeit", "Schließungszeit"]} format={format} style={{width:"100%"}} disabled={this.state.fridayDisabled || this.props.disabled}
                        value={[moment(this.state.friday[0], format), moment(this.state.friday[1], format)]}
                        onChange={(mom, value) => this.setState({ friday: value })}
                    />
                </Card.Grid>

                <Card.Grid hoverable={false} style={gridStyle}>
                    <div style={{flex:1}}>
                        <Switch
                            checkedChildren={<LockOpenIcon style={{padding:2}}/>}
                            unCheckedChildren={<LockIcon style={{padding:2}} />}
                            checked={!this.state.saturdayDisabled}
                            disabled={this.props.disabled}
                            onClick={()=>{
                                this.setState({saturdayDisabled: !this.state.saturdayDisabled})
                            }}
                        />
                    </div>
                    <div style={{flex:1}}>
                        <p style={{fontSize: 20, textAlign:"center"}}>Samstag</p>
                    </div>
                    <div style={{flex:1}}/>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyleSettings}>
                    <RangePicker placeholder={["Öffnungszeit", "Schließungszeit"]} format={format} style={{width:"100%"}} disabled={this.state.saturdayDisabled || this.props.disabled}
                        value={[moment(this.state.saturday[0], format), moment(this.state.saturday[1], format)]}
                        onChange={(mom, value) => this.setState({ saturday: value })}
                    />
                </Card.Grid>

                <Card.Grid hoverable={false} style={gridStyle}>
                    <div style={{flex:1}}>
                        <Switch
                            checkedChildren={<LockOpenIcon style={{padding:2}}/>}
                            unCheckedChildren={<LockIcon style={{padding:2}} />}
                            checked={!this.state.sundayDisabled}
                            disabled={this.props.disabled}
                            onClick={()=>{
                                this.setState({sundayDisabled: !this.state.sundayDisabled})
                            }}
                        />
                    </div>
                    <div style={{flex:1}}>
                        <p style={{fontSize: 20, textAlign:"center"}}>Sonntag</p>
                    </div>
                    <div style={{flex:1}}/>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyleSettings}>
                    <RangePicker placeholder={["Öffnungszeit", "Schließungszeit"]} format={format} style={{width:"100%"}} disabled={this.state.sundayDisabled || this.props.disabled}
                        value={[moment(this.state.sunday[0], format), moment(this.state.sunday[1], format)]}
                        onChange={(mom, value) => this.setState({ sunday: value })}
                    />
                </Card.Grid>

                {this.props.doctor.clinicAdmin==1 &&
                    <span>
                        <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "45px", fontSize: 18, width:"50%", margin:10}} shape="round" onClick={() => {this.props.onSave(this.state);this.showpopup("myPopupconfirm")}} >
                            Speichern
                        </Button>
                        <div class="popup" style={{ left: "-25%" }}>
                            <span class="popuptext" id="myPopupconfirm">Öffnungszeiten gespeichert!</span>
                        </div>
                    </span>
                }
            </Card>
        );
    }
}

export default OpeningHours
