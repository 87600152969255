import React from "react";
import { ChatList } from 'react-chat-elements';
import profile from "../../Profile_Pic.png";

class Chats extends React.Component {

    constructor(props){
        super(props);
        this.state={
            chats:[]
        }
    }

    async componentDidMount(){
        const rooms= await this.props.rocketChat.methodCall("rooms/get");

        const mappedRooms = rooms.map(room => {
            return {
                id: room._id,
                avatar: profile,
                alt: 'Reactjs',
                title: room.description,
                subtitle: room.lastMessage ? room.lastMessage.msg : "",
                date: room.lastMessage ? new Date(room.lastMessage.ts.$date) : "",
                unread: 0,
            }
        });

        this.setState( {chats:mappedRooms});

        const subscription = await this.props.rocketChat.subscribeToMessages();
        subscription.onEvent( (msg) => this.onMessageRecieved(msg));
      }

      async onMessageRecieved(msg){
        console.log(msg);
        const rooms= await this.props.rocketChat.methodCall("rooms/get");

        const mappedRooms = rooms.map(room => {
            return {
                id: room._id,
                avatar: profile,
                alt: 'Reactjs',
                title: room.description,
                subtitle: room.lastMessage ? room.lastMessage.msg : "",
                date: room.lastMessage ? new Date(room.lastMessage.ts.$date) : "",
                unread: 0,
            }
        });

        this.setState( {chats:mappedRooms});
    }

    selectChat(obj){
        this.props.selectChat(obj.id);
    }

    render() {
        return(
            <div style={{height:"100%", width:"100%"}}>
                <ChatList
                     className='chat-list'
                     dataSource={this.state.chats} 
                     onClick={(obj)=>this.selectChat(obj)}/>
            </div>
        );
    }

}

export default Chats