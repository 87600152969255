import React, { useRef } from "react";
import Logo from "./assets/HelloVetLogo.png"
import CheckIcon from "./assets/CheckIcon.png"
import Background from "./assets/BackgroundLeft.png"
import Corner from "./assets/InfosCorner.png"
import { Form, Input, Button, Checkbox, Select, Divider, Spin, Radio, Space } from 'antd';
import debounce from 'lodash/debounce';
import { CSSTransition } from 'react-transition-group';
import { CloseOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import DatabaseService from "../services/database.service";
import GeocodingService from '@mapbox/mapbox-sdk/services/geocoding';
import { Redirect } from "react-router-dom";
import { StatusCodes as HttpStatus } from 'http-status-codes';
import store from "store";

const { Option } = Select;

class RegistrierenRender extends React.Component {

    constructor(props) {
        super(props);

        this.lastFetchId = 0;
        // TODO: accessToken vom GeocodingService entfernen
        this.state = {
            data: [],
            value: "",
            selectedClinicID: "",
            selectedClinic: {},
            fetching: false,
            newClinicSelected: false,
            geocodingClient: new GeocodingService({
                accessToken: 'pk.eyJ1IjoiZG9taW5pay1jYXYiLCJhIjoiY2tsMWNlZW1rMHVhMTJwcGVkaXo0YnpucCJ9.implwE-8ZbHzk49FRRZGDw'
            }),
            dataSubmitted: false,
        };
        this.fetchUser = debounce(this.fetchUser, 800);
        this.fetchUser("");
    }

    componentDidMount() {
        DatabaseService.clinic.profile
            .readAll()
            .then(response => {
                var items = [];
                for(let i=0;i<response.data.length;i++) {
                    items.push(response.data[i])
                }

                this.setState({
                    data: items,
                })
            })
            .catch(e => {
                console.log(e);
            })
    }

    onFinish = values => {
        // Workaround: API Link korrigieren
        DatabaseService.fixOrigin();

        if (this.state.newClinicSelected) {
            this.state.geocodingClient
                .forwardGeocode({
                    query: values.addresse,
                    countries: ['de'],
                    limit: 2
                })
                .send()
                .then(georesponse => {
                    // curl -X POST -d '{"name":"Klinik-A","addresse":"Hauptstr 2","anschrift":"12345 Tierhausen","telefonnummer":"0999-123456","notrufnummer":"0999-123456","email":"admin@hellovet.de"}' http://localhost.vet.hellovet.de/api.hellovet.de/api/clinicbase/create.php
                    DatabaseService.clinic
                        .create({
                            name: values.klinikName,
                            addresse: values.addresse,
                            anschrift: values.anschrift,
                            telefonnummer: values.telefonnummer,
                            notrufnummer: values.notrufnummer,
                            email: values.email
                        })
                        .then(clinicresponse => {
                            DatabaseService.vet
                                .updateByToken({
                                    id: this.props.token,
                                    clinicID: clinicresponse.data.id,
                                    clinicAdmin: 1,
                                    clinicAccepted: 1,
                                    fachrichtung: values.fachgebiet,
                                    addresse: values.addresse,
                                    zusatzausbildung: values.spezialisierung,
                                    telefon: values.mobile,
                                    lng: georesponse.body.features[0].center[0],
                                    lat: georesponse.body.features[0].center[1]
                                })
                                .then(userresponse => {
                                    //store.set('loggedIn', true);
                                    //store.set('redirectPage', "/login");
                                    this.setState({ dataSubmitted: true });
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                            DatabaseService.clinic.profile
                                .create({
                                    clinicID: clinicresponse.data.id,
                                    name: values.klinikName,
                                    addresse: values.addresse,
                                    anschrift: values.anschrift,
                                    telefonnummer: values.telefonnummer,
                                    notrufnummer: values.notrufnummer,
                                    email: values.email,
                                    lng: georesponse.body.features[0].center[0],
                                    lat: georesponse.body.features[0].center[1]
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                            DatabaseService.clinicpermissions
                                .create({
                                    clinicID: clinicresponse.data.id,
                                    vetID: this.props.vetID
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                        })
                        .catch(e => {
                            console.log(e);
                        });
                })
        } else {
            DatabaseService.vet
                .updateByToken({
                    id: this.props.token,
                    clinicID: this.state.selectedClinicID,
                    clinicAdmin: 0,
                    clinicAccepted: 0,
                    fachrichtung: values.fachgebiet,
                    addresse: this.state.selectedClinic.addresse,
                    zusatzausbildung: values.spezialisierung,
                    telefon: values.mobile,
                    lng: this.state.selectedClinic.lng,
                    lat: this.state.selectedClinic.lat
                })
                .then(userresponse => {
                    //store.set('loggedIn', true);
                    //store.set('redirectPage', "/login");
                    this.setState({ dataSubmitted: true });
                })
                .catch(e => {
                    console.log(e);
                });
        }

    };

    onFinishFailed = errorInfo => {
        console.log('onFinishFailed:', errorInfo);
    };

    fetchUser = value => {
        DatabaseService.clinic
            .search(value)
            .then(response => {
                if(response.data && Array.isArray(response.data)) {
                    const data = response.data.map(clinic => ({
                        label: clinic.name,
                        value: clinic.name,
                        clinic: clinic
                    }));
                    this.setState({ data, fetching: false });
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    handleChange = (value, option) => {
        DatabaseService.clinic.profile
            .read(value)
            .then(response => {
                if (response.status === HttpStatus.OK) {
                    this.setState({
                        selectedClinic: response.data,
                    })
                }
            })
            .catch (e => {
                console.log(e);
            })

        this.setState({
            selectedClinicID: value,
            fetching: false,
        });
    };

    renderOptions = () => {
        this.state.data.sort((a, b) => {
            let fa = a.name.toLowerCase(),
                fb = b.name.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });

        return this.state.data.map( element =>
            <Option key={element.clinicID} value={element.clincID}>{element.name}</Option>
        )
    }

    render() {
        const { fetching, data, value } = this.state;
        if (this.state.dataSubmitted) {
            return (
                <Redirect to="/login" />
            );
        }
        return (
            <div style={{ width: "100%", height: "100vh", display: "flex", flexDirection: "row" }}>
                <img src={Corner} style={{ position: "absolute", right: "0px", top: "0px", height: "15%", zIndex: 0 }} />
                <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundImage: `url(${Background})`, backgroundPosition: "left center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    <img src={Logo} style={{ width: "400px" }} />
                    <div style={{ display: "flex", flexDirection: "column", width: "500px" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <img src={CheckIcon} style={{ height: "80px", marginTop: 15 }} />
                            <span style={{ marginTop: 15, fontSize: 22, fontFamily: "WorkSans" }} >Bequem von Zuhause ohne Anfahrt und Wartezimmerstress für dein Tier</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <img src={CheckIcon} style={{ height: "80px", marginTop: 15 }} />
                            <span style={{ marginTop: 15, fontSize: 22, fontFamily: "WorkSans" }} >Beratung per Videochat durch erfahrene Tierärzte</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <img src={CheckIcon} style={{ height: "80px", marginTop: 15 }} />
                            <span style={{ marginTop: 15, fontSize: 22, fontFamily: "WorkSans" }} >Löst viele Probleme ohne weiteren Arztbesuch im Anschluss</span>
                        </div>
                    </div>
                </div>
                <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", padding: 100 }}>

                    <Form
                        layout="vertical"
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <span style={{ marginBottom: 15, fontSize: 22, fontFamily: "WorkSans", fontWeight: "bold" }} >Vielen Dank für Ihr Interesse an unserem kostenlosen Angebot</span>
                            <span style={{ marginBottom: 15, fontSize: 16, fontFamily: "WorkSans" }} >Um die vielen Vorteile von Hello Vet kennenlernen zu können benötigen wir noch weitere Angaben zu Ihrer Person und Ihrer Praxis bzw. Ihrer Klinik.</span>
                        </div>
                        <Form.Item
                            label="Mobiltelefon"
                            name="mobile"
                            rules={[{ required: true, message: 'Bitte Telefonnummer eingeben!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item name="weiterbildung" label="Weiterbildungen">
                            <Radio.Group>
                                <Radio value="facharzt">Facharzt</Radio>
                                <Radio value="diplomatie">Diplomatie</Radio>
                                <Radio value="phd">PHD</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="Fachgebiet"
                            name="fachgebiet"
                            rules={[{ required: true, message: 'Bitte eine Auswahl treffen!' }]}
                        >
                            <Select placeholder="Bitte auswählen">
                                <Select.Option value="test">Test</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Weitere Spezialisierung"
                            name="spezialisierung"
                            rules={[{ required: true, message: 'Bitte eine Auswahl treffen!' }]}
                        >
                            <Select placeholder="Bitte auswählen">
                                <Select.Option value="test">Test</Select.Option>
                            </Select>
                        </Form.Item>

                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <span style={{ marginBottom: 15, fontSize: 22, fontFamily: "WorkSans", fontWeight: "bold" }} >Praxis- bzw. Klinik-Auswahl</span>
                            <span style={{ marginBottom: 10, fontSize: 16, fontFamily: "WorkSans" }} >Bitte benutzen sie die nachfolgende Suchfunktion um ihre Praxis bzw. Klink auszuwählen. </span>
                            <span style={{ marginBottom: 15, fontSize: 16, fontFamily: "WorkSans" }} >Hinweis: Sollte(n) diese noch nicht in unserem System sein, bitten wir sie die Option “Praxis/Klink anlegen” auszuwählen</span>
                        </div>

                        {!this.state.newClinicSelected &&
                            <Form.Item
                                label="Klinik auswählen"
                                name="klinik"
                                rules={[{ required: true, message: 'Bitte eine Auswahl treffen!' }]}
                            >
                                <Select
                                    value={value}
                                    placeholder="Bitte auswählen"
                                    notFoundContent={fetching ? <Spin size="small" /> : null}
                                    showSearch
                                    filterOption={false}
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Button style={{ backgroundColor: "#25D2AA", color: "white" }} shape="round" block onClick={() => this.setState({ newClinicSelected: true })}>
                                                    Neue Klinik erstellen
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                    onSearch={this.fetchUser}
                                    onSelect={this.handleChange}
                                    >
                                        {this.renderOptions()}
                                </Select>
                            </Form.Item>}

                        <CSSTransition
                            in={this.state.newClinicSelected}
                            timeout={300}
                            classNames="alert"
                            unmountOnExit
                        >
                            <div>
                                <Space align="center" style={{ padding: 10, marginBottom: 15, backgroundColor: "#25D2AA", borderRadius: "10px" }}>
                                    <span style={{ color: "white", fontSize: 18, fontFamily: "WorkSans" }} >Neue Klinik erstellen</span>
                                    <CloseOutlined style={{ color: "black", fontSize: 20 }} onClick={() => this.setState({ newClinicSelected: false })} />
                                </Space>

                                <Form.Item
                                    label="Klinik/Praxis Name"
                                    name="klinikName"
                                    rules={[{ required: true, message: 'Bitte Klinik/Praxis Name eingeben!' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Addresse"
                                    name="addresse"
                                    rules={[{ required: true, message: 'Bitte Addresse eingeben!' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Anschrift"
                                    name="anschrift"
                                    rules={[{ required: true, message: 'Bitte Anschrift eingeben!' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Telefonnummer"
                                    name="telefonnummer"
                                    rules={[{ required: true, message: 'Bitte Telefonnummer eingeben!' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Notrufnummer"
                                    name="notrufnummer"
                                    rules={[{ required: true, message: 'Bitte Notrufnummer eingeben!' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="E-Mail"
                                    name="email"
                                    rules={[{ required: true, message: 'Bitte E-Mail eingeben!' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                        </CSSTransition>

                        <Form.Item >
                            <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "45px", fontSize: 18 }} shape="round" htmlType="submit" >
                                Speichern & Weiter zur Übersicht
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }
}

function Registrieren(props) {
    let { token, vetID } = useParams();
    return (<RegistrierenRender token={token} vetID={vetID} />);
}

export default Registrieren
