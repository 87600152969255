import { configureStore } from '@reduxjs/toolkit'
import eventsSlice from './events/eventsSlice'

const store = configureStore({
    reducer: {
        events: eventsSlice
    }
})

export default store
