import React from 'react';
import Logo from "./assets/HelloVetPetLogo.png"
import { Layout, Row, Col } from 'antd';
import LineFooter from "./assets/LineFooter.png"
import Dot1 from "./assets/Dot1Impressum.png"
import Dot2 from "./assets/Dot2Impressum.png"
import Line from "./assets/LineImpressum.png"
import Background from "./assets/ImpressumBackg.png"
import Partner from "./assets/koop_2x.png"
import Arndt from "./assets/Arndt.jpg"
import Daniel from "./assets/Daniel.jpg"
import Dots from "./assets/DotsImpressum.png"
import RosengartenLogo from "./assets/rosengarten.png"
import TierheimheldenLogo from "./assets/tierheimhelden.svg"
import LogoLMU from "./assets/LogoLMU.jpg"
import LogoEXIST from "./assets/Logo-EXIST.png"
import {
  LinkedinFilled
} from '@ant-design/icons';
import {
  Link
} from "react-router-dom";
import "./Impressum.css"

const { Header, Content, Footer } = Layout;


function Datenschutz(){
  return (
  <Layout className="layout" style={{ backgroundColor:"white", backgroundImage: `url(${Background})`, backgroundRepeat:"no-repeat", backgroundSize:"contain" }}>
    <Header style={{ backgroundColor: 'transparent' }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
          <Link to="/">
             <img src={Logo} style={{ width: "260px" }} />
          </Link>
        </div>
    </Header>
    <Content >
        <div style={{display:"flex", flexDirection:"column", width:"100%", alignItems:"center"}}>
        <div style={{display:"flex", flexDirection:"column", width:"70%"}}>
      <span className="heading" style={{width:"100%", textAlign:"center"}}>Datenschutzhinweise</span>
      <span className="impressum-heading">Datenschutzgrundsätze bei HelloVet.de</span>
      <span className="impressum">Der Schutz und die Sicherheit personenbezogener Daten sind für uns, die Veticine Ventures GmbH, als Betreiber von HelloVet.de sehr wichtig. Selbstverständlich werden personenbezogene Daten (z.B. Name, Anschrift, E-Mail, Telefonnummer, u.ä.) durch uns nur gemäß den Bestimmungen des geltenden Datenschutzrechts, insbesondere in Übereinstimmung mit den Anforderungen der Datenschutz-Grundverordnung (DSGVO) und den Bestimmungen des Bundesdatenschutzgesetzes (BDSG) verarbeitet.
Daten und Informationen sind dann personenbezogen, wenn sie sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen. Nachstehende Hinweise informieren Sie insbesondere über die Art, den Umfang und Zweck der Verarbeitung Ihrer personenbezogenen Daten im Rahmen der Bereitstellung unseres Webangebots unter HelloVet.de und dienen als Information nach Art. 12 ff. DSGVO. </span>

  <span className="impressum-heading">Inhaltsübersicht</span>
      <span className="impressum">Datenschutzgrundsätze bei HelloVet.de</span>
<span className="impressum">Inhaltsübersicht</span>
<span className="impressum">Name und Kontaktdaten des Verantwortlichen</span>
<span className="impressum">Zwecke und Rechtsgrundlagen der Datenverarbeitung</span>
<span className="impressum">1.	Aufruf und Besuch unserer Website</span>
<span className="impressum">2.	Registrierung von Nutzer-/Tierarztkonten und Nutzung unserer HelloVet.de Webservices</span>
<span className="impressum">3.	Terminbuchungen</span>
<span className="impressum">4.	Kontaktanfragen</span>
<span className="impressum">5.	Newsletter / Versendung von Marketinginformationen</span>
<span className="impressum">6.	Social Media Links</span>
<span className="impressum">7.	Einhaltung gesetzlicher Vorschriften, insb. Aufbewahrung / Dokumentation</span>
<span className="impressum">8.	Rechtsdurchsetzung</span>
<span className="impressum">Kategorien von Empfängern</span>
<span className="impressum">Dauer der Speicherung</span>
<span className="impressum">Datensicherheit</span>
<span className="impressum">Betroffenenrechte</span>
<span className="impressum">Umfang Ihrer Pflichten zur Bereitstellung von Daten</span>
<span className="impressum">Automatisierte Entscheidungsfindung / Profiling</span>
<span className="impressum">Änderungen</span>

<span className="impressum-heading">Name und Kontaktdaten des Verantwortlichen</span>

<span className="impressum">Verantwortlicher im Sinne der DSGVO und sonstiger datenschutzrechtlicher Bestimmungen  Daniel Medding und Arndt Nietfeld. Die Kontaktdaten lauten:</span>
<span className="impressum">Daniel Medding</span>
<span className="impressum">E-Mail: daniel.medding@hellovet.de</span>
<span className="impressum">Telefon: 0176 21140756</span>
<span className="impressum">Arndt Nietfeld</span>
<span className="impressum">E-Mail: arndt.nietfeld@hellovet.de</span>
<span className="impressum">Website: www.hellovet.de</span>
<span className="impressum">Eine gesetzliche Pflicht zur Bestellung eines Datenschutzbeauftragten besteht nicht.</span>

<span className="impressum-heading">Zwecke und Rechtsgrundlagen der Datenverarbeitung</span>

<span className="impressum">Im Rahmen der Nutzung unserer Website verarbeiten wir personenbezogene Daten ausschließlich zu festgelegten Zwecken, die wir im Folgenden zu Ihrer Information im Detail erläutern.
Sie können HelloVet.de rein zu Informationszwecken ohne weitere aktive Mitteilung von Daten zu Ihrer Person besuchen. Über Ihren Besuch werden in diesem Fall lediglich technische Zugriffsdaten automatisiert gespeichert, die durch Ihren Browser übermittelt werden, um Ihnen den Besuch unserer Website in optimierter und bedarfsgerecht gestalteter Art und Weise zu ermöglichen und unsere Systeme vor Sicherheitsrisiken zu schützen (Aufruf/Besuch der Website, technische Bereitstellung) sowie Informationen, die uns bei entsprechender Browser-Cookieeinstellung zur statistischen Analyse der Nutzung unseres Webangebots übermittelt werden.
In welchem Umfang und in welcher Art und Weise Ihre Daten darüber hinaus verarbeitet werden, richtet sich insbesondere danach, welche Services, Funktionalitäten und Angebote unserer Website Sie aktiv in Anspruch nehmen. Insbesondere verarbeiten wir Daten, wenn Sie Kontakt mit uns aufnehmen, sich bei HelloVet.de registrieren, an einer HelloVet.de Videosprechstunde teilnehmen oder sonstige HelloVet.de Webservices nutzen.
Weitergehende Verarbeitungen Ihrer personenbezogenen Daten als in diesen Website-Datenschutzhinweisen genannt, erfolgen nur in Fällen, in denen eine Rechtsvorschrift uns hierzu ausdrücklich berechtigt bzw. verpflichtet oder Sie in die Datenverarbeitung explizit eingewilligt haben. Über etwaige Zweckänderungen werden wir Sie unter Berücksichtigung der gesetzlichen Voraussetzungen entsprechend informieren.
</span>

<span className="impressum" style={{fontWeight:"bold"}}>1. Aufruf und Besuch unserer Website</span>

<span className="impressum">Zum Zweck der technischen Bereitstellung der Website ist es erforderlich, dass wir bestimmte, automatisch übermittelte Informationen von Ihnen verarbeiten, damit Ihr Browser unsere Website anzeigen und Sie die Webservices nutzen können. Diese Informationen werden bei jedem Aufruf unserer Website automatisch erfasst und in unseren Serverlogfiles gespeichert. Diese Informationen beziehen sich auf das Browser-/Betriebssystem des aufrufenden Rechners. Folgende technische Zugriffsdaten werden dabei erhoben:</span>
<span className="impressum">    • IP-Adresse;</span>
<span className="impressum">    • Erkennungsdaten des verwendeten Browsers (Browsertyp/-version, Browsersprache,</span>
<span className="impressum">Auflösung des Browserfensters, etc.)</span>
<span className="impressum">    • Verwendetes Betriebssystem (Bsp.: Windows 10);</span>
<span className="impressum">    • Name des Internet Service Providers;</span>
<span className="impressum">    • Bildschirmauflösung;</span>
<span className="impressum">    • Javascriptaktivierung/-einstellungen;</span>
<span className="impressum">    • Cookie-Einstellungen;</span>
<span className="impressum">    • Internetseite, von der aus Sie uns besuchen;</span>
<span className="impressum">    • Datum und Uhrzeit Ihres Zugriffs.</span>
<span className="impressum">Die Speicherung der vorgenannten Zugriffsdaten erfolgt im Rahmen sogenannter Serverlogfiles auf unserem Webserver und ist zur Bereitstellung einer funktionsfähigen Website und zur Sicherstellung der Systemsicherheit aus technischen Gründen erforderlich. Dies gilt auch für die Speicherung Ihrer IP-Adresse, die notwendigerweise erfolgt und unter weiteren Voraussetzungen ggf. eine Zuordnung zu Ihrer Person ermöglichen kann. Über die vorstehend genannten Zwecke hinaus verwenden wir Zugriffsdaten ausschließlich zur bedarfsgerechten Gestaltung und Optimierung unseres Webangebotes rein statistisch und ohne Rückschluss auf Ihre Person. Eine Zusammenführung der technischen Zugriffsdaten mit anderen Datenquellen erfolgt nicht, auch eine Auswertung der Daten zu Marketingzwecken findet nicht statt.
Ferner setzen wir Cookies ein, um Ihnen unsere Website optimiert und bedarfsgerecht gestaltet zur Nutzung zur Verfügung zu stellen (zu den diesbezüglichen Details und Ihren entsprechenden Gestaltungsoptionen verweisen wir auf den Abschnitt unten “Verwendung von Cookies und zugehöriger Funktionen/Technologien“).
Die im Rahmen der Nutzung unserer Website erhobenen Zugriffsdaten werden nur für den Zeitraum aufbewahrt, für den diese Daten zur Erreichung der vorstehenden Zwecke benötigt werden. Ihre IP-Adresse wird hierbei auf unserem Webserver für maximal 7 Tage gespeichert.
Soweit Sie unsere Website besuchen, um sich über unser Service-/Leistungsangebot zu informieren oder dieses zu nutzen, erfolgt die vorübergehende Speicherung der Zugriffsdaten und Logfiles auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO (Rechtsgrundlage), insbesondere zur Durchführung vorvertraglicher Maßnahmen. Darüber hinaus dient vorliegend Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für die vorübergehende Speicherung der technischen Zugriffsdaten und Logfiles. Unser berechtigtes Interesse besteht hierbei darin, Ihnen eine technisch funktionierende und benutzerfreundlich gestaltete Website zur Verfügung stellen zu können sowie die Sicherheit unserer Systeme zu gewährleisten.
</span>

<span className="impressum" style={{fontWeight:"bold"}}>2. Registrierung von Nutzer-/Tierarztkonten und Nutzung unserer HelloVet.de Webservices</span>
<span className="impressum">
Sie können sich als Nutzer sowie als Tierarzt auf unserer Website registrieren und ein Nutzer- bzw. Tierarztkonto anlegen. Im Rahmen der Registrierung erheben wir als personenbezogene Daten Ihren Vor- und Nachnamen sowie Ihre E-Mail-Adresse. Für die Registrierung als Tierarzt erheben wir darüber hinaus weitere erforderliche und freiwillige Angaben zu Ihrer Person (Titel, Fachrichtung, Zusatzausbildung) sowie ggf. Daten einer zugehörigen tierärztlichen Klinik. Tierarzt-Konten werden erst nach erfolgter Verifikation manuell freigeschaltet.
Als registrierter Nutzer / TierarztInnen haben mittels eines selbstgewählten Benutzernamens oder ihrer Emailadresse und eines Passworts Zugang zu Ihrem Nutzer-/Tierarzt-Konto, in dem Sie Ihre persönlichen Daten weiter ergänzen und jederzeit ändern können. Sie können selbstverständlich auch jederzeit die Löschung Ihres Nutzer-/Tierarztkontos beantragen.
Als registrierter Nutzer haben Sie die Möglichkeit, Daten zu Ihren Tieren zu speichern, tierärztliche Termine zu vereinbaren und einzusehen, an Webinaren teilzunehmen, Videosprechstunden der registrierten Tierärzte zu besuchen und sonstige Webservices von HelloVet.de zu nutzen.
Damit Sie dieses Webserviceangebot nutzen können, verarbeiten wir teilweise weitergehende vertrags- und/oder zahlungsrelevante personenbezogene Daten, die sich je nach genutztem Webservice unterscheiden können und im Folgenden für einzelne Webservices gesondert dargestellt werden. Die Verarbeitung der Daten erfolgt zu Zwecken der Organisation und Abwicklung des gewählten Webserviceangebots sowie zur Ausführung Ihrer Buchungen und Verwaltung der bestehenden Nutzer-/Tierarztkonten. Je nach Webservice geben wir personenbezogene Daten ggf. an Dienstleistungspartner weiter, derer wir uns zur Vertragsabwicklung bedienen (insb. Kreditinstitute, Zahlungsabwickler, Logistik-Dienstleister).
Wir verarbeiten Ihre Daten – soweit im Folgenden nicht anders dargestellt – zum Zwecke der Abwicklung unseres Webserviceangebots auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO (Rechtsgrundlage).
</span>

<span className="impressum" style={{fontWeight:"bold"}}>3. Terminbuchungen</span>
<span className="impressum">
Zum Zwecke der Terminbuchung über HelloVet.de verarbeiten wir Daten zu Ihrer Person, einschließlich Ihrer Personenstamm- und Kontaktdaten sowie terminbezogener Informationen (wie Termindaten und Zweckbeschreibung der Terminvereinbarung, einschließlich Details zum zu behandelnden Tier) und übersenden diese verschlüsselt an den von Ihnen als Nutzer ausgewählten Tierarzt. Der Termin wird entsprechend in Ihrem persönlichen HelloVet.de Terminkalender vermerkt und ggf. werden Terminbestätigungen und -erinnerungen an Sie versandt.
Wir verarbeiten Ihre Daten zum Zwecke der Terminbuchung bei registrierten Tierärzten auf Grundlage Ihrer nach Art. 6 Abs. 1 lit. a DSGVO erteilten Einwilligung (Rechtsgrundlage).
</span>

<span className="impressum" style={{fontWeight:"bold"}}>4. Kontaktanfragen</span>
<span className="impressum">
Um Kontaktanfragen, die Sie uns z.B. an unsere Email-Adresse oder über unser Kontaktformular auf der Website zusenden, bearbeiten und beantworten zu können, verarbeiten wir die von Ihnen in diesem Zusammenhang mitgeteilten personenbezogenen Daten. Dazu zählen in jedem Fall Ihre E-Mail Adresse, um Ihnen eine Antwort zukommen zu lassen, sowie die sonstigen Informationen, die Sie uns im Rahmen Ihrer Mitteilung zur Konkretisierung Ihrer Anfrage zusenden.
Für die Zusendung geeigneter Informationen oder die gewünschte Kontaktaufnahme zu einem bestimmten Thema kann es erforderlich sein, dass wir die von Ihnen mitgeteilten Daten an externe Dienstleister (ggf. im Rahmen der Auftragsverarbeitung) weitergeben. Dies geschieht selbstverständlich nur im Rahmen der gesetzlichen Erlaubnisse und nur soweit zum Zwecke der Bearbeitung und Beantwortung Ihrer Kontaktanfrage erforderlich.
Die von Ihnen mitgeteilten Daten und Informationen werden zum Zwecke der Bearbeitung und Beantwortung Ihrer Kontaktanfrage – insbesondere bei Anfragen im Rahmen bestehender Mitgliedschaften – gemäß Art. 6 Abs. 1 lit. b DSGVO (Rechtsgrundlage) verarbeitet.
Wir speichern die im Zusammenhang mit Ihrer Kontaktaufnahme erhobenen personenbezogenen Daten zum Zwecke und für die Dauer der Bearbeitung Ihrer Anfrage.
</span>

<span className="impressum" style={{fontWeight:"bold"}}>5. Newsletter / Versendung von Marketinginformationen</span>
<span className="impressum">
Auf unserer Website besteht die Möglichkeit, einen kostenfreien Newsletter zu abonnieren. Dabei werden bei der Anmeldung zum Newsletter die Daten aus der Eingabemaske entsprechend an uns übermittelt. Wir erheben dabei Pflichtangaben wie Ihren Nachnamen und Ihre E-Mail-Adresse, um die persönliche Ansprache und den Versand unseres Newsletters zu ermöglichen, aber auch Angaben, die Sie uns ggf. freiwillig mitteilen (z.B. Ihr Titel, Ihr Vorname). Die freiwilligen Angaben nutzen wir, um unsere Newsletter-Mailings für Sie kundenfreundlich auszugestalten und Sie individualisiert ansprechen zu können.
Zudem werden bei der Newsletter-Anmeldung folgende technische Zugriffsdaten gespeichert: Datum und Uhrzeit der Newsletter-Anmeldung (Opt-In) sowie Ihre IP-Adresse. Damit Sie unseren E-Mail-Newsletter empfangen können, führen wir zudem zu Identifizierungs-/Nachweiszwecken das sog. Double-Opt-In-Verfahren durch und speichern die diesbezüglich bei uns eingehenden Daten (Datum und Uhrzeit der Newsletter-Aktivierung (Double-Opt-In) sowie Ihre IP-Adresse).
Darüber hinaus erhalten Sie unseren Newsletter bzw. weitere Marketinginformationen ggf. auch als Kunde unserer Serviceleistungen. Die Versendung des Newsletters dient dazu, Ihnen Informationen über für Sie relevante, weitere Services zukommen zu lassen.
Für die Zusendung des E-Mail-Newsletters kann es erforderlich sein, dass wir die vorbenannten Daten an externe Dienstleister (im Rahmen der Auftragsverarbeitung) weitergeben. Dies geschieht selbstverständlich nur im Rahmen der gesetzlichen Anforderungen.
Wir verarbeiten Ihre Daten für den vorgenannten Versand von Newslettern und Marketinginformationen sowie die Personalisierung der Ansprache im Falle Ihrer erteilten Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO (Rechtsgrundlage) bzw. bei entsprechendem Direkt-/Bestandskundenmarketing auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO (Rechtsgrundlage). Die Verarbeitung personenbezogener Daten zum Zwecke der Direktwerbung stellt eine berechtigten Interessen dienende Verarbeitung dar.
Sie können dem Versand des Newsletters bzw. weiterer Marketinginformationen selbstverständlich jederzeit widersprechen und Ihre diesbezüglich ggf. erteilte Einwilligung für die Zukunft ganz oder teilweise widerrufen, insbesondere indem Sie auf den in dem Newsletter vorgesehenen Link klicken und die Abbestellung durchführen oder eine E-Mail an datenschutz@hellovet.de senden.
</span>

<span className="impressum" style={{fontWeight:"bold"}}>6. Social Media Links</span>
<span className="impressum">
    Auf unserer Website sind Links zu Social Media Diensten wie Twitter und Xing eingebunden. Nach dem Anklicken der jeweiligen Links bzw. der eingebundenen Social Media Grafik werden Sie auf die Seite des jeweiligen Anbieters weitergeleitet. Wir weisen darauf hin, dass im Rahmen dessen keine Verarbeitung Ihrer Daten durch uns erfolgt und wir für die Inhalte von Internetseiten, auf die wir verlinken, nicht verantwortlich sind. Für den Fall, dass Sie einem Link zu den Social Media Diensten folgen, berücksichtigen Sie bitte die jeweiligen Datenverwendungsrichtlinien und Datenschutzhinweise dieser Anbieter.
</span>

<span className="impressum" style={{fontWeight:"bold"}}>7. Einhaltung gesetzlicher Vorschriften, insb. Aufbewahrung / Dokumentation</span>
<span className="impressum">
Wir verarbeiten Ihre personenbezogenen Daten zudem, um sonstige gesetzliche Pflichten, die uns im Zusammenhang mit unserem Webangebot und unseren Serviceleistungen treffen, zu erfüllen. Hierzu zählen insbesondere handels-, gewerbe- oder steuerrechtliche Aufbewahrungsfristen (siehe dazu auch unter „Dauer der Aufbewahrung“). Wir verarbeiten Ihre personenbezogenen Daten diesbezüglich gemäß Art. 6 Abs. 1 lit. c DSGVO (Rechtsgrundlage) zur Erfüllung einer rechtlichen Verpflichtung, der wir unterliegen.
</span>

<span className="impressum" style={{fontWeight:"bold"}}>8. Rechtsdurchsetzung</span>
<span className="impressum">
Wir verarbeiten Ihre personenbezogenen Daten zudem, um unsere Rechte geltend zu machen und unsere rechtlichen Ansprüche durchsetzen zu können. Ebenfalls verarbeiten wir Ihre personenbezogenen Daten, um uns gegen rechtliche Ansprüche verteidigen zu können. Schließlich verarbeiten wir Ihre personenbezogenen Daten, soweit dies zur Abwehr oder Verfolgung von Straftaten erforderlich ist.
Wir verarbeiten Ihre personenbezogenen Daten hierbei zur Wahrung unserer berechtigten Interessen gemäß Art. 6 Abs. 1 lit. f DSGVO (Rechtsgrundlage), soweit wir rechtliche Ansprüche geltend machen oder uns bei rechtlichen Streitigkeiten verteidigen oder wir Straftaten verhindern oder aufklären.
</span>

<span className="impressum-heading">Kategorien von Empfängern</span>
<span className="impressum">
Bei HelloVet.de erhalten diejenigen Personen Zugriff auf personenbezogene Daten, die diese zur rechtmäßigen Aufgabenerfüllung benötigen.
Darüber hinaus setzen wir im Rahmen der Bereitstellung unseres Webangebots externe Dienstleister ein bzw. beauftragen diese mit entsprechenden Leistungen. Folgende Kategorien von Empfängern erhalten hierzu ggf. personenbezogene Daten:
<span className="impressum">    • Unsere IT-Dienstleister zur auftragsgemäßen Pflege unserer Webservices und der technischen Betreuung des zugriffsgeschützten Bereichs für registrierte Nutzer / Tierärzte (Hosting, Support, etc.);</span>
<span className="impressum">    • ggf. Rechtsberater im Zusammenhang mit der Geltendmachung unserer Ansprüche.</span>
Es findet keine Übermittlung personenbezogener Daten in ein Drittland (außerhalb der Europäischer Union / des Europäischem Wirtschaftsraums) oder an eine internationale Organisation i.S.d. Art. 4 Nr. 26 DSGVO statt.
</span>

<span className="impressum-heading">Dauer der Speicherung</span>
<span className="impressum">
  Wir speichern Ihre personenbezogenen Daten zunächst für die Dauer, für die der jeweilige Nutzungszweck eine entsprechende Speicherung erfordert (vgl. oben). Das schließt auch die Anbahnung (vorvertragliches Rechtsverhältnis) und die Beendigung von Vertragsverhältnissen mit ein. Zusätzlich speichern wir Ihre personenbezogenen Daten ggf. bis zum Eintritt der Verjährung etwaiger rechtlicher Ansprüche aus der Beziehung mit Ihnen, um sie gegebenenfalls zu Nachweiszwecken einsetzen zu können. Die Verjährungsfrist beträgt in der Regel zwischen 12 und 36 Monaten, kann aber auch bis zu 30 Jahre betragen. Mit Eintritt der Verjährung löschen wir Ihre personenbezogenen Daten, es sei denn, es liegt eine gesetzliche Aufbewahrungspflicht, zum Beispiel aus dem Handelsgesetzbuch (§§ 238, 257 Abs. 4 HGB) oder aus der Abgabenordnung (§ 147 Abs. 3, 4 AO) vor. Diese Aufbewahrungspflichten können bis zu zehn Jahre betragen.
</span>

<span className="impressum-heading">Datensicherheit</span>
<span className="impressum">
Personenbezogene Daten werden durch uns mittels geeigneter technischer und organisatorischer Maßnahmen geschützt, um ein angemessenes Schutzniveau zu gewährleisten und die Persönlichkeitsrechte der Betroffenen zu wahren. Die getroffenen Maßnahmen dienen unter anderem der Vermeidung eines unerlaubten Zugriffs auf die durch uns genutzten technischen Einrichtungen sowie dem Schutz personenbezogener Daten vor unerlaubter Kenntnisnahme durch Dritte.
</span>

<span className="impressum-heading">Betroffenenrechte</span>
<span className="impressum">
Betroffenen Personen stehen entsprechend der gesetzlichen Voraussetzungen die nachfolgenden Rechte zu, die uns gegenüber jederzeit geltend gemacht werden können:</span>
<span className="impressum">• Auskunftsrecht: Betroffene Personen sind im Rahmen von Art. 15 DSGVO jederzeit berechtigt, eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden. Ist dies der Fall, ist die betroffene Person im Rahmen von Art. 15 DSGVO ferner berechtigt, Auskunft über diese personenbezogenen Daten sowie bestimmte weitere Informationen (u.a. Verarbeitungszwecke, Kategorien personenbezogener Daten, Kategorien von Empfängern, geplante Speicherdauer, ihre Rechte, die Herkunft der Daten) und eine Kopie der jeweiligen Daten zu erhalten. Es gelten die Einschränkungen des § 34 BDSG.</span>
<span className="impressum">• Recht auf Berichtigung: Betroffene Personen sind berechtigt, nach Art. 16 DSGVO zu verlangen, dass die über sie gespeicherten personenbezogenen Daten berichtigt werden, wenn diese unzutreffend oder fehlerhaft sind.</span>
<span className="impressum">• Recht auf Löschung: Betroffene Personen sind berechtigt, unter den Voraussetzungen von Art. 17 DSGVO zu verlangen, dass sie betreffende personenbezogene Daten unverzüglich gelöscht werden. Das Recht auf Löschung besteht u.a. nicht, wenn die Verarbeitung der personenbezogenen Daten erforderlich ist z.B. zur Erfüllung einer rechtlichen Verpflichtung (bspw. gesetzliche Aufbewahrungspflichten) oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. Darüber hinaus gelten die Einschränkungen des § 35 BDSG.</span>
<span className="impressum">• Recht auf Einschränkung der Verarbeitung: Betroffene Personen sind berechtigt, unter den Voraussetzungen von Art. 18 DSGVO die Einschränkung der Verarbeitung personenbezogener Daten zu verlangen.</span>
<span className="impressum">• Recht auf Datenübertragbarkeit: Betroffene Personen sind berechtigt, unter den Voraussetzungen von Art. 20 DSGVO zu verlangen, dass ihnen sie betreffende und eigens bereitgestellte personenbezogene Daten in einem strukturierten, gängigen und maschinenlesbaren Format übergeben werden.</span>
<span className="impressum">• Widerrufsrecht: Betroffene Personen haben das Recht, ihre erteilte Einwilligung in die Verarbeitung personenbezogener Daten jederzeit mit Wirkung für die Zukunft zu widerrufen.</span>
<span className="impressum">• Widerspruchsrecht: Betroffene Personen sind berechtigt, unter den Voraussetzungen von Art. 21 DSGVO Widerspruch gegen die Verarbeitung ihrer personenbezogenen Daten einzulegen, so dass wir die Verarbeitung der betreffenden personenbezogenen Daten beenden müssen. Das Widerspruchsrecht besteht nur in den in Art. 21 DSGVO vorgesehenen Grenzen.</span>

<span className="impressum">• Beschwerderecht bei einer Aufsichtsbehörde: Betroffene Personen sind berechtigt, unter den Voraussetzungen von Art. 77 DSGVO Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedsstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu erheben, wenn sie der Ansicht sind, dass die Verarbeitung der sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt. Das Beschwerderecht besteht unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs. Die für die Veticine Ventures GmbH zuständige Aufsichtsbehörde ist die Aufsichtsbehörde – Der Landesbeauftragte für den Datenschutz Niedersachsen – Prinzenstraße 5, 30159 Hannover.</span>
<span className="impressum">Die vorstehend beschriebenen Rechte und sonstige Anliegen können uns gegenüber in Schriftform oder elektronisch ausgeübt werden und sind an die oben unter „Verantwortlicher“ angegebene Kontaktadresse zu richten.
</span>

<span className="impressum-heading">Umfang Ihrer Pflichten zur Bereitstellung von Daten</span>
<span className="impressum">
Grundsätzlich sind Sie nicht verpflichtet, uns Ihre personenbezogenen Daten mitzuteilen. Wenn Sie dies jedoch nicht tun, werden wir Ihnen unsere Webservices nicht vollumfänglich zur Verfügung stellen können oder beispielsweise Ihre Anfragen an uns nicht beantworten können. Personenbezogene Daten, die wir nicht zwingend für die oben genannten Verarbeitungszwecke benötigen, sind entsprechend als freiwillige Angaben gekennzeichnet.
</span>

<span className="impressum-heading">Änderungen</span>
<span className="impressum">
Wir behalten uns das Recht vor, diese Datenschutzhinweise jederzeit zu ändern. Etwaige Änderungen werden durch Veröffentlichung der geänderten Datenschutzhinweise auf unserer Website bekannt gemacht. Soweit nicht ein anderes bestimmt ist, werden solche Änderungen sofort wirksam. Bitte prüfen Sie daher diese Datenschutzhinweise regelmäßig, um die jeweils aktuellste Version einzusehen.
</span>

<span className="impressum-heading" style={{marginTop:20, width:"100%", textAlign:"center"}}>Stand: Dezember 2020</span>
    </div>

    <img src={LineFooter} style={{marginTop:50, width:"100%", zIndex:0}}/>

    <div style={{  display: "flex", flexDirection: "row" }}>
      <div style={{paddingRight:30}}>
      <img src={Logo} className="logo" />
      </div>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
        <span className="footer-headings" >Rechtliches</span>
        <Link className="footer-elements" to="/datenschutz" >
        <span  >Datenschutz</span>
        </Link>
        <Link className="footer-elements" to="/impressum" >
        <span  >Impressum</span>
        </Link>
      </div>
    </div>
    <span style={{ marginTop: 90, marginBottom: 50, fontSize: 16, fontFamily: "WorkSans" }} >Veticine Ventures GmbH, Alle Rechte vorbehalten.</span>
    </div>
    </Content>
    </Layout>
  );

}

export default Datenschutz
