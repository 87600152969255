import React from "react"
import { Avatar, List, Divider, Popover, Form, Button, Input, Select, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import store from 'store'
import { Redirect, useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import profile from "../../../Profile_Pic.png";
import DatabaseService from "../../../services/database.service"
import ImageUpload from "../profile/ImageUpload"
import OpeningHours from "../profile/OpeningHours"
import { UserOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import UploadAvatar from "../profile/UploadAvatar"
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import SettingsIcon from '@material-ui/icons/Settings';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { StatusCodes as HttpStatus } from 'http-status-codes';

const { Option } = Select;

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <span style={{ fontSize: 20, fontWeight: "bold" }}>{children}</span>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class MeinePraxis extends React.Component {

    constructor(props) {
        super(props);
        console.log("MeinePraxis.js: this.props ", this.props);
        this.state = {
            admins: [],
            preselectedAdminID: {},
            clinicprofile: [],
            mitglieder: [],
            anfragen: [],
            fileList: this.parsePicturesJson(),
            clinicInfo: {},
            editModal: false,
            editAddress: false,
            address: this.props.clinic.anschrift,
            editSpezialgebiet: false,
            editAbout: false,
            about: this.props.clinicProfile.about,
            editLeistungsspektrum: false,
            leistungsspektrumDesc: [],
            leistungsspektrumNew: [],
            leistungsspektrumIdx: {},
            website: this.props.clinicProfile.website,
            editZugangsinformationen: false,
            zugangsinformationen: this.props.clinicProfile.zugangsinformationen,
            editBehandlerprofil: false,
            behandlerprofil: this.props.clinicProfile.behandlerprofil,
            editSprachen: false,
            leistungsspektrum: [],
            sprachen: [],
            sprachenString: this.props.clinicProfile.sprachen || '',
            showLeistunsspektrum: true,
            showZugangsinformationen: true,
            showBehandlerprofil: false,
            showOpeningtimes: true
        }

        this.updateClinicVets();
        this.getAdminList();
        this.getClinic();
    }

    showLeistunsspektrum = () => {
        this.setState({ showLeistunsspektrum: !this.state.showLeistunsspektrum })
    }

    showZugangsinformationen = () => {
        this.setState({ showZugangsinformationen: !this.state.showZugangsinformationen })
    }

    showBehandlerprofil = () => {
        this.setState({ showBehandlerprofil: !this.state.showBehandlerprofil })
    }

    showOpeningtimes = () => {
        this.setState({ showOpeningtimes: !this.state.showOpeningtimes })
    }

    parseLeistungsspektrumJson() {
        let res = []
        try {
            if (this.props.clinicProfile.leistungsspektrumTags) {
                res = JSON.parse(this.props.clinicProfile.leistungsspektrumTags);
            }
        }
        catch (error) {
        }
        return res;
    }

    // TODO: Wozu ist das?
    parsePicturesJson() {
        try {
            const parsedJson = JSON.parse(this.props.clinic.vetPictures);
            return parsedJson.vetPictures;
        }
        catch (error) {
            return [];
        }
    }

    updateClinicVets() {
        DatabaseService.clinic.profile
            .readAccepted(this.props.clinic.id)
            .then(response => {
                // MockPersonal
                // response = {
                //     data: {
                //         personal: [
                //             this.props.doctor,
                //             this.props.doctor,
                //             this.props.doctor,
                //         ],
                //         requests: [
                //             this.props.doctor,
                //             this.props.doctor,
                //             this.props.doctor,
                //         ]
                //     }
                // }
                this.setState({ mitglieder: response.data.personal, anfragen: response.data.requests })
            })
            .catch(e => {
                console.log(e);
            });
    }

    addDoctor = vetId => {
        DatabaseService.vet
            .addToClinic({ id: vetId })
            .then(response => {
                this.updateClinicVets();
            })
            .catch(e => {
                console.log(e);
            });
    };

    removeDoctor = vetId => {
        DatabaseService.vet
            .removeFromClinic({ id: vetId })
            .then(response => {
                this.updateClinicVets();
            })
            .catch(e => {
                console.log(e);
            });
    };

    setClinicAdmin = vetId => {
        DatabaseService.vet
            .setAdmin({ id: vetId, clinicID: this.props.clinic.id })
            .then(response => {
                window.location.reload();
            })
            .catch(e => {
                console.log(e);
            });
    };


    showModal = () => {
        this.setState({ editModal: true });
    };

    handleOk = (values) => {
        DatabaseService.clinic.profile
            .update({ clinicID: this.props.clinic.id, ausbildung: values.ausbildung });
        DatabaseService.clinic.profile
            .update({ clinicID: this.props.clinic.id, fachgebiet: values.fachgebiet });
        this.setState({ editModal: false });
    };

    handleCancel = () => {
        this.setState({ editModal: false });
    };

    addTag = e => {
        this.setState({ leistungsspektrum: this.state.leistungsspektrum.concat(e.target.value) }, () =>
            DatabaseService.clinic.profile
                .update({
                    clinicID: this.props.clinic.id,
                    leistungsspektrumTags: JSON.stringify(this.state.leistungsspektrum)
                })
        );
        console.log(e.target.value);
    }

    addLanguage = val => {
        this.setState({ sprachen: this.state.sprachen.concat(val) })
    }



    editSprachen = () => {
        if (this.state.editSprachen) {
            var sprachenString = this.state.sprachen.join(", ");
            DatabaseService.clinic.profile
                .update({ clinicID: this.props.clinicProfile.id, sprachen: sprachenString })
                .then(() => {
                    this.props.onChange({
                        sprachen: sprachenString
                    });
                })
                .finally(this.setState({ sprachenString: sprachenString, editSprachen: false }));
        }
        else {
            this.setState({
                sprachen: this.state.sprachenString ? this.state.sprachenString.split(', ') : []
            },
                () => this.setState({ editSprachen: true })
            )
        }
    }

    editAbout = () => {
        if (this.state.editAbout) {
            DatabaseService.clinic.profile
                .update({ clinicID: this.props.clinicProfile.id, about: this.state.about })
                .then(() => {
                    this.props.onChange({
                        about: this.state.about
                    });
                })
                .finally(this.setState({ editAbout: false }));
        } else {
            this.setState({ editAbout: true });
        }
    }

    editAddress = () => {
        if (this.state.editAddress) {
            DatabaseService.clinic
                .update({ id: this.props.clinicProfile.id, anschrift: this.state.address })
                .then(() => {
                    this.props.onChange({
                        anschrift: this.state.address
                    });
                })
                .finally(this.setState({ editAddress: false }));
        }
        else {
            this.setState({ editAddress: true });
        }
    }

    async editLeistungsspektrum(idx) {
        this.setState({ leistungsspektrumIdx: idx });

        if (this.state.editLeistungsspektrum) {
            var leistungssp = this.parseObj(this.state.clinicprofile.leistungsspektrum);

            if (this.state.leistungsspektrumDesc.length > 0) {
                leistungssp[this.state.leistungsspektrumIdx] = this.state.leistungsspektrumDesc;
            }else {
                if (idx > -1) {
                    leistungssp.splice(idx, 1);
                }
            }

            await DatabaseService.clinic.profile
                    .update({
                        clinicID: this.props.clinicProfile.id,
                        leistungsspektrum: JSON.stringify(leistungssp)
                    })

            this.getClinic();
            this.setState({ leistungsspektrumDesc: [], editLeistungsspektrum: false });
        }
        else {
            this.setState({ editLeistungsspektrum: true });
        }
    }

    editZugangsinformationen = () => {
        if (this.state.editZugangsinformationen) {
            DatabaseService.clinic.profile
                .update({ clinicID: this.props.clinicProfile.id, zugangsinformationen: this.state.zugangsinformationen })
                .then(() => {
                    this.props.onChange({
                        zugangsinformationen: this.state.zugangsinformationen
                    });
                })
                .finally(this.setState({ editZugangsinformationen: false }));
        }
        else {
            this.setState({ editZugangsinformationen: true });
        }
    }

    editBehandlerprofil = () => {
        if (this.state.editBehandlerprofil) {
            DatabaseService.clinic.profile
                .update({ clinicID: this.props.clinicProfile.id, behandlerprofil: this.state.behandlerprofil })
                .then(() => {
                    this.props.onChange({
                        behandlerprofil: this.state.behandlerprofil
                    });
                })
                .finally(this.setState({ editBehandlerprofil: false }));
        }
        else {
            this.setState({ editBehandlerprofil: true });
        }
    }

    editWebsite = (val) => {
        this.setState({ website: val });
        DatabaseService.clinic.profile
            .update({ clinicID: this.props.clinicProfile.id, website: val })
            .then(() => {
                this.props.onChange({
                    website: val
                });
            })
    }

    //TODO [vetID --> clinicID; this.props.doctor.id --> this.props.clinicProfile.id]
    onSaveOpeningsHours = (data) => {
        const openingsHours = data;
        console.log("MeinePraxis.js: onSaveOpeningsHours() openingsHours ", openingsHours);
        DatabaseService.clinic.profile
            .update({ clinicID: this.props.clinicProfile.id, oeffnungszeiten: JSON.stringify(openingsHours) })
            .then(() => {
                this.props.onChange({
                    oeffnungszeiten: openingsHours
                });
            })
    }

    onSaveAdmin = () => {
        DatabaseService.clinicpermissions
            .update({ clinicID: this.props.clinic.id, vetID: this.state.preselectedAdminID })
            .then(() => {
                var adminupdate;

                for (let i=0;i<this.state.admins.length;i++) {
                    if (this.state.admins[i].id == this.state.preselectedAdminID) {
                        adminupdate = this.state.admins[i];
                    }
                }

                DatabaseService.vet
                    .updateOne({
                        id: adminupdate.id,
                        titel: adminupdate.titel,
                        vorname: adminupdate.vorname,
                        nachname: adminupdate.nachname,
                        name: adminupdate.name,
                        email: adminupdate.email,
                        mobiltelefon: adminupdate.mobiltelefon,
                        geschlecht: adminupdate.geschlecht,
                        clinicID: adminupdate.clinicID,
                        clinicAdmin: "1",
                        clinicAccepted: adminupdate.clinicAccepted,
                        token: adminupdate.token,
                        verifiziert: adminupdate.verifiziert
                    })
                    .then(() => {})
            })
    }

    visibilityOnClick() {
        console.log("Clicked");
    }

    handleChange = (value) => {
        this.setState({ preselectedAdminID: value });
    };

    async handleChangeSpektrum() {
        var leistungssp = this.parseObj(this.state.clinicprofile.leistungsspektrum);
        leistungssp.push(this.state.leistungsspektrumNew);

        await DatabaseService.clinic.profile
            .update({
                clinicID: this.props.clinicProfile.id,
                leistungsspektrum: JSON.stringify(leistungssp)
            })


        this.getClinic();
        this.setState({ leistungsspektrumNew: [] });
    }

    getClinic() {
        DatabaseService.clinic.profile
            .readOne(this.props.clinicProfile.id)
            .then(response => {
                this.setState({ clinicprofile: response.data });
            })
    }

    parseObj(data) {
        if (typeof data !== "undefined" && data != null) {
            var str = data.split("&quot;");
            var output = [];

            for(let i=1;i<str.length;i=i+2) {
                output.push(str[i]);
            }

            return output;
        }

        return [];
    }

    renderOptions() {
        this.state.admins.sort((a, b) => {
            let fa = a.vorname.toLowerCase(),
                fb = b.vorname.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });

        return this.state.admins.map( element =>
            <Option key={element.id} value={element.id}>{element.vorname} {element.nachname}</Option>
        )
    }

    renderLeistungsspektrum() {
        if (this.state.clinicprofile.leistungsspektrum != null) {
            var leistungssp = this.parseObj(this.state.clinicprofile.leistungsspektrum);

            if (leistungssp != "") {
                return leistungssp.map( (element, idx) =>
                    <div>
                        {element}
                        {this.state.editLeistungsspektrum && this.state.leistungsspektrumIdx == idx ?
                            <Input placeholder="Leistungsspektrum" value={this.state.leistungsspektrumDesc} onChange={({ target: { value } }) => this.setState({ leistungsspektrumDesc: value })} /> :
                            <span style={{ color: "grey", marginTop: 7 }}></span>}
                            {this.props.doctor.clinicAdmin==1 &&
                                <IconButton style={{ color: "#25D2AA" }} onClick={() => this.editLeistungsspektrum(idx)}>
                                    {this.state.editLeistungsspektrum && this.state.leistungsspektrumIdx == idx ?
                                            <DoneIcon /> :
                                            <EditIcon />
                                    }
                                </IconButton>
                            }
                    </div>
                )
            }
        }

        return "";
    }

    async getAdminList() {
        var adminids = [];
        var adminarr = [];

        await DatabaseService.vet.profile
            .readAll(this.props.clinic.id)
            .then(response => {
                adminids = JSON.parse(response.data);
            })
            .catch(e => {
                console.log(e);
            })

        for (let i=0;i<adminids.length;i++) {
            const admin = await this.getAdmin(adminids[i]);

            if (admin.id != this.props.doctor.id && admin.clinicAdmin != 1) {
                adminarr.push(admin);
            }
        }

        this.setState({ admins: adminarr});
    }

    async getAdmin(element) {
        var admin;

        await DatabaseService.vet
            .readOne(element.vetID)
            .then(response => {
                admin = JSON.parse(JSON.stringify(response.data));
            })
            .catch(e => {
                console.log(e);
            })

        return admin;
    }

    async showpopup(id) {
        var popup = document.getElementById(id);

        if (!popup.classList.contains("show")) {
            popup.classList.add("show");

            let promise = new Promise((resolve, reject) => {
                setTimeout(() => resolve("done!"), 5000)
              });

            let result = await promise;

            popup.classList.remove("show");
        }
    }

    render() {
        const { selectedAdmin } = this.state.preselectedAdminID;
        return (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch", width: "85%" }}>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: 25, margin: 30 }}>
                    <div style={{ height: "100px", width: "100px" }}>
                        <UploadAvatar style={{ height: "100px", width: "100px" }} imageUrl={this.props.clinicProfile.profilePic} mode={"praxis"}/>
                    </div>
                    <div style={{ display: "flex", marginLeft: 20, flexDirection: "column" }}>
                        <span style={{ fontWeight: "bold", color: "#25D2AA", fontSize: 20 }}>{this.props.clinic.name}</span>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {this.state.editAddress ?
                                <Input placeholder="Addresse" value={this.state.address} onChange={({ target: { value } }) => this.setState({ address: value })} /> :
                                <span style={{ width: "500px", marginTop: 14, fontWeight: "bold" }}>{this.state.address}</span>
                            }
                            {this.props.doctor.clinicAdmin==1 &&
                                <IconButton style={{ color: "#25D2AA" }} onClick={() => this.editAddress()}>
                                    {this.state.editAddress ?
                                        <DoneIcon /> :
                                        <EditIcon />
                                    }
                                </IconButton>
                            }
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {this.state.editAbout ?
                                <Input placeholder="Über mich" value={this.state.about} onChange={({ target: { value } }) => this.setState({ about: value })} /> :
                                <span style={{ color: "grey", width: "500px", marginTop: 14 }}>{this.state.about}</span>
                            }
                            {this.props.doctor.clinicAdmin==1 &&
                                <IconButton style={{ color: "#25D2AA" }} onClick={() => this.editAbout()}>
                                    {this.state.editAbout ?
                                        <DoneIcon /> :
                                        <EditIcon />
                                    }
                                </IconButton>
                            }
                        </div>
                    </div>
                </div>
                <Divider />
                <IconButton style={{ color: "black", alignSelf: "flex-end" }} onClick={() => this.showLeistunsspektrum()}>
                    {this.state.showLeistunsspektrum ?
                        <VisibilityIcon />
                        :
                        <VisibilityOffIcon />
                    }
                </IconButton>
                {this.state.showLeistunsspektrum &&
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 25, margin: 30, marginTop: 0 }}>
                        <span style={{ fontWeight: "bold", fontSize: 20 }}>Leistungsspektrum</span>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                            {this.renderLeistungsspektrum()}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {this.props.doctor.clinicAdmin==1 &&
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", borderRadius: "3px", backgroundColor: "#f4f1ea", padding: 5, margin: 10 }}>
                                    <AddIcon onClick={() => this.handleChangeSpektrum()} />
                                    <Input placeholder="Hinzufügen" value={this.state.leistungsspektrumNew} onChange={({ target: { value } }) => this.setState({ leistungsspektrumNew: value })} bordered={false} onPressEnter={() => this.handleChangeSpektrum()} />
                                </div>
                            }
                        </div>
                    </div>
                }
                <Divider />
                <IconButton style={{ color: "black", alignSelf: "flex-end" }} onClick={() => this.showZugangsinformationen()}>
                    {this.state.showZugangsinformationen ?
                        <VisibilityIcon onClick={() => this.visibilityOnClick()}/>
                        :
                        <VisibilityOffIcon />
                    }
                </IconButton>
                {this.state.showZugangsinformationen &&
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: 25, margin: 30 }}>
                        <span style={{ fontWeight: "bold", fontSize: 20 }}>Addresse und Zugangsinformation</span>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 15 }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                <span style={{ fontWeight: "bold", marginTop: 20 }}>{this.props.clinic.name}</span>
                                <span style={{ color: "grey", marginTop: 7 }}>{this.props.clinicProfile.addresse},</span>
                                <span style={{ color: "grey", marginTop: 7 }}>{this.state.address}</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginLeft: 70 }}>
                                <span style={{ fontWeight: "bold", marginTop: 20 }}>Zugangsinformationen</span>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 15 }}>

                                    {this.state.editZugangsinformationen ?
                                        <TextField
                                            value={this.state.zugangsinformationen}
                                            onChange={({ target: { value } }) => this.setState({ zugangsinformationen: value })}
                                            multiline
                                            variant="outlined"
                                        /> :
                                        <>
                                            <span style={{ color: "grey", marginTop: 7 }}>{this.state.zugangsinformationen}</span>
                                        </>}
                                    {this.props.doctor.clinicAdmin==1 &&
                                        <IconButton style={{ color: "#25D2AA" }} onClick={() => this.editZugangsinformationen()}>
                                            {this.state.editZugangsinformationen ?
                                                <DoneIcon fontSize="large" /> :
                                                <EditIcon />
                                            }
                                        </IconButton>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <Divider />
                <IconButton style={{ color: "black", alignSelf: "flex-end" }} onClick={() => this.showBehandlerprofil()}>
                    {this.state.showBehandlerprofil ?
                        <VisibilityIcon onclick=""/>
                        :
                        <VisibilityOffIcon />
                    }
                </IconButton>
                {this.state.showBehandlerprofil &&
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 25, margin: 30, marginTop: 0 }}>
                        <span style={{ fontWeight: "bold", fontSize: 20 }}>Behandlerprofil</span>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {this.state.editBehandlerprofil ?
                                <Input placeholder="Behandlerprofil" value={this.state.behandlerprofil} onChange={({ target: { value } }) => this.setState({ behandlerprofil: value })} /> :
                                <span style={{ width: "500px", marginTop: 7 }}>{this.state.behandlerprofil} </span>}
                            {this.props.doctor.clinicAdmin==1 &&
                                <IconButton style={{ color: "#25D2AA" }} onClick={() => this.editBehandlerprofil()}>
                                    {this.state.editBehandlerprofil ?
                                        <DoneIcon /> :
                                        <EditIcon />
                                    }
                                </IconButton>
                            }
                        </div>
                        <div style={{ height: "15px" }} />
                        <ImageUpload doctor={this.props.clinic} />

                        <div style={{ display: "flex", flexDirection: this.state.editSprachen ? "column" : "row", marginTop: 15 }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ fontWeight: "bold", marginTop: 7 }}>Gesprochene Sprachen</span>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "500px" }}>
                                    {this.state.editSprachen ?
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            {this.state.sprachen.map((elem, index) => {
                                                return <div key={'praxisLanguage_' + index} style={{ display: "flex", flexDirection: "row", alignItems: "center", borderRadius: "3px", backgroundColor: "#f4f1ea", padding: 5, margin: 10 }}>
                                                    <span style={{ fontSize: 14 }}>{elem}</span>
                                                    <IconButton onClick={() => {
                                                        var sprachenArr = this.state.sprachen;
                                                        sprachenArr.splice(index, 1);
                                                        this.setState({ sprachen: sprachenArr })
                                                    }}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </div>
                                            })
                                            }
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", borderRadius: "3px", backgroundColor: "#f4f1ea", padding: 5, margin: 10 }}>
                                                <AddIcon />
                                                <Input placeholder="Hinzufügen" bordered={false} onPressEnter={({ target: { value } }) => this.addLanguage(value)} />
                                            </div>
                                        </div> :
                                        <span style={{ marginTop: 7 }}>{this.state.sprachenString}</span>
                                    }
                                    {this.props.doctor.clinicAdmin==1 &&
                                        <IconButton style={{ color: "#25D2AA" }} onClick={() => this.editSprachen()}>
                                            {this.state.editSprachen ?
                                                <DoneIcon fontSize="large" /> :
                                                <EditIcon />
                                            }
                                        </IconButton>
                                    }
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ fontWeight: "bold", marginTop: 7 }}>Website</span>
                                <Input style={{ marginTop: 7 }} addonBefore="http://" placeholder="beispiel.de" value={this.state.website} onChange={({ target: { value } }) => this.editWebsite(value)} />
                            </div>
                        </div>
                    </div>
                }
                <Divider />
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "90%", marginTop: 20 }}>
                    <List
                        itemLayout="horizontal"
                        dataSource={this.state.mitglieder}
                        header={<span style={{ fontWeight: "bold", fontSize: 20 }}>Personal</span>}
                        renderItem={item => (
                            <List.Item>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Avatar size={64} src="https://www.mainewomensnetwork.com/Resources/Pictures/vicki%20aqua%20headshot-smallmwn.jpg" />
                                    <div style={{ display: "flex", marginLeft: 20, flexDirection: "column" }}>
                                        <span style={{ fontWeight: "bold", color: "#25D2AA" }}>{item.name}</span>
                                        <span style={{ fontWeight: "bold", marginTop: 7 }}>Facharzt für Innere Medizin</span>
                                    </div>
                                    <Popover content={
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <Button size="large" style={{ borderColor: "black", color: "black" }} ghost shape="round" onClick={() => this.removeDoctor(item.vetID)}>
                                                Entfernen
                                            </Button>
                                            <div style={{ height: "5px" }} />
                                            <Button size="large" style={{ borderColor: "black", color: "black" }} ghost shape="round" onClick={() => this.setClinicAdmin(item.vetID)}>
                                                Zum Admin ernennen
                                            </Button>
                                        </div>
                                    }
                                        trigger="click">
                                        <IconButton component="span">
                                            <SettingsIcon />
                                        </IconButton>
                                    </Popover>
                                </div>
                            </List.Item>
                        )}
                    />

                    <List
                        itemLayout="horizontal"
                        dataSource={this.state.anfragen}
                        header={<span style={{ fontWeight: "bold", fontSize: 20 }}>Personal anfragen</span>}
                        renderItem={item => (
                            <List.Item>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Avatar size={64} src="https://www.mainewomensnetwork.com/Resources/Pictures/vicki%20aqua%20headshot-smallmwn.jpg" />
                                    <div style={{ display: "flex", marginLeft: 20, marginRight: 20, flexDirection: "column" }}>
                                        <span style={{ fontWeight: "bold", color: "#25D2AA" }}>{item.name}</span>
                                        <span style={{ fontWeight: "bold", marginTop: 7 }}>Facharzt für Innere Medizin</span>
                                    </div>
                                    <Button size="medium" style={{ borderColor: "black", color: "black" }} ghost shape="round" onClick={() => this.addDoctor(item.vetID)}>
                                        Annehmen
                                    </Button>
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
                <Divider />
                <IconButton style={{ color: "black", alignSelf: "flex-end" }} onClick={() => this.showOpeningtimes()}>
                    {this.state.showOpeningtimes ?
                        <VisibilityIcon />
                        :
                        <VisibilityOffIcon />
                    }
                </IconButton>
                {this.state.showOpeningtimes &&
                    <OpeningHours {...this.props} oeffnungszeiten={this.props.clinicProfile.oeffnungszeiten} onSave={this.onSaveOpeningsHours} />
                }
                {this.state.showOpeningtimes &&
                    <div style={{ height: "100px" }} />
                }
                <Divider />

                {this.props.doctor.clinicAdmin==1 &&
                    <Form.Item
                        label="Tierarzt auswählen"
                        name="tierarzt"
                        rules={[{ required: true, message: 'Bitte eine Auswahl treffen!' }]}
                    >
                        <Select
                            placeholder="Bitte auswählen"
                            showSearch
                            filterOption={false}
                            onSelect={this.handleChange}
                            >
                                {this.renderOptions()}
                        </Select>
                    </Form.Item>
                }
                {this.props.doctor.clinicAdmin==1 &&
                    <span>
                        <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "45px", fontSize: 18, width:"50%", margin:10}} shape="round" onClick={() => {this.onSaveAdmin();this.showpopup("myPopupconfirmAdmin")}} >
                            Zum Admin ernennen
                        </Button>
                        <div class="popup" style={{ left: "-25%" }}>
                            <span class="popuptext" id="myPopupconfirmAdmin">Admin gespeichert!</span>
                        </div>
                    </span>
                }
            </div>
        );
    }


}

export default MeinePraxis
