import Event   from './vetEvent';
import Patient from './vetPatient';
import Meeting from './vetMeeting';
import Profile from './vetProfile';


class VetAction {
    /**
     * Vet Action Class
     */
    constructor(axios) {
        this.http = axios;
        this._event = new Event(axios);
        this._patient = new Patient(axios);
        this._meeting = new Meeting(axios);
        this._profile = new Profile(axios);
    }


    /**
     * Vet Event Service
     */
     get event() {
        return this._event;
    }
    /**
     * Vet Patient Service
     */
    get patient() {
        return this._patient;
    }
    /**
     * Vet Meeting Service
     */
    get meeting() {
        return this._meeting;
    }
    /**
     * Vet Profile Service
     */
    get profile() {
        return this._profile;
    }


    /**
     * Create one vet
     * @param {Object} data
     * @returns Promise object
     */
    create(data) {
        return this.http.post("/api/vetbase/create.php", data);
    };
    /**
     * Read all events
     * @returns Promise object
     */
    read() {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Read one vet
     * @param {Object} id vet id
     * @returns Promise object
     */
    readOne(id) {
        return this.http.get(`/api/vetbase/read_by_index.php?id=${id}`);
    };
    /**
     * Update one vet
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return this.http.patch("/api/vetbase/update.php", data);
    };
    /**
     * Update one vet
     * @param {Object} data
     * @returns Promise object
     */
     updateOne(data) {
        return this.http.patch("/api/vetbase/updateOne.php", data);
    };
    /**
     * Delete one vet
     * @param {Object} id vet id
     * @returns Promise object
     */
    delete(id) {
        return this.http.delete(`/api/vetbase/delete.php?id=${id}`);
    };
    /**
     * Check vet email is available
     * @param {String} email vet email
     * @returns Promise object
     */
    checkEmail(email) {
        return this.http.get(`/api/vetbase/check_email.php?mail=${email}`);
    };
    /**
     * Check vet account is available
     * @param {Object} data vet data
     * @returns Promise object
     */
    checkAccount(data) {
        // return this.http.get(`/api/vetbase/auth.php?mail=${data.email}&passwort=${data.passwort}`);
        return this.http.get(`/api/vetbase/auth.php?mail=${data.email}`);
    };
    /**
     * Add vet to clinic
     * @param {Object} data vet data
     * @returns Promise object
     */
    addToClinic(data) {
        return this.http.post("/api/vetbase/add_to_clinic.php", data);
    };
    /**
     * Remove vet from clinic
     * @param {Object} data vet data
     * @returns Promise object
     */
    removeFromClinic(data) {
        return this.http.post("/api/vetbase/remove_from_clinic.php", data);
    };
    /**
     * Set vet as clinic admin
     * @param {Object} data vet data
     * @returns Promise object
     */
    setAdmin(data) {
        return this.http.post("/api/vetbase/set_clinic_admin.php", data);
    };
    /**
     * Update one vet by token
     * @param {Object} data
     * @returns Promise object
     */
    updateByToken(data) {
        return this.http.post("/api/vetbase/update_with_token.php", data);
    };

}

export default VetAction;
