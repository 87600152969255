import React from "react"
import Paper from '@material-ui/core/Paper';
import ChatComp from "../../chat/ChatComp"

class ChatCard extends React.Component{

    render(){
        return(
            <Paper elevation={3} style={{display:"flex", flexDirection:"column", justifyContent:"space-around", padding: 25, overflow:"auto"}}>
                <span style={{ fontSize:20, fontWeight:"bold", marginBottom:30}}>Chats [ChatCard.js]</span>
                <ChatComp doctor={this.props.doctor} selectedChat={this.props.selectedChat} />
            </Paper>
        )
    }
}

export default ChatCard