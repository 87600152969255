import React from 'react';
import { List, Avatar, Collapse, Card, Skeleton, Descriptions, Tooltip, Typography } from 'antd';
import {
    PhoneTwoTone,
    MessageTwoTone,
    UserOutlined
} from '@ant-design/icons';
import Dialog from '@material-ui/core/Dialog';
import AnimalsList from "./AnimalsList"
import DatabaseService from "../../../services/database.service";
import store from 'store';
import profile from "../../../Profile_Pic.png";
import Paper from '@material-ui/core/Paper';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import UserProfileModal from "../UserProfileModal"

const { Panel } = Collapse;
const { Title } = Typography;

class PatientsCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [
                {},
                {},
                {},
                {},
            ],
            loading: true,
            dialogOpen: false,
            usr: {}
        };

        DatabaseService.vet.patient
            .read(store.get('usrId'))
            .then(response => {
                this.setState({ data: response.data.records, loading: false });
            })
            .catch(e => {
                console.log(e);
                this.setState({ data: [], loading: false });
            });

    }

    render() {
        return (
            <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", padding: 25, overflow: "auto" }}>
                <span style={{ fontSize: 20, fontWeight: "bold", marginBottom: 30 }}>Alle Patientenbesitzer</span>
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.data}
                    renderItem={item => (
                        <List.Item >
                            <Skeleton avatar loading={this.state.loading} active>
                                <List.Item.Meta
                                    avatar={<Avatar src={item.profilePic} icon={<UserOutlined />} />}
                                    title={<Title level={4}>{item.vorname + " " + item.nachname}</Title>}
                                    onClick={() => this.setState({ dialogOpen: true, usr: item })}
                                />
                            </Skeleton>
                        </List.Item>
                    )}
                />
                <UserProfileModal startConf={this.props.startConf} open={this.state.dialogOpen} onClose={() => this.setState({ dialogOpen: false })} user={this.state.usr} />
            </Paper>
        );
    }

}

export default PatientsCard;
