// @flow

import React from 'react';

/**
 * React {@code Component} representing the Header of an audio option group.
 *
 * @returns { ReactElement}
 */
export default function AudioSettingsHeader({ IconComponent, text }) {
    return (
        <div className = 'audio-preview-header'>
            <div className = 'audio-preview-header-icon'>
                { <IconComponent/>}
            </div>
            <div className = 'audio-preview-header-text'>{text}</div>
        </div>
    );
}
