import React from "react"
import { notification, Card, Avatar, Tooltip, Descriptions, Button, Modal, Input, Select } from 'antd';
import store from 'store'
import ConferencePage from "./ConferencePage"
import DatabaseService from "../services/database.service"
import WaitingRoom from "./WaitingRoom"
import isLoggedIn from "../is_logged_in"
import { withRouter } from "react-router";
import 'dotenv/config';
import { StatusCodes as HttpStatus } from 'http-status-codes';


class ConferenceController extends React.Component {

    constructor(props) {
        super(props);
        console.log("ConferenceController.js: this.props ", this.props);

        this.state = {
            conferenceUrl: process.env.REACT_APP_CONFERENCE_URL,
            //conferenceUrl: "meet.callavet-conferencing.de",
            ws: null,
            admin: false,
            waiting: true,
            roomID: this.props.match.params.roomID,
            profile: null,
            loaded: false,
            meeting: {}
        };
    }

    componentDidMount() {
        DatabaseService.vet.profile
            .readOne(store.get('usrId'))
            .then(response => {
                if (response.status === HttpStatus.OK) {
                    this.setState({ admin: true });
                }
            })
            .catch(e => {
                console.log(e);
            });

        setInterval(async () => {
            this.updateMeetings();
            this.start();
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    updateMeetings() {
        DatabaseService.vet.meeting
            .readbymeetid(this.state.roomID)
            .then(response => {
                this.setState({ meeting: response.data });
            })
            .catch(e => {
                console.log(e);
            });
    }

    start() {
        DatabaseService.vet.meeting
            .readbymeetid(this.state.roomID)
            .then(response => {
                this.setState({ meeting: response.data });
                if (response.data.confirmed == 1) {
                    this.setState({
                        waiting: false
                    });
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {
        if (this.state.waiting) {
            return (
                <div>
                    <WaitingRoom profile={this.state.profile} roomID={this.state.roomID} ws={this.state.ws} admin={this.state.admin} />
                </div>
            );
        }
        return (
            <div>
                <ConferencePage meeting={this.state.meeting} profilesLoaded={this.state.loaded} roomID={this.state.roomID} profile={this.state.profile} />
            </div>
        );
    }

}

export default withRouter(ConferenceController)
