import React from 'react';
import { List, Avatar,Collapse, Card, Input,Button, Typography, Row, Col, Divider } from 'antd';
import Paper from '@material-ui/core/Paper';
import {
  CloseOutlined,
  CheckOutlined,
  PlusOutlined
} from '@ant-design/icons';

const { Panel } = Collapse;
const { Title } = Typography;

const { TextArea } = Input;

class MarkerCard extends React.Component {

  render(){
      return(
        <Paper elevation={3} style={{display:"flex", flexDirection:"column", justifyContent:"space-around", padding: 25, overflow:"auto"}}>
        <span style={{ fontSize:20, fontWeight:"bold", marginBottom:30}}>Marker</span>
        <List
        footer={<Button onClick={()=>this.props.setMarkers(this.props.markers.concat({ time: new Date().valueOf() - this.props.recordingStartTime.valueOf(), notice: "" }))} size="large" icon={<PlusOutlined />}/>}
        bordered
        style={{height:"100%"}}
        dataSource={this.props.markers}
        renderItem={item => (
          <List.Item>
           <Collapse style={{width:"100%"}}>
              <Panel header={Math.round(item.time/1000)+" Sekunden"} key="1">
                <TextArea onPressEnter={e => {    const { value } = e.target;   item.notice=value}} defaultValue={item.notice}/>
              </Panel>
           </Collapse>
          </List.Item>
        )}
        />
        </Paper>
      );
    }

  }

  export default MarkerCard
