import React from "react";
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import DatabaseService from "../../../services/database.service";
import store from 'store'

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

async function beforeUpload(file, fileList, imageUUID, mode) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    else {
        // FIXME: there is no api end point available
        if (mode == "profil") {
            DatabaseService.vet.profile
                .update({ vetID: store.get('usrId'), profilePic  : 'https://s3-de-central.profitbricks.com/cav-user-files/' + imageUUID })
        }else if (mode == "praxis") {
            var id = "";

            await DatabaseService.vet.profile
                .readOne(store.get('usrId'))
                .then(response => {
                    console.log("RESPONSEDATA: " + JSON.stringify(response));

                    id = response.data.clinicID;
                })

            console.log("ID: " + id);

            DatabaseService.clinic.profile
                .update({ clinicID: id, profilePic  : 'https://s3-de-central.profitbricks.com/cav-user-files/' + imageUUID })
        }
    }
    return isJpgOrPng;
}

class Avatar extends React.Component {
    state = {
        loading: false,
        imageUUID: uuidv4(),
        imageUrl: this.props.imageUrl,
        mode: this.props.mode
    };

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                }),
            );
        }
    };

  render() {
    const { loading, imageUrl } = this.state;
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    return (
        <Upload
            name="avatar"
            listType="picture-card"
            style={this.props.style}
            showUploadList={false}
            action={process.env.REACT_APP_HTTP_S3_BASE_URL + "/uploadPP/" + this.state.imageUUID}
            beforeUpload={(file, fileList) => beforeUpload(file, fileList, this.state.imageUUID, this.state.mode)}
            onChange={this.handleChange}
        >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
    );
  }
}

export default Avatar
