
class VetProfileAction {
    /**
     * VetProfile Action Class
     */
    constructor(axios) {
        this.http = axios;
    }


    /**
     * Create one vet profile
     * @param {Object} data
     * @returns Promise object
     */
    create(data) {
        return this.http.post("/api/vetprofile/create.php", data);
    };
    /**
     * Read all vet profiles
     * @returns Promise object
     */
    read() {
        return this.http.get("/api/vetprofile/read.php");
    };
    /**
     * Read one vet profile
     * @param {Object} id vet id
     * @returns Promise object
     */
    readOne(vetId) {
        return this.http.get(`/api/vetprofile/read_by_index.php?id=${vetId}`);
    };
    /**
     * Read All vet profile By clinic id
     * @param {Object} id vet id
     * @returns Promise object
     */
    readAll(clinicID) {
        return this.http.get(`/api/vetprofile/read_by_index_clinic.php?id=${clinicID}`);
    };
    /**
     * Update one vet profile
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return this.http.post(`/api/vetprofile/update.php`, data);
    };
    /**
     * Delete one vet profile
     * @param {Object} id vet id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Search one vet profile
     * @param {Object} vetId vet id
     * @returns Promise object
     */
    search(vetId) {
        return this.http.get(`/api/vetprofile/read_vet_clinic.php?id=${vetId}`);
    };
}

export default VetProfileAction;
