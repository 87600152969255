
class ChatAction {
    /**
     * Chat Action Class
     */
    constructor(axios) {
        this.http = axios;        
    }


    /**
     * Create one chat
     * @param {Object} data 
     * @returns Promise object
     */
    create(data) {
        return this.http.post("/api/chats/create.php", data);
    };
    /**
     * Read all chats by id belongs to user
     * @param {Object} userId user id
     * @returns Promise object
     */
    read(userId) {
        return this.http.get(`/api/chats/read_by_index.php?id=${userId}`);
    };
    /**
     * Read one chat
     * @param {Object} id chat id
     * @returns Promise object
     */
    readOne(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Update one chat
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Delete one chat
     * @param {Object} id chat id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Check if chat exists
     * @param {Object} vetId vet id
     * @param {Object} userId user id
     * @returns Promise object
     */
    exists(vetId, userId) {
        return this.http.get(`/api/chats/chat_exists.php?myID=${vetId}&usrID=${userId}`);
    };


}

export default ChatAction;