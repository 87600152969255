import Event from "./animalOwnerEvent";

class AnimalOwner {
    /**
     * AnimalOwner Action Class
     */
    constructor(axios) {
        this.http = axios;
        this._event = new Event(axios);
    }


    /**
     * Owner Event Service
     */
    get event() {
        return this._event;
    }

    
    /**
     * Create one owner
     * @param {Object} data 
     * @returns Promise object
     */
    create(data) {
        return Promise.reject('NOT_SUPPORTED');
    };
    /**
     * Read all owners
     * @param {Array} idList list of owner id's
     * @returns Promise object
     */
    read(idList) {
        return this.http.post(`/api/userbase/read_multiple.php`, {
            ids: idList
        });
    };
    /**
     * Read one owner
     * @param {string} id owner id
     * @returns Promise object
     */
    readOne(id) {
        return this.http.get(`/api/userbase/read_by_index.php?id=${id}`);
    };
    /**
     * Update one owner
     * @param {Object} data 
     * @returns Promise object
     */
    update(data) {
        return Promise.reject('NOT_SUPPORTED');
    };
    /**
     * Delete one owner - NOT_IMPLEMENTED!
     * @param {Object} id of an owner 
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_SUPPORTED');
    };
    /**
     * Read all animal belongs to the owner
     * @param {string} ownerId owner id
     * @returns Promise object
     */
    readAllAnimals(ownerId) {
        return this.http.get(`/api/animalbase/read_by_index.php?id=${ownerId}`);
    };

}

export default AnimalOwner;