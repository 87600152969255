import axios from "../http-common";
import httpOrigin from "../http-common-origin";
import whereAmI from "../whereAmI";
import Animal from './actions/animal';
import AnimalOwner from './actions/animalOwner';
import CallHistory from './actions/callHistory';
import Chat from './actions/chat';
import Clinic from './actions/clinic';
import Conference from './actions/conference';
import Meeting from './actions/meeting';
import Notification from './actions/notification';
import Registration from './actions/registration';
import Vet from './actions/vet';
import Documents from './actions/documents';
import Permissions from './actions/clinicPermissions';


/**
 * Database Service Class
 */
class DatabaseService {
    /**
     * Database Service
     */
    constructor() {
        // console.log("database.service.js: axios ", axios);
        axios.defaults.baseURL = axios.defaults.baseURL.replace("/register-validated", "");
        axios.defaults.baseURL = axios.defaults.baseURL.replace("/konferenz", "");
        // console.log("database.service.js: axios ", axios);
        this.http = axios;
        this._animal = new Animal(axios);
        this._animalOwner = new AnimalOwner(axios);
        this._callHistory = new CallHistory(axios);
        this._chat = new Chat(axios);
        this._clinic = new Clinic(axios);
        this._conference = new Conference(axios);
        this._meeting = new Meeting(axios);
        this._notification = new Notification(axios);
        this._registration = new Registration(axios);
        this._vet = new Vet(axios);
        this._documents = new Documents(axios);
        this._clinicpermissions = new Permissions(axios);
    }

    /**
     * Animal Service
     */
    get animal() {
        return this._animal;
    }

    /**
     * Animal Owner (alias userbase) Service
     */
    get AnimalOwner() {
        return this._animalOwner;
    }

    /**
     * Call History Service
     */
    get callHistory() {
        return this._callHistory;
    }

    /**
     * Chat Service
     */
    get chat() {
        return this._chat;
    }

    /**
     * Registration Service
     */
    get clinic() {
        return this._clinic;
    }

    /**
     * Conference Service
     */
    get conference() {
        return this._conference;
    }

    /**
     * Meeting Service
     */
    get meeting() {
        return this._meeting;
    }

    /**
     * Notification Service
     */
    get notification() {
        return this._notification;
    }

    /**
     * Registration Service
     */
    get registration() {
        return this._registration;
    }

    /**
     * Vet Service
     */
    get vet() {
        return this._vet;
    }

    /**
     * Document Service
     */
    get documents() {
        return this._documents;
    }


    /**
     * Clinic Permissions Service
     */
     get clinicpermissions() {
        return this._clinicpermissions;
    }

    // Wenn die URL bei der Registrierung in der api/verify-mail.php
    // umgebogen wurde, dann muss sie für RegistrierenStep2.js wieder
    // korrigiert werden
    // "falsche":  /register-validated/api.hellovet.de/api/clinicbase/create.php
    // "richtige": /api.hellovet.de/api/clinicbase/create.php
    // Aus der baseUrl muss "/register-validated" entfernt werden.
    fixOrigin() {
        console.log("database.service.js: fixOrigin() this.http %s >> %s", this.http, httpOrigin);
        this.http = httpOrigin;
    }


    // Obsolete: api end point not available, but stil used
    updateVetParameters(id, data) {
        return Promise.reject("NOT_AVAILABLE");
        // return this.http.put(`/update_vet_parameters.php/?id=${id}`, data);
    }







    // getConferenceParticipants(ids) {
    //     return this.http.post(`/get_meeting_participants.php`, {
    //         ids: ids
    //     });
    // }

    // joinConference(data) {
    //     return this.http.post("/join_conference.php", data);
    // }





    // OBSOLETE FUNCTIONS!
    // TODO: REMOVE IF THE NEW INTERFACE IS READY

    // createNotification(data) {
    //     return this.http.post("/api/notifications/create.php", data);
    // }

    // getNotifications(id) {
    //     return this.http.get(`/api/notifications/read_by_index.php?id=${id}`);
    // }


    // getAllPets(id) {
    //     return this.http.get(`/api/animalbase/read_by_index.php?id=${id}`);
    // }


    // getUsers(id) {
    //     return this.http.get(`/api/userbase/read_by_index.php?id=${id}`);
    // }
    // getMultipleUsers(ids) {
    //     return this.http.post(`/api/userbase/read_multiple.php`, {
    //         ids: ids
    //     });
    // }


    // checkConferenceRequests(id) {
    //     return this.http.get(`/api/conference/get_requests.php?id=${id}`);
    // }

    // checkConfirmed(id) {
    //     return this.http.get(`/api/conference/check_confirmed.php?id=${id}`);
    // }

    // createConferenceAccount(data) {
    //     return this.http.post("/api/conference/create.php", data);
    // }

    // getConferenceAccount(id) {
    //     return this.http.get(`/api/conference/read_by_index.php?id=${id}`);
    // }

    // getUsersWithToken(token) {
    //     return this.http.get(`/get_user.php/?token=${token}`);
    // }

    // checkUsernameAvail(id) {
    //     return this.http.post(`/check_username.php`, {
    //         id: id
    //     });
    // }


    // createUsers(data) {
    //     return this.http.post("/create_user.php", data);
    // }

    // updateUsers(id, data) {
    //     return this.http.put(`/update_user.php/?id=${id}`, data);
    // }


    // getAllClinic() {
    //     return this.http.get("/get_all_clinic.php");
    // }

    // createPet(data) {
    //     return this.http.post("/create_animal.php", data);
    // }

    // getPet(id) {
    //     return this.http.get(`/get_pet.php/?id=${id}`);
    // }

    // getMultiplePets(ids) {
    //     return this.http.post(`/get_multiple_pets.php`, {
    //         ids: ids
    //     });
    // }

    // createEvent(data) {
    //     return this.http.post("/create_event.php", data);
    // }

    // getEvents(data) {
    //     return this.http.put("/get_events.php", data);
    // }

    // getEventsUsers(data) {
    //     return this.http.put("/get_user_events.php", data);
    // }

    // updateVets(id, data) {
    //     return this.http.put(`/update_vet.php/?id=${id}`, data);
    // }


    // getEventsVets(id) {
    //     return this.http.get(`/api/events/read_by_index.php?id=${id}`);
    // }
    // updateEventsVets(data) {
    //     return this.http.post("/api/events/update.php", data);
    // }
    // getUserEvents(vetID, userID) {
    //     return this.http.get(`/api/events/read_by_index_user_vet.php?id=${userID}&vetID=${vetID}`);
    // }


    // createRegistrationMail(data) {
    //     return this.http.post("/api/mailservice/registration_mail.php", data);
    // }


    // updateVetPictures(id, data) {
    //     return this.http.put(`/update_vet_pictures.php/?id=${id}`, data);
    // }


    // createClinic(data) {
    //     return this.http.post("/api/clinicbase/create.php", data);
    // }

    // getClinic(id) {
    //     return this.http.get(`/api/clinicbase/read_by_index.php?id=${id}`);
    // }


    // createChat(data) {
    //     return this.http.post("/api/chats/create.php", data);
    // }

    // getChats(id) {
    //     return this.http.get(`/api/chats/read_by_index.php?id=${id}`);
    // }

    // chatExists(myID, usrID) {
    //     return this.http.get(`/api/chats/chat_exists.php?myID=${myID}&usrID=${usrID}`);
    // }


    // getClinicInfo(id) {
    //     return this.http.get(`/api/clinicprofile/read_by_index.php?id=${id}`);
    // }

    // getClinicDoctors(id) {
    //     return this.http.get(`/api/clinicprofile/get_personal.php?id=${id}`);
    // }

    // updateClinicProfile(data) {
    //     return this.http.post("/api/clinicprofile/update.php", data);
    // }

    // createClinicprofile(data) {
    //     return this.http.post("/api/clinicprofile/create.php", data);
    // }

    // getClinicStaff(id) {
    //     return this.http.get(`/api/clinicprofile/get_staff.php?id=${id}`);
    // }


    // searchClinic(id) {
    //     return this.http.get(`/search-clinic.php?id=${id}`);
    // }



    // updateAbout(id, data) {
    //     return this.http.put(`/update_about.php/?id=${id}`, data);
    // }

    // updateBehandlerprofil(id, data) {
    //     return this.http.put(`/update_behandlerprofil.php/?id=${id}`, data);
    // }

    // updateFachbereich(id, data) {
    //     return this.http.put(`/update_fachbereich.php/?id=${id}`, data);
    // }

    // updateGesprocheneSprachen(id, data) {
    //     return this.http.put(`/update_gesprochene_sprachen.php/?id=${id}`, data);
    // }

    // updateLeistungsspektrum(id, data) {
    //     return this.http.put(`/update_leistungsspektrum.php/?id=${id}`, data);
    // }

    // updateLeistungsspektrumTags(id, data) {
    //     return this.http.put(`/update_leistungsspektrum_tags.php/?id=${id}`, data);
    // }

    // updateOeffnungszeiten(id, data) {
    //     return this.http.put(`/update_oeffnungszeiten.php/?id=${id}`, data);
    // }

    // updatePraxisbilder(id, data) {
    //     return this.http.put(`/update_praxisbilder.php/?id=${id}`, data);
    // }

    // updateWebsite(id, data) {
    //     return this.http.put(`/update_website.php/?id=${id}`, data);
    // }

    // updateZugangsinformationen(id, data) {
    //     return this.http.put(`/update_zugangsinformationen.php/?id=${id}`, data);
    // }


    // createNewConference(data) {
    //     return this.http.post("/api/meetings/create.php", data);
    // }

    // deleteConference(id) {
    //     return this.http.delete(`/api/meetings/delete.php?id=${id}`);
    // }


    // createHistoryEntry(data) {
    //     return this.http.post("/api/callhistory/create.php", data);
    // }

    // getCallHistory(id) {
    //     return this.http.get(`/api/callhistory/read_by_index.php?id=${id}`);
    // }



    // createVetPatient(data) {
    //     return this.http.post("/api/vetpatients/create.php", data);
    // }

    // getVetPatientVets(id) {
    //     return this.http.get(`/api/vetpatients/read_by_index.php?id=${id}`);
    // }



    // getConferenceHistory(id) {
    //     return this.http.get(`/api/vetmeetings/read_by_index.php?id=${id}`);
    // }

    // createVetMeeting(data) {
    //     return this.http.post("/api/vetmeetings/create.php", data);
    // }

    // getCurrentMeeting(id, meetingID) {
    //     return this.http.get(`/api/vetmeetings/get_current_meeting.php?id=${id}&meetingID=${meetingID}`);
    // }

    // updateConfirmed(id) {
    //     return this.http.get(`/api/vetmeetings/update_confirmed.php?id=${id}`);
    // }


    // updateVetForm(id, data) {
    //     return this.http.post(`/api/vetprofile/update.php`, data);
    // }

    // getAllVets() {
    //     return this.http.get("/api/vetprofile/read.php");
    // }

    // createVetprofile(data) {
    //     return this.http.post("/api/vetprofile/create.php", data);
    // }

    // updateVetProfile(data) {
    //     return this.http.post("/api/vetprofile/update.php", data);
    // }

    // getVetProfile(id) {
    //     return this.http.get(`/api/vetprofile/read_by_index.php?id=${id}`);
    // }

    // getSearchedVetProfile(id) {
    //     return this.http.get(`/api/vetprofile/read_vet_clinic.php?id=${id}`);
    // }



    // updateUserWithToken(data) {
    //     return this.http.post("/api/vetbase/update_with_token.php", data);
    // }

    // checkEmailAvail(id) {
    //     return this.http.get(`/api/vetbase/check_email.php?mail=${id}`);
    // }

    // addVetToClinic(data) {
    //     return this.http.post("/api/vetbase/add_to_clinic.php", data);
    // }

    // removeVetFromClinic(data) {
    //     return this.http.post("/api/vetbase/remove_from_clinic.php", data);
    // }

    // setClinicAdmin(data) {
    //     return this.http.post("/api/vetbase/set_clinic_admin.php", data);
    // }

    // updateVet(data) {
    //     return this.http.patch("/api/vetbase/update.php", data);
    // }

    // NO API END POINT!
    // updateVetProfilePic(id, data) {
    //     return this.http.patch(`/api/vetbase/update.php/?id=${id}`, data);
    // }

    // deleteVet(id) {
    //     return this.http.delete(`/api/vetbase/delete.php?id=${id}`);
    // }

    // getVets(id) {
    //     return this.http.get(`/api/vetbase/read_by_index.php?id=${id}`);
    // }

    // createVets(data) {
    //     return this.http.post("/api/vetbase/create.php", data);
    // }

    // checkPWVets(data) {
    //     return this.http.get(`/api/vetbase/auth.php?mail=${data.email}&passwort=${data.passwort}`);
    // }

}

export default new DatabaseService();
