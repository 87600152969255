class ClinicPermissionsAction {
    /**
     * ClinicPermissions Action Class
     */
    constructor(axios) {
        this.http = axios;
    }

    /**
     * Create one clinic permission
     * @param {Object} data
     * @returns Promise object
     */
     create(data) {
        return this.http.post("/api/clinicpermissions/create.php?clinicID=" + data.clinicID + "&vetID=" + data.vetID);
    };
    /**
     * Read all clinic permissions by id
     * @param {Object} clinicId clinic profile id
     * @returns Promise object
     */
     readAll(clinicId) {
        return this.http.get(`/api/clinicpermissions/read_by_index.php?id=${clinicId}`);
    };
    /**
     * Update one clinic permission
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return this.http.post("/api/clinicpermissions/update.php?clinicID=" + data.clinicID + "&vetID=" + data.vetID);
    };
}

export default ClinicPermissionsAction;
