import React, { Component } from 'react';

import { IconMicrophoneEmpty, IconVolumeEmpty } from './icons';

import AudioSettingsHeader from './AudioSettingsHeader';
import MicrophoneEntry from './MicrophoneEntry';
import SpeakerEntry from './SpeakerEntry';
import "./audio-preview.scss"

function createLocalTrack(type, deviceId, timeout, additionalOptions) {
    return (
        window.JitsiMeetJS.createLocalTracks({
            cameraDeviceId: deviceId,
            devices: [ type ],

            // eslint-disable-next-line camelcase
            firefox_fake_device:
                window.config && window.config.firefox_fake_device,
            micDeviceId: deviceId,
            timeout,
            ...additionalOptions
        })
            .then(([ jitsiLocalTrack ]) => jitsiLocalTrack));
}

function createLocalAudioTracks(devices, timeout) {
    return Promise.all(
        devices.map(async ({ deviceId, label }) => {
            let jitsiTrack = null;
            let hasError = false;

            try {
                jitsiTrack = await createLocalTrack('audio', deviceId, timeout);
            } catch (err) {
                hasError = true;
            }

            return {
                deviceId,
                hasError,
                jitsiTrack,
                label
            };
        }));
}

/**
 * Implements a React {@link Component} which displays a list of all
 * the audio input & output devices to choose from.
 *
 * @extends Component
 */
class AudioSettingsContent extends Component {

    /**
     * Initializes a new {@code AudioSettingsContent} instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props) {
        super(props);

        this._onMicrophoneEntryClick = this._onMicrophoneEntryClick.bind(this);
        this._onSpeakerEntryClick = this._onSpeakerEntryClick.bind(this);

        this.state = {
            audioTracks: props.microphoneDevices.map(({ deviceId, label }) => {
                return {
                    deviceId,
                    hasError: false,
                    jitsiTrack: null,
                    label
                };
            })
        };
    }


    /**
     * Click handler for the microphone entries.
     *
     * @param {string} deviceId - The deviceId for the clicked microphone.
     * @returns {void}
     */
    _onMicrophoneEntryClick(deviceId) {
        this.props.setAudioInputDevice(deviceId);
    }


    /**
     * Click handler for the speaker entries.
     *
     * @param {string} deviceId - The deviceId for the clicked speaker.
     * @returns {void}
     */
    _onSpeakerEntryClick(deviceId) {
        this.props.setAudioOutputDevice(deviceId);
    }

    /**
     * Renders a single microphone entry.
     *
     * @param {Object} data - An object with the deviceId, jitsiTrack & label of the microphone.
     * @param {number} index - The index of the element, used for creating a key.
     * @returns {React$Node}
     */
    _renderMicrophoneEntry(data, index) {
        const { deviceId, label, jitsiTrack, hasError } = data;
        const isSelected = deviceId === this.props.currentMicDeviceId;

        return (
            <MicrophoneEntry
                deviceId = { deviceId }
                hasError = { hasError }
                isSelected = { isSelected }
                jitsiTrack = { jitsiTrack }
                key = { `me-${index}` }
                onClick = { this._onMicrophoneEntryClick }>
                {label}
            </MicrophoneEntry>
        );
    }

    /**
     * Renders a single speaker entry.
     *
     * @param {Object} data - An object with the deviceId and label of the speaker.
     * @param {number} index - The index of the element, used for creating a key.
     * @returns {React$Node}
     */
    _renderSpeakerEntry(data, index) {
        const { deviceId, label } = data;
        const key = `se-${index}`;

        return (
            <SpeakerEntry
                deviceId = { deviceId }
                isSelected = { deviceId === this.props.currentOutputDeviceId }
                key = { key }
                onClick = { this._onSpeakerEntryClick }>
                {label}
            </SpeakerEntry>
        );
    }

    /**
     * Creates and updates the audio tracks.
     *
     * @returns {void}
     */
    async _setTracks() {

        this._disposeTracks(this.state.audioTracks);

        const audioTracks = await createLocalAudioTracks(this.props.microphoneDevices, 5000);

        if (this._componentWasUnmounted) {
            this._disposeTracks(audioTracks);
        } else {
            this.setState({
                audioTracks
            });
        }
    }

    /**
     * Disposes the audio tracks.
     *
     * @param {Object} audioTracks - The object holding the audio tracks.
     * @returns {void}
     */
    _disposeTracks(audioTracks) {
        audioTracks.forEach(({ jitsiTrack }) => {
            jitsiTrack && jitsiTrack.dispose();
        });
    }

    /**
     * Implements React's {@link Component#componentDidMount}.
     *
     * @inheritdoc
     */
    componentDidMount() {
        this._setTracks();
    }

    /**
     * Implements React's {@link Component#componentWillUnmount}.
     *
     * @inheritdoc
     */
    componentWillUnmount() {
        this._componentWasUnmounted = true;
        this._disposeTracks(this.state.audioTracks);
    }

    /**
     * Implements React's {@link Component#componentDidUpdate}.
     *
     * @inheritdoc
     */
    componentDidUpdate(prevProps) {
        if (this.props.microphoneDevices !== prevProps.microphoneDevices) {
            this._setTracks();
        }
    }


    /**
     * Implements React's {@link Component#render}.
     *
     * @inheritdoc
     */
    render() {
        const { outputDevices } = this.props;

        return (
            <div>
                <div className = 'audio-preview-content'>
                    <AudioSettingsHeader
                        IconComponent = { IconMicrophoneEmpty }
                        text = { "Microphone Settings" } />
                    {this.state.audioTracks.map((data, i) =>
                        this._renderMicrophoneEntry(data, i),
                    )}
                    { outputDevices.length > 0 && (
                        <>
                            <hr className = 'audio-preview-hr' />
                            <AudioSettingsHeader
                                IconComponent = { IconVolumeEmpty }
                                text = { "Speaker Settings" } />
                        </>
                    )
                    }
                    {outputDevices.map((data, i) =>
                        this._renderSpeakerEntry(data, i),
                    )}
                </div>
            </div>
        );
    }
}

export default AudioSettingsContent;
