import React from "react"
import { Layout, Menu, Button, Avatar, List, Dropdown, Form, Image } from 'antd';
import { UserOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import Divider from '@material-ui/core/Divider';
import GridList from '@material-ui/core/GridList';
import ButtonBase from '@material-ui/core/ButtonBase';
import DatabaseService from "../../../services/database.service"
import ClinicPic from "./building.svg"
import profile from "../../../Profile_Pic.png";

class PraxisProfile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            leistungsspektrum: [],
            oeffnungszeiten: [],
            staff: []
        }

        DatabaseService.clinic.profile
            .read(this.props.clinic)
            .then(response =>
                this.setState({
                    fileList: this.parsePicturesJson(response.data.vetPictures),
                    name: response.data.name,
                    about: response.data.about,
                    leistungsspektrumDesc: response.data.leistungsspektrum,
                    website: response.data.website,
                    zugangsinformationen: response.data.zugangsinformationen,
                    behandlerprofil: response.data.behandlerprofil,
                    leistungsspektrum: this.parseJson(response.data.leistungsspektrumTags),
                    oeffnungszeiten: this.parseJson(response.data.oeffnungszeiten),
                    sprachenString: response.data.sprachen,
                    profilePic: response.data.profilePic,
                    about: response.data.about,
                    leistungsspektrumDesc: response.data.leistungsspektrum,
                    website: response.data.website,
                    zugangsinformationen: response.data.zugangsinformationen,
                    behandlerprofil: response.data.behandlerprofil,
                    sprachenString: response.data.sprachen,
                    addresse: response.data.addresse
                })
            )
            .catch(e => {
                console.log(e);
            });

        DatabaseService.clinic.profile
            .readAdmin(this.props.clinic)
            .then(response =>
                this.setState({ staff: [response.data.admin].concat(response.data.personal) })
            )
            .catch(e => {
                console.log(e);
            });
    }

    parseJson(value) {
        try {
            return JSON.parse(value)
        }
        catch (error) {
            return [];
        }
    }

    parsePicturesJson(value) {
        try {
            const parsedJson = JSON.parse(value);
            return parsedJson.vetPictures;
        }
        catch (error) {
            return [];
        }
    }

    render() {
        return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", width: "100%", backgroundColor: "#fffcf7" }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch", width: "70%", marginRight: 20 }}>

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: 25, margin: 30 }}>
                        <Avatar shape="square" size={150} src={this.state.profilePic ? this.state.profilePic : ClinicPic} />
                        <div style={{ display: "flex", marginLeft: 20, flexDirection: "column" }}>
                            <span style={{ fontWeight: "bold", fontSize: 20 }}>{this.state.name}</span>
                            <span style={{ fontWeight: "bold", marginTop: 7 }}>Facharzt für Innere Medizin</span>
                            <span style={{ color: "grey", marginTop: 7 }}>{this.state.addresse}</span>
                            <span style={{ color: "grey", width: "500px", marginTop: 14 }}>{this.state.about}</span>
                        </div>
                    </div>
                    <Divider />
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 25, margin: 30 }}>
                        <span style={{ fontWeight: "bold", fontSize: 20 }}>Leistungsspektrum</span>
                        <span style={{ color: "grey", marginTop: 7 }}>{this.state.leistungsspektrumDesc}</span>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {this.state.leistungsspektrum && this.state.leistungsspektrum.map(elem => {
                                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", borderRadius: "3px", backgroundColor: "#f4f1ea", padding: 5, margin: 10 }}>
                                    <span style={{ fontSize: 17, margin: 4 }}>{elem}</span>
                                </div>
                            })
                            }
                        </div>
                    </div>
                    <Divider />
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: 25, margin: 30 }}>
                        <span style={{ fontWeight: "bold", fontSize: 20 }}>Addresse und Zugangsinformation</span>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 15 }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                <span style={{ fontWeight: "bold", marginTop: 20 }}>{this.state.name}</span>
                                <span style={{ color: "grey", marginTop: 7 }}>{this.state.addresse}</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginLeft: 70 }}>
                                <span style={{ fontWeight: "bold", marginTop: 20 }}>Zugangsinformationen</span>
                                <span style={{ color: "grey", marginTop: 7 }}>{this.state.zugangsinformationen}</span>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 25, margin: 30 }}>
                        <span style={{ fontWeight: "bold", fontSize: 20 }}>Behandlerprofil</span>
                        <span style={{ width: "500px", marginTop: 7 }}>{this.state.behandlerprofil} </span>
                        <div style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
                            {this.state.fileList.map(image => {
                                return (<Image
                                    style={{ margin: 10 }}
                                    height={100}
                                    src={image.url}
                                />);
                            })}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
                            <div style={{ display: "flex", flexDirection: "column", width: "20vw" }}>
                                <span style={{ fontWeight: "bold", marginTop: 7 }}>Gesprochene Sprachen</span>
                                <span style={{ marginTop: 7 }}>{this.state.sprachenString}</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ fontWeight: "bold", marginTop: 7 }}>Website</span>
                                <span style={{ width: "500px", marginTop: 7, color: "#25D2AA" }}>Website öffnen</span>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 25, margin: 30 }}>
                        <span style={{ fontWeight: "bold", fontSize: 20, marginBottom: 20 }}>Personal</span>
                        <GridList cellHeight="auto" cols={2} spacing={10}>
                            {this.state.staff.map((tile) => (
                                <ButtonBase onClick={() => this.props.selectDoctor(undefined, { key: tile.vetID })}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <Avatar size={64} src={tile.profilePic ? tile.profilePic : profile} />
                                        <div style={{ display: "flex", marginLeft: 20, flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                            <span style={{ fontWeight: "bold", color: "#ff5151" }}>{tile.name}</span>
                                            <span style={{ fontWeight: "bold", marginTop: 7 }}>Facharzt für Innere Medizin</span>
                                        </div>
                                    </div>
                                </ButtonBase>
                            ))}
                        </GridList>
                    </div>
                </div>
            </div>
        );
    }


}

export default PraxisProfile