
class ConferenceAction {
    /**
     * Conference Action Class
     */
    constructor(axios) {
        this.http = axios;
    }

    /**
     * Create one conference
     * @param {Object} data
     * @returns Promise object
     */
    create(data) {
        return this.http.post("/api/conference/create.php", data);
    };
    /**
     * Read all notifications - NOT_IMPLEMENTED!
     * @returns Promise object
     */
    read() {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Read one conference
     * @param {Object} id conference id
     * @returns Promise object
     */
    readOne(id) {
        return this.http.get(`/api/conference/read_by_index.php?id=${id}`);
    };
    /**
     * Update one conference - NOT_IMPLEMENTED!
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Delete one conference - NOT_IMPLEMENTED!
     * @param {Object} id conference id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Check confirmed conference
     * @param {Object} id conference id
     * @returns Promise object
     */
    checkConfirmed(id) {
        return this.http.get(`/api/conference/check_confirmed.php?id=${id}`);
    };
    /**
     * Check new participants
     * @param {Object} id conference id
     * @returns Promise object
     */
    checkNewParticipants(id) {
        return this.http.get(`/api/conference/get_requests.php?id=${id}`);
    }


}

export default ConferenceAction;
