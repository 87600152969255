import React from 'react';
import DatabaseService from "../../services/database.service"
import { Button, Drawer, TimePicker, Form, Input, List, Avatar } from 'antd';
import { CaretUpOutlined } from "@ant-design/icons"
import profile from "../../Profile_Pic.png";


const { RangePicker } = TimePicker;

class KlinikMgmt extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mitglieder: [],
            anfragen: []
        }
        this.updateClinicVets();
    }

    updateClinicVets() {
        DatabaseService.clinic.profile
            .readAccepted(this.props.clinicID)
            .then(response => {
                response.data.forEach((item) => {
                    if (parseInt(item.clinicAccepted, 10)) {
                        this.state.mitglieder.push(item);
                    }
                    else {
                        this.state.anfragen.push(item);
                    }
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    addDoctor = vetId => {
        DatabaseService.vet
            .addToClinic(vetId)
            .then(response => {
                console.log(response.data);
                this.setState({
                    mitglieder: [],
                    anfragen: []
                });
                this.updateClinicVets();
            })
            .catch(e => {
                console.log(e);
            });
    };

    removeDoctor = vetId => {
        DatabaseService.vet
            .removeFromClinic(vetId)
            .then(response => {
                console.log(response.data);
                this.setState({
                    mitglieder: [],
                    anfragen: []
                });
                this.updateClinicVets();
            })
            .catch(e => {
                console.log(e);
            });
    };

    layout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 18 },
    };

    render() {
        return (
            <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', height: "100%" }}>
                <Form {...this.layout} name="nest-messages" style={{ paddingTop: 10 }}>
                    <Form.Item name='telefonnummer' label="Telefonnummer">
                        <Input />
                    </Form.Item>
                    <Form.Item name='notrufnummer' label="Notrufnummer">
                        <Input />
                    </Form.Item>
                    <Form.Item name='email' label="E-Mail">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Öffnungszeiten" name="öffnungszeiten">
                        <RangePicker />
                    </Form.Item>
                    <Form.Item wrapperCol={{ ...this.layout.wrapperCol, offset: 8 }}>
                        <Button type="primary" htmlType="submit" block>
                            Update
                        </Button>
                    </Form.Item>
                </Form>

                <List
                    itemLayout="horizontal"
                    style={{ width: "400px", margin: 80 }}
                    dataSource={this.state.mitglieder}
                    header={<span style={{ fontSize: 20, color: "#1e90ff", textAlign: "center" }}>Mitglieder</span>}
                    renderItem={item => (
                        <List.Item extra={<Button onClick={() => this.removeDoctor(item.id)} type="primary">Entfernen</Button>}>
                            <List.Item.Meta
                                avatar={<Avatar src={item.profilePic ? item.profilePic : profile} />}
                                title={<span>{item.titel + " " + item.vorname + " " + item.nachname}</span>}
                                description={"Fachrichtung:" + item.fachrichtung + "  " + "Standort:München"}
                            />
                        </List.Item>
                    )}
                />

                <List
                    itemLayout="horizontal"
                    style={{ width: "400px" }}
                    dataSource={this.state.anfragen}
                    header={<span style={{ fontSize: 20, color: "#1e90ff", textAlign: "center" }}>Anfragen</span>}
                    renderItem={item => (
                        <List.Item extra={<Button onClick={() => this.addDoctor(item.id)} type="primary">Hinzufügen</Button>}>
                            <List.Item.Meta
                                avatar={<Avatar src={item.profilePic ? item.profilePic : profile} />}
                                title={<span>{item.titel + " " + item.vorname + " " + item.nachname}</span>}
                                description={"Fachrichtung:" + item.fachrichtung + "  " + "Standort:München"}
                            />
                        </List.Item>
                    )}
                />
            </div>
        );
    }

}

export default KlinikMgmt
