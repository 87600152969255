import React from 'react';

import { IconMeter } from './icons';

import "./meter.scss"

/**
 * React {@code Component} representing an audio level meter.
 *
 * @returns { ReactElement}
 */
export default function Meter({ className, isDisabled, level }) {
    let ownClassName;

    if (level > -1) {
        ownClassName = `metr metr-l-${level}`;
    } else {
        ownClassName = `metr ${isDisabled ? 'metr--disabled' : ''}`;
    }

    return (
        <IconMeter className = { `${ownClassName} ${className}` } />
    );
}
