import React, { Component } from "react"
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Divider, List, Button, Input } from 'antd';
import { ButtonBase } from '@material-ui/core';
import {
    UserOutlined
} from '@ant-design/icons';
import Paper from '@material-ui/core/Paper';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DatabaseService from "../../services/database.service"
import { Upload, message } from 'antd';
import profile from "../../Profile_Pic.png"
import { isMobile } from 'react-device-detect';
import store from 'store';
import "./PopUp.css";
// https://github.com/prettymuchbryce/http-status-codes
import { StatusCodes as HttpStatus } from 'http-status-codes';
import http from "../../http-node-service"
import { InboxOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';


const { Dragger } = Upload;
const properties = {
  name: 'file',
  multiple: true,
  //action: `http://localhost:8080/uploadFile/${store.get('usrId')}`,
  listType: "picture",
};

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <span style={{ fontSize: 20, fontWeight: "bold" }}>{children}</span>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


class UserProfileModal extends Component {

    constructor(props) {
        super(props);
        console.log("UserProfileModal.js: this.props ", this.props);
        this.state = {
            animals: [],
            events: [],
            selectedEvent: { eventID: -1 },
            fileList: [],
            uuid: {},
            filekeys: [],
            vetconfirm: false
        }
    }

    parseJson(jsonString) {
        try {
            return JSON.parse(jsonString);
        }
        catch (e) {
            console.log(e);
        }
    }

    async loadData() {
        if (this.props.selectedEvent) {
            this.setState({ selectedEvent: { ...this.props.selectedEvent, fields: this.parseJson(this.props.selectedEvent.form) } });

            await DatabaseService.AnimalOwner
                .readOne(this.props.selectedEvent.userID)
                .then(response => {
                    this.setState( { pic: response.data.profilePic });
                })
                .catch(e =>
                    console.log(e)
                );
        }
        await DatabaseService.animal
            .readOwnerAnimals(this.props.user.userID)
            .then(response => {
                console.log("UserProfileModal.js: animal.readOwnerAnimals() response ", response);
                if(response.status === HttpStatus.OK) {
                    const animals = JSON.parse(response.data);
                    this.setState({ animals: animals });
                }
            })
            .catch(e =>
                console.log(e)
            );
        await DatabaseService.AnimalOwner.event
            .read(store.get('usrId'), this.props.user.userID)
            .then(response => {
                console.log("UserProfileModal.js: AnimalOwner.event.read() response ", response);
                if(response.status === HttpStatus.OK) {
                    const events = response.data.records;
                    const mappedData = events.map(singleEvent => {
                        console.log("UserProfileModal.js: AnimalOwner.event.read() singleEvent ", singleEvent);
                        return {
                            ...singleEvent,
                            fields: this.parseJson(singleEvent.form),
                            title: Number(singleEvent.confirmed) ? "Bestätigt" : "Terminanfrage",
                            start: new Date(singleEvent.time),
                            end: new Date(new Date(singleEvent.time).getTime() + singleEvent.slotlength * 60000)
                        };
                    })
                    this.setState({ events: mappedData });

                    //vom Dashboard übergebenes Event aktualisieren
                    for (var i=0;i<this.state.events.length;i++) {
                        if (this.state.selectedEvent.eventID == this.state.events[i].eventID) {
                            this.setState({ selectedEvent: this.state.events[i] });
                        }
                    }
                }
            })
            .catch(e =>
                console.log(e)
            );

        await DatabaseService.documents
            .read(store.get('usrId'))
            .then(response => {
                if(response.status === HttpStatus.OK) {
                    this.setState({ filekeys: response.data.records });
                }
            })
            .catch(e =>
                console.log(e)
            );

        await http.get("/getFileList/"+ this.props.user.userID)
            .then(response => {

            var list = [];

            for (var i=0;i<response.data.Contents.length;i++) {
                var parts=response.data.Contents[i].Key.split("!");

                var fileToAdd = {
                    uid: -1,
                    name: parts.pop(),
                    url: process.env.REACT_APP_HTTP_S3_USER_FILES + "/" + response.data.Contents[i].Key,
                    //url: "s3-de-central.profitbricks.com/cav-user-files/pet-files/" + response.data.Contents[i].Key,
                    linkProps: '{"download": "name", "target": "_blank"}'
                };

                for (var j=0;j<this.state.filekeys.length;j++) {
                    if (this.state.filekeys[j].filekey == parts[1]) {
                        list.push(fileToAdd);
                    }
                }
            }

            this.setState({fileList:list})
            this.forceUpdate();

            console.log("UserProfileModal.js: FileList ", list);
            })
            .catch( e => console.log(e));
    }

    handleAccept = () => {
        DatabaseService.vet.patient
            .create({
                vetID: this.state.selectedEvent.vetID,
                userID: this.state.selectedEvent.userID
            })
            .then(response => {
                console.log("UserProfileModal.js: vet.patient.create() response ", response);
                if(response.status === HttpStatus.CREATED) {
                    DatabaseService.vet.event
                        .update({
                            eventID: this.state.selectedEvent.eventID,
                            confirmed: true
                        })
                        .then(response => {
                            console.log("UserProfileModal.js: vet.event.update() response ", response);
                            if(response.status === HttpStatus.OK) {
                                const notifData = {
                                    besuchsgrund: this.state.selectedEvent.besuchsgrund,
                                    vetName: store.get('name'),
                                    name: this.state.selectedEvent.name,
                                    confirmed: true,
                                    event: this.state.selectedEvent
                                };
                                DatabaseService.notification
                                    .create({
                                        id: this.state.selectedEvent.userID,
                                        message: "test",
                                        type: "notification",
                                        data: JSON.stringify(notifData)
                                    })
                                    .then(response => {
                                        console.log("UserProfileModal.js: notification.create() response ", response);
                                        if(response.status === HttpStatus.CREATED) {
                                            this.showpopup("myPopupconfirm");

                                            document.getElementById("buttonaccept").style.display = "none";
                                        }
                                    })
                                    .catch(e => {
                                        console.log("UserProfileModal.js: notification.create() catch ", e);
                                    });
                            }
                        })
                        .catch(e => {
                            console.log("UserProfileModal.js: vet.event.update() catch ", e);
                        });
                }
            })
            .catch(e => {
                console.log("UserProfileModal.js: vet.patient.create() catch ", e);
            });
    };

    handleDeny = () => {
        DatabaseService.vet.event
            .update({
                eventID: this.state.selectedEvent.eventID,
                confirmed: 0
            })
            .then(response => {
                console.log("UserProfileModal.js: vet.event.update() response ", response);
                if(response.status === HttpStatus.OK) {
                    const notifData = {
                        besuchsgrund: this.state.selectedEvent.besuchsgrund,
                        vetName: store.get('name'),
                        name: this.state.selectedEvent.name,
                        confirmed: false,
                        event: this.state.selectedEvent
                    };
                    DatabaseService.notification
                        .create({
                            id: this.state.selectedEvent.userID,
                            message: "test",
                            type: "notification",
                            data: JSON.stringify(notifData)
                        })
                        .then(response => {
                            console.log("UserProfileModal.js: notification.create() response ", response);
                            if(response.status === HttpStatus.CREATED) {
                                this.showpopup("myPopupdeny");

                                document.getElementById("buttondeny").style.display = "none";
                            }
                        })
                        .catch(e => {
                            console.log(e);
                        });
                }
            })
            .catch(e => console.log(e));
    }

    async showpopup(id) {
        var popup = document.getElementById(id);

        if (!popup.classList.contains("show")) {
            popup.classList.add("show");

            let promise = new Promise((resolve, reject) => {
                setTimeout(() => resolve("done!"), 5000)
              });

            let result = await promise;

            popup.classList.remove("show");
        }
    }

    uuidv4() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    SaveDocsOnChange() {
        if (this.state.vetconfirm == false) {
            this.setState({vetconfirm: true});
        }else {
            this.setState({vetconfirm: false});
        }

        console.log("Confirm: " + this.state.vetconfirm);
    }

    async loadDocs() {
        await DatabaseService.documents
            .read(store.get('usrId'))
            .then(response => {
                if(response.status === HttpStatus.OK) {
                    this.setState({ filekeys: response.data.records });
                }
            })
            .catch(e =>
                console.log(e)
            );

        await http.get("/getFileList/"+ this.props.user.userID)
            .then(response => {

            console.log(response.data.Contents);

            var list = [];

            for (var i=0;i<response.data.Contents.length;i++) {
                var parts=response.data.Contents[i].Key.split("!");

                var fileToAdd = {
                    uid: -1,
                    name: parts.pop(),
                    url: process.env.REACT_APP_HTTP_S3_USER_FILES + "/" + response.data.Contents[i].Key,
                    //url: "s3-de-central.profitbricks.com/cav-user-files/pet-files/" + response.data.Contents[i].Key,
                    linkProps: '{"download": "name", "target": "_blank"}'
                };

                for (var j=0;j<this.state.filekeys.length;j++) {
                    if (this.state.filekeys[j].filekey == parts[1]) {
                        list.push(fileToAdd);
                    }
                }
            }

            this.setState({fileList:list})
            this.forceUpdate();

            console.log("UserProfileModal.js: FileList ", list);
        })
        .catch( e => console.log(e));
    }

    close() {
        this.setState({ fileList: [] });
    }

    render() {
        if (!this.props.user) {
            return (
                <Dialog onEntered={() => this.loadData()} onExit={() => this.close()} onClose={this.props.onClose} paper={{ backgroundColor: "#fffcf7" }} aria-labelledby="customized-dialog-title" open={this.props.open} maxWidth={"lg"}>
                    <DialogTitle id="customized-dialog-title" onClose={this.props.onClose}>
                        Benutzerprofil
                    </DialogTitle>
                </Dialog>
            )
        }
        return (
            <Dialog onEntered={() => this.loadData()} onExit={() => this.close()} onClose={this.props.onClose} paper={{ backgroundColor: "#fffcf7" }} aria-labelledby="customized-dialog-title" open={this.props.open} maxWidth={"lg"}>
                <DialogTitle id="customized-dialog-title" onClose={this.props.onClose}>
                    Benutzerprofil
                </DialogTitle>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", width: 1200 }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", width: "100%", padding: 40 }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%" }}>
                            {this.props.selectedEvent ?
                                <Avatar size={50} src={this.state.pic} /> :
                                <Avatar size={50} src={this.props.user.profilePic} />
                            }
                            <div style={{ display: "flex", marginLeft: 20, flexDirection: "column" }}>
                                <span style={{ fontWeight: "bold", fontSize: 20 }}>{this.props.user.vorname + " " + this.props.user.nachname}</span>
                                <span style={{ fontWeight: "bold", marginTop: 7 }}>E-Mail: {this.props.user.email}</span>
                                <span style={{ fontWeight: "bold", marginTop: 7 }}>Benutzername: {this.props.user.username}</span>
                                {//<span style={{color:"grey", marginTop:7}}>Herrmann-Hesse-Straße 4, 10437 Berlin</span>
                                }
                            </div>
                            {this.state.selectedEvent.eventID !== -1 && this.state.selectedEvent.confirmed == 1 &&
                                <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "flex-end" }}>
                                    <Button size="large" style={{ backgroundColor: "#25D2AA", color: "white", height: "50px" }} shape="round" onClick={() => { this.props.startConf(this.state.selectedEvent.userID, this.state.selectedEvent); this.props.onClose() }}>Videoanruf starten</Button>
                                </div>
                            }
                        </div>
                        <Divider />
                        <span style={{ fontSize: 20, fontWeight: "bold", marginBottom: 30 }}>Termine</span>
                        <List
                            itemLayout="vertical"
                            style={{ marginTop: 10, width: "100%" }}
                            dataSource={this.state.events}
                            grid={
                                isMobile ?
                                    {
                                        gutter: 32,
                                        column: 1
                                    } :
                                    this.state.selectedEvent.eventID == -1 ?
                                        {
                                            gutter: 32,
                                            column: 3
                                        } :
                                        {
                                            gutter: 32,
                                            column: 2
                                        }
                            }
                            renderItem={item => (
                                <List.Item style={{ width: "100%" }} >
                                    <ButtonBase style={{ width: "100%", height: "100%" }} onClick={() => this.setState({ selectedEvent: item })}>
                                        <div style={{ backgroundColor: "white", boxShadow: item.eventID == this.state.selectedEvent.eventID ? "rgba(37, 210, 170, 0.6) 0px 2px 6px" : "rgba(0, 0, 0, 0.16) 0px 2px 6px", display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", padding: 10 }}>
                                            <div className="verticalLine" style={{ borderColor: "#25D2AA" }} />
                                            <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                                <span style={{ fontWeight: "bold" }}>{item.besuchsgrund}</span>
                                                <span >{item.name}</span>
                                                <span style={{ color: "grey" }}>{item.tierart + ", " + item.tierAlter + " Jahre"}</span>
                                            </div>
                                            <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                                <span style={{ fontWeight: "bold" }}>{item.start.toLocaleString('de-DE', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}</span>
                                                <span>{item.start.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })}</span>
                                                <span style={{ color: "grey" }}>{item.end.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })}</span>
                                            </div>
                                        </div>
                                    </ButtonBase>
                                </List.Item>
                            )}
                        />
                        <Divider />
                        <span style={{ fontWeight: "bold", fontSize: 20, marginBottom: 20 }}>Tiere</span>
                        <List
                            style={{ marginTop: 10, width: "80%" }}

                            grid={
                                isMobile ?
                                    this.state.selectedEvent.eventID == -1 ?
                                        {
                                            gutter: 16,
                                            column: 2
                                        } :
                                        {
                                            gutter: 16,
                                            column: 1
                                        } :
                                    this.state.selectedEvent.eventID == -1 ?
                                        {
                                            gutter: 16,
                                            column: 4
                                        } :
                                        {
                                            gutter: 16,
                                            column: 3
                                        }}
                            dataSource={this.state.animals}
                            renderItem={item => (
                                <List.Item>
                                    <Paper elevation={3} style={{ backgroundColor: "white", borderWidth: "2px", borderColor: "black", height: "170px", width: "200px", display: "flex", flexDirection: 'column', justifyContent: 'space-around', alignItems: "stretch", padding: 10 }}>
                                        <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: "center", margin: 8, width: "100%", height: "100%" }}>
                                            <Avatar
                                                size={40}
                                                src={item.profilePic ? item.profilePic : profile}
                                            />
                                            <span style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>{item.name}</span>
                                            <div style={{ width: "40px" }} />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start", margin: 7, height: "100%" }}>
                                            <span style={{ fontSize: 15, fontWeight: "bold", marginTop: 4 }}>{item.tierrasse}</span>
                                            <span style={{ fontSize: 15, color: "grey", marginTop: 4 }}>{item.tierAlter} Jahre</span>
                                        </div>


                                    </Paper>
                                </List.Item>
                            )}
                        />
                        <Divider />
                        <span style={{ fontWeight: "bold", fontSize: 20, marginBottom: 20 }}>Dokumente</span>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", width:"100%"}}>
                        <h1>Unterlagen hinzufügen</h1><div style={{ display: "flex", justifyContent: "flex-end"}}><Checkbox onChange={() => this.SaveDocsOnChange()}>Für alle anderen Tierärzte freigeben</Checkbox></div>
                        <Dragger {...properties} onChange={async (info) => {const { status } = info.file;
                                                                  if (status !== 'uploading') {

                                                                    console.log(info.file, info.fileList);
                                                                  }
                                                                  if (status === 'done') {
                                                                    message.success(`${info.file.name} file uploaded successfully.`);

                                                                    if (this.state.vetconfirm) {
                                                                        await DatabaseService.vet.patient
                                                                            .readByPatient(this.props.user.userID)
                                                                            .then(response => {
                                                                                if(response.status === HttpStatus.OK) {
                                                                                    console.log("DATA: " + JSON.stringify(response));

                                                                                    response.data.records.forEach(element => {
                                                                                        DatabaseService.documents
                                                                                        .create({
                                                                                            filekey: this.state.uuid,
                                                                                            vetID: element.vetID
                                                                                        })
                                                                                        .then(response => {
                                                                                            console.log("UserProfileModal.js: documents.create() response ", response);
                                                                                            if(response.status === HttpStatus.CREATED) {
                                                                                                console.log("UserProfileModal.js: documents.create(): Document created.");
                                                                                            }
                                                                                        })
                                                                                        .catch(e =>
                                                                                            console.log(e)
                                                                                        );
                                                                                    });
                                                                                }
                                                                            })
                                                                            .catch(e =>
                                                                                console.log(e)
                                                                            );
                                                                    }else {
                                                                        await DatabaseService.documents
                                                                            .create({
                                                                                filekey: this.state.uuid,
                                                                                vetID: store.get('usrId')
                                                                            })
                                                                            .then(response => {
                                                                                console.log("UserProfileModal.js: documents.create() response ", response);
                                                                                if(response.status === HttpStatus.CREATED) {
                                                                                    console.log("UserProfileModal.js: documents.create(): Document created.");
                                                                                }
                                                                            })
                                                                            .catch(e =>
                                                                                console.log(e)
                                                                            );
                                                                    }

                                                                    this.loadDocs();
                                                                  } else if (status === 'error') {
                                                                    message.error(`${info.file.name} file upload failed.`);
                                                                  }}}
                                                 beforeUpload={(file) => {
                                                        this.setState({ uuid: this.uuidv4() });
                                                    }
                                                 }
                                                 action={process.env.REACT_APP_HTTP_S3_BASE_URL + `/uploadFile/` + this.props.user.userID  + "!" + this.state.uuid}
                                                 fileList={this.state.fileList} style={{ marginTop: 30 }}>
                            <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Zum Hochladen, diesen Bereich Anklicken oder Dateien hierher ziehen</p>
                            <p className="ant-upload-hint">
                            Sie können ein oder mehrere Dateien hochladen
                        </p>
                        </Dragger>
                        </div>

                    </div>
                    {this.state.selectedEvent.eventID !== -1 &&
                        <Paper elevation={3} style={{ margin: 20 }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: 30 }}>
                                <span style={{ fontWeight: "bold", fontSize: 20 }}>{this.state.selectedEvent.start.toLocaleString('de-DE', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })} <br></br> {this.state.selectedEvent.start.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })} - {this.state.selectedEvent.end.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })}</span>
                                <span style={{ fontWeight: "bold", fontSize: 20, textAlign: "center", marginTop: 10 }}>{this.state.selectedEvent.besuchsgrund}</span>
                                <span style={{ fontSize: 20, textAlign: "center", marginTop: 10 }}>{this.state.selectedEvent.name + ", " + this.state.selectedEvent.tierAlter + " Jahre"}</span>
                                <span style={{ textAlign: "center", fontSize: 20 }}>{this.state.selectedEvent.tierrasse + ", " + this.state.selectedEvent.tierart}</span>
                                <div style={{ height: "6px", backgroundColor: "orange", width: "200px", margin: 15 }} />
                                {this.state.selectedEvent.fields && this.state.selectedEvent.fields.form.map(elem =>
                                    <>
                                        <span style={{ fontSize: 20 }}>{elem.feldName}</span>
                                        <Input value={elem.feldData} />
                                        <Divider />
                                    </>
                                )}
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                    <Button id="buttondeny" size="large" style={{ borderColor: "black", color: "black" }} ghost shape="round" onClick={() => this.handleDeny()}>Ablehnen</Button>
                                    {this.state.selectedEvent.confirmed == 0 &&
                                        <>
                                            <div style={{ width: "10px" }} />
                                            <Button id="buttonaccept" size="large" style={{ backgroundColor: "#25D2AA", color: "white" }} shape="round" onClick={() => this.handleAccept()}>Annehmen</Button>
                                        </>
                                    }
                                    <div className="popup">
                                        <span className="popuptext" id="myPopupconfirm">Termin bestätigt!</span>
                                    </div>
                                    <div className="popup">
                                        <span className="popuptext" id="myPopupdeny">Termin abgelehnt!</span>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    }
                </div>
            </Dialog>

        )
    }
}

export default UserProfileModal
