import React from 'react';
import Logo from "./assets/HelloVetPetLogo.png"
import { Layout, Row, Col } from 'antd';
import LineFooter from "./assets/LineFooter.png"
import Dot1 from "./assets/Dot1Impressum.png"
import Dot2 from "./assets/Dot2Impressum.png"
import Line from "./assets/LineImpressum.png"
import Background from "./assets/ImpressumBackg.png"
import PartnerBackground from "./assets/koop_2x.png"
import Arndt from "./assets/Arndt.jpg"
import Daniel from "./assets/Daniel.jpg"
import Dots from "./assets/DotsImpressum.png"
import RosengartenLogo from "./assets/rosengarten.png"
import TierheimheldenLogo from "./assets/tierheimhelden.svg"
import LogoLMU from "./assets/LogoLMU.jpg"
import LogoEXIST from "./assets/Logo-EXIST.png"
import {
  LinkedinFilled
} from '@ant-design/icons';
import {
  Link
} from "react-router-dom";
import "./Impressum.css"

const { Header, Content, Footer } = Layout;


function Partner(){
  return (
  <Layout className="layout" style={{ backgroundColor:"white"}}>
    <Header style={{ backgroundColor: 'transparent' }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
          <Link to="/">
             <img src={Logo} style={{ width: "260px" }} />
          </Link>
        </div>
    </Header>
    <Content >
    <img src={Dots} style={{position:"absolute", top:"90%", left:"0%", height:"20vh", zIndex:5}}/>
        <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", width:"100%", backgroundImage: `url(${PartnerBackground})`, backgroundRepeat:"no-repeat", backgroundSize:"contain", paddingTop: 120}}>
        <span className="heading">Partner</span>
        <div style={{display:"flex", flexDirection:"column", maxWidth:"1100px"}}>
          <div style={{height: "100px"}}/>
          <Row gutter={[48, 48]}>
              <Col span={12}>
                <img src={RosengartenLogo} style={{borderRadius:"20px", width:"100%", height:"auto"}}/>
              </Col>
              <Col span={12}>
                <div style={{ height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                 <span className="gründer-desc">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</span>
                </div>
              </Col>
          </Row>
          <div style={{height: "100px"}}/>
          <Row gutter={[48, 48]}>
             <Col span={12}>
                <div style={{height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                 <span className="gründer-desc">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</span>
                </div>
              </Col>
              <Col span={12}>
                <img src={TierheimheldenLogo} style={{borderRadius:"20px", width:"100%", height:"auto"}}/>
              </Col>
          </Row>
          <div style={{height: "100px"}}/>
          <Row gutter={[48, 48]}>
              <Col span={12}>
                <img src={LogoLMU} style={{borderRadius:"20px", width:"100%", height:"auto"}}/>
              </Col>
              <Col span={12}>
                <div style={{ height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                 <span className="gründer-desc">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</span>
                </div>
              </Col>
          </Row>
          <div style={{height: "100px"}}/>
          <Row gutter={[48, 48]}>
             <Col span={12}>
                <div style={{height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                 <span className="gründer-desc">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</span>
                </div>
              </Col>
              <Col span={12}>
                <img src={LogoEXIST} style={{borderRadius:"20px", width:"100%", height:"auto"}}/>
              </Col>
          </Row>
        </div>
        </div>


        <img src={LineFooter} style={{marginTop:50, width:"100%", zIndex:0}}/>

        <div style={{  display: "flex", flexDirection: "row" }}>
          <div style={{paddingRight:30}}>
          <img src={Logo} className="logo" />
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
            <span className="footer-headings" >Rechtliches</span>
            <Link className="footer-elements" to="/datenschutz" >
            <span  >Datenschutz</span>
            </Link>
            <Link className="footer-elements" to="/impressum" >
            <span  >Impressum</span>
            </Link>
          </div>
        </div>
        <span style={{ marginTop: 90, marginBottom: 50, fontSize: 16, fontFamily: "WorkSans" }} >Veticine Ventures GmbH, Alle Rechte vorbehalten.</span>
    </Content>
    </Layout>
  );

}

export default Partner
