import Mail from './registrationMail';

class RegistrationAction {
    /**
     * Registration Action Class
     */
    constructor(axios) {
        this.http = axios;
        this._mail = new Mail(axios);
    }


    /**
     * Registration Mail Service
     */
    get mail() {
        return this._mail;
    }
}

export default RegistrationAction;