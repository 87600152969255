import React from 'react';
import { List, Avatar, Collapse, Card, Skeleton, Descriptions, Typography, Row, Col, Select } from 'antd';
import {
    CloseOutlined,
    CheckOutlined,
} from '@ant-design/icons';
import DatabaseService from "../../../services/database.service";
import Paper from '@material-ui/core/Paper';
import store from 'store';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { ButtonBase } from '@material-ui/core';
import UserProfileModal from "../UserProfileModal";
import { connect } from 'react-redux'

const { Panel } = Collapse;
const { Title } = Typography;
const { Text } = Typography;
const { Meta } = Card;
const { Option } = Select;

function mapStateToProps(state) {
    return { events: state.events }
}

class CalendarHistory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            usersLoaded: false,
            petsLoaded: false,
            requests: [],
            users: new Map(),
            pets: new Map(),
            currentSelectedDay: "allgemein",
            dialogOpen: false,
            nextEvent: {},
            data: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
            ]
        }
    }

    isToday = (date) => {
        const today = new Date()
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    getRequests() {
        const mappedEvents = this.props.events.filter((singleEvent, index) => {
            if (((this.state.currentSelectedDay === "heute" && this.isToday(singleEvent.start)) || (this.state.currentSelectedDay === "allgemein")) && Number(singleEvent.confirmed)) {
                return true;
            }
            else {
                return false;
            }
        });

        return mappedEvents;
    }


    handleAccept(item) {
        DatabaseService.vet.event.update(item.id, { confirmed: true });
        DatabaseService.AnimalOwner
            .readOne(item.userID)
            .then(response => {
                //TODO: Test if user already exists
                DatabaseService.vet.patient
                    .create({
                        vetID: item.vetID,
                        userID: item.userID,
                        nachname: response.data.nachname,
                        vorname: response.data.vorname
                    })
                    .then(response => {
                        const filteredEvents = this.state.requests.filter((singleEvent, index) => {
                            if (Object.is(singleEvent, item)) {
                                return false;
                            }
                            return true;
                        });
                        this.setState({ requests: filteredEvents });
                    })
                    .catch(e => {
                        console.log(e);
                    });
            })
            .catch(e => {
                console.log(e);
            });

    };

    handleDecline(item) {
        DatabaseService.vet.event
            .update(item.id, { confirmed: false })
            .then(response => {
                const filteredEvents = this.state.requests.filter((singleEvent, index) => {
                    if (Object.is(singleEvent, item)) {
                        return false;
                    }
                    return true;
                });
                this.setState({ requests: filteredEvents });
            })
            .catch(e => {
                console.log(e);
            });
    }

    handleDayChange(value) {
        this.setState({ currentSelectedDay: value });
    }

    render() {

        if (this.props.events.length === 0)
            return (
                <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", padding: 25 }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 30 }}>
                        <span style={{ fontSize: 20, fontWeight: "bold" }}>Kalenderübersicht [CalendarHistory.js]</span>
                        <Select defaultValue="allgemein" style={{ width: 120 }} onChange={value => this.handleDayChange(value)}>
                            <Option value="allgemein">Allgemein</Option>
                            <Option value="heute">Heute</Option>

                        </Select>
                    </div>
                    <span style={{ fontWeight: "bold" }}>Keine Termine</span>
                </Paper>
            );

        return (
            <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: 25, overflow: "auto" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 30 }}>
                    <span style={{ fontSize: 20, fontWeight: "bold" }}>Kalenderübersicht</span>
                    <Select defaultValue="heute" style={{ width: 120 }} onChange={value => this.handleDayChange(value)}>
                        <Option value="heute">Heute</Option>
                        <Option value="allgemein">Allgemein</Option>
                    </Select>
                </div>
                <List
                    itemLayout="horizontal"
                    dataSource={this.getRequests()}
                    renderItem={item => (
                        <List.Item style={{ width: "100%" }} >
                            <ButtonBase style={{ width: "100%" }} onClick={() => this.setState({ dialogOpen: true, nextEvent: item })}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%" }}>
                                    <div style={{ borderColor: "red" }} className="verticalLineLowProfile" />
                                    <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                        {/*<span>{this.state.pet.name + ", " + this.state.user.vorname+ " " + this.state.user.nachname}</span>
    <span>{this.state.pet.tierart + ", " + this.state.pet.tierAlter+ " Jahre"}</span>*/}
                                        <span style={{ fontWeight: "bold" }}>{item.besuchsgrund}</span>
                                        <span>{item.name + ", " + item.vorname + " " + item.nachname}</span>
                                    </div>
                                    <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                        <span>{item.start.toLocaleString('de-DE', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}</span>
                                        <span>{item.start.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })} - {item.end.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })}</span>
                                    </div>
                                </div>
                            </ButtonBase>
                        </List.Item>
                    )}
                />
                <UserProfileModal startConf={this.props.startConf} open={this.state.dialogOpen} onClose={() => this.setState({ dialogOpen: false })} selectedEvent={this.state.nextEvent} user={this.state.nextEvent} />
            </Paper>
        );
    }

}

export default connect(mapStateToProps)(CalendarHistory)
