import React from 'react';
import {
    Layout,
    Avatar,
    Button,
    Input,
    Space,
    Spin,
    AutoComplete
} from 'antd';
import {
    SearchOutlined
} from '@ant-design/icons';
import {
    Redirect,
    Link
} from "react-router-dom";
import Pfote from "./Pfote.png";
import Calendar from "./Calendar"
import store from 'store'
import DatabaseService from "../../services/database.service"
import {
    v4 as uuidv4
} from 'uuid';
import ProfilePage from "./profile/ProfilePage"
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Conferencing from "./Conferencing"
import KlinikMgmt from "./KlinikMgmt"
import Settings from "./settings/Settings"
import {
    motion,
    AnimateSharedLayout
} from "framer-motion";
import DashboardMainComponent from "./cards-redesign/DashboardMainComponent"
import CallComp from "./novics-jitsi-integration/CallComp"
import Portal from "./novics-jitsi-integration/Portal"
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import profile from "../../Profile_Pic.png";
import isLoggedIn from "../../is_logged_in"
import VetProfile from "./colleagues-profiles/ProfilePage"
import PraxisProfile from "./colleagues-profiles/PraxisProfile"
// https://github.com/RocketChat/Rocket.Chat.js.SDK#readme
import RocketChatAPI from "@rocket.chat/sdk/lib/api/RocketChat"
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PetsIcon from '@material-ui/icons/Pets';
import TodayIcon from '@material-ui/icons/Today';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SettingsIcon from '@material-ui/icons/Settings';
import {
    isMobile
} from 'react-device-detect';
import {
    connect
} from 'react-redux'
import "./Dashboard.css"
import {
    ButtonBase
} from '@material-ui/core';
import {
    initialize,
    addEvent
} from "../../redux/events/eventsSlice";
// https://github.com/prettymuchbryce/http-status-codes
import { StatusCodes as HttpStatus } from 'http-status-codes';
import 'dotenv/config';
import { cWebsocketService as WebsocketService } from "../../wss-socket";
import { sha256 } from 'js-sha256';


const {
    Header,
    Content,
    Sider
} = Layout;

const {
    Option
} = AutoComplete;

const {
    TextArea
} = Input;

const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateEvents: (event) => dispatch(addEvent(event)),
        saveFetched: (events) => dispatch(initialize(events)),
    }
}

let wait = (ms) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    })
}




class UImgmtComp extends React.Component {

    constructor(props) {
        super(props);
        console.log("UImgmtComp.js: this.props ", this.props);
        // console.log("UImgmtComp.js: ENV ", process.env);
        store.set('isDevelopment', process.env.NODE_ENV === 'development');
        // store.each((k,v) => {
        //     console.log("UImgmtComp.js: store ", k,v);
        // })

        this.state = {
            events: [],
            currentHeaderKey: 'uebersicht',
            settingsDialog: false,
            nextEvent: {},
            updateUserEvents: false,
            vorname: "",
            nachname: "",
            open: false,
            slotLength: 0,
            eventCurrentlyActive: false,
            sessionActive: false,
            klinikSettings: false,
            klinikAdmin: false,
            clinicID: 1,
            clinic: {},
            clinicProfile: {},
            usrID: -1,
            selectedEvent: {
                eventID: -1
            },
            selectedUser: -1,
            selectedDoctor: {},
            selectedClinic: {},
            dataLoaded: false,
            vetsLoaded: false,
            eventsLoaded: false,
            doctor: [],
            timerTime: 0,
            isRecording: false,
            popoverVisible: false,
            externalWindow: false,
            demoCallActive: false,
            createNewButtonPressed: false,
            vetProfile: {},
            userProfileOpen: false,
            stopSession: false,
            launchWindow: false,
            volltextsucheResults: ["test1234", "test4568", "test890678", "test789456", "test456123"],
            RocketRestAPI: new RocketChatAPI({
                host: process.env.REACT_APP_HTTP_CHAT_BASE_URL
            }),
            connected: false,
            chatSelected: true,
            selectedChat: "",
            ws: null,
            sessionName: ""
        };

        // this.getVet()
        //     .then(async success => {
        //         console.log("UImgmtComp.js: getVet() success ", success);
        //         this.getVetProfile();
        //         this.updateEvents();
        //         this.getClinic()
        //         this.getClinicProfile()
        //         this.getAllVets();
        //         this.connectChat();
        //     })
        //     .then(() => {
        //         // this.connect();
        //     })
        //     .catch(e => {
        //         // Logout user and reload page
        //         console.log("UImgmtComp.js: getVet() error ", e);
        //         store.remove('loggedIn');
        //         window.location.reload();
        //     })
    }


    async componentDidMount() {
        // await wait(5000);
        this.getVet()
            .then(async success => {
                console.log("UImgmtComp.js: getVet() success ", success);
                await this.getVetProfile();
                await this.updateEvents();
                await this.getClinic()
                await this.getClinicProfile()
                await this.getAllVets();
                await this.connectChat();
            })
            .then(() => {
                this.connect();
            })
            .catch(e => {
                // Logout user and reload page
                console.log("UImgmtComp.js: getVet() error ", e);
                store.remove('loggedIn');
                window.location.reload();
            })
    }

    getVet() {
        return new Promise((resolve, reject) => {
            console.log("UImgmtComp.js: getVets(%s)", store.get('usrId'));
            DatabaseService.vet
                .readOne(store.get('usrId'))
                .then(async response => {
                    console.log("UImgmtComp.js: vet.readOne() response ", response);
                    if(response.status === HttpStatus.OK) {
                        this.setState({
                            vetsLoaded: true,
                            doctor: response.data,
                            vorname: response.data.vorname,
                            nachname: response.data.nachname,
                            klinikAdmin: parseInt(response.data.clinicAdmin, 10),
                            clinicID: response.data.clinicID,
                            dataLoaded: this.state.eventsLoaded,
                        });
                        // this.setState({dataLoaded: false});//this.state.eventsLoaded});
                        // slotLength: response.data.slotLength, // FIXME: Ist das Kunst oder kann das weg? slotLength kommt hier gar nicht vor
                        store.set('name', response.data.vorname + " " + response.data.nachname);
                        console.log("UImgmtComp.js: vet.readOne() state ", this.state);
                    }
                    resolve(response.status === HttpStatus.OK);
                })
                .catch(e => {
                    console.log("UImgmtComp.js: vet.readOne() e ", e);
                    reject();
                });
        });
    }

    connectChat() {
        return new Promise(async (resolve, reject) => {
            console.log("UImgmtComp.js: connectChat() ", this.state.doctor);
            try {
                await this.state.RocketRestAPI.login({
                    user: this.state.doctor.email,
                    password: this.state.doctor.passwort
                });
                if (this.state.RocketRestAPI.loggedIn()) {
                    console.log("UImgmtComp.js: connectChat() Rocket chat logged in")
                }
                this.setState({
                    connected: true
                })
                resolve(true);
            }
            catch(e) {
                console.log("UImgmtComp.js: connectChat() Rocket chat not logged in ", e);
                reject(e);
            };
        });
    }

    getVetProfile() {
        return new Promise((resolve, reject) => {
            console.log("UImgmtComp.js: getVetProfile(%s)", store.get('usrId'));
            DatabaseService.vet.profile
                .readOne(store.get('usrId'))
                .then(response => {
                    console.log("UImgmtComp.js: vet.profile.readOne() response ", response);
                    if(response.status === HttpStatus.OK) {
                        this.setState({
                            vetProfile: response.data
                        });
                        // In der DB liegt es als String und muss hier geparst werden
                        this.state.vetProfile.oeffnungszeiten = JSON.parse(response.data.oeffnungszeiten);
                    }
                    resolve(response.status === HttpStatus.OK);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });

        })
    }

    getAllVets() {
        return new Promise((resolve, reject) => {
            DatabaseService.vet.profile
                .read()
                .then(response => {
                    console.log("UImgmtComp.js: vet.profile.read() response ", response);
                    if(response.status === HttpStatus.OK) {
                        this.setState({
                            volltextsucheResults: response.data.records
                        });
                    }
                    resolve(response.status === HttpStatus.OK);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        })
    }

    getClinic() {
        return new Promise((resolve, reject) => {
            console.log("UImgmtComp.js: getClinic(%s)", this.state.clinicID);
            DatabaseService.clinic
                .read(this.state.clinicID)
                .then(response => {
                    console.log("UImgmtComp.js: clinic.read() response ", response);
                    if(response.status === HttpStatus.OK) {
                        this.setState({
                            clinic: response.data
                        });
                    }
                    resolve(response.status === HttpStatus.OK);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    getClinicProfile() {
        return new Promise((resolve, reject) => {
            console.log("UImgmtComp.js: getClinicProfile(%s)", this.state.clinicID);
            DatabaseService.clinic.profile
                .read(this.state.clinicID)
                .then(response => {
                    console.log("UImgmtComp.js: clinic.profile.read() response ", response);
                    if(response.status === HttpStatus.OK) {
                        this.setState({
                            clinicProfile: response.data
                        });

                        // In der DB liegt es als String und muss hier geparst werden
                        if (response.data.oeffnungszeiten != null) {
                            this.state.clinicProfile.oeffnungszeiten = JSON.parse(response.data.oeffnungszeiten.replace(/&quot;/g,'"'));
                        }else this.state.clinicProfile.oeffnungszeiten = JSON.parse(response.data.oeffnungszeiten);

                    }
                    resolve(response.status === HttpStatus.OK);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    updateEvents() {
        return new Promise((resolve, reject) => {
            console.log("UImgmtComp.js: updateEvents() ", store.get('usrId'));
            DatabaseService.vet.event
                .read(store.get('usrId'))
                .then(response => {
                    console.log("UImgmtComp.js: vet.event.read() response ", response);
                    if(response.status === HttpStatus.OK) {
                        if (!response.data) {
                            return;
                        }
                        this.props.saveFetched(response.data.records);
                        this.state.events = response.data.records;
                    }
                    resolve(true);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    volltextsuche = (value) => {
        console.log(value);
    }

    doctorSelected = (value, option) => {
        this.setState({
            selectedDoctor: option.key,
            currentHeaderKey: "noPage"
        }, () => {
            this.setState({
                currentHeaderKey: "profilePage"
            });
        });
    }

    addEvent = (event) => {
        console.log("UImgmtComp.js: addEvent() event ", event);
        this.props.updateEvents(event)
    }

    clinicSelected = (value, option) => {
        this.setState({
            selectedClinic: option.key,
            currentHeaderKey: "noPage"
        }, () => {
            this.setState({
                currentHeaderKey: "praxisProfile"
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.sessionActive && prevState.sessionActive) {
            console.log("session Deactivated")
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    timeout = 250; // Initial timeout duration as a class variable

    /**
     * @function connect
     * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
     */
    connect = () => {
        if (this.state.ws) {
            this.state.ws.close();
        }
        // var ws = new WebSocket("wss://hv-websocket-server.herokuapp.com/" + store.get('usrId') + "?vet=true");
        // url format: ws://localhost:18082/134?vet=true
        let ws = (new WebsocketService(store.get('usrId') + "?vet=true")).client;
        console.log("UImgmtComp.js: connect() websocket connecting...", ws);
        let that = this; // cache the this
        var connectInterval;

        // websocket onopen event listener
        ws.onopen = (s) => {
            console.log("UImgmtComp.js: connect() websocket on open ", s);
            that.setState({
                ws: ws
            });
            that.timeout = 250; // reset timer to 250 on open of websocket connection
            clearTimeout(connectInterval); // clear Interval on on open of websocket connection
        };

        ws.onmessage = function (event) {
            console.log("UImgmtComp.js: connect() websocket onmessage ", event);
            var message;
            try {
                message = JSON.parse(event.data);
                if (message.method === "call-request") {
                    console.log("UImgmtComp.js: connect() on message: ", message);
                } else if (message.method === "call-confirmation") {} else if (message.method === "events-update") {
                    that.addEvent(message.message)
                }
            } catch (e) {
                console.log(e)
            }
        }

        // websocket onclose event listener
        ws.onclose = e => {
            let sec = Math.min(10000 / 1000, (that.timeout + that.timeout) / 1000);
            console.log(`UImgmtComp.js: connect() on close. Reconnect will be attempted in ${sec} second.`, e.reason);
            that.timeout = that.timeout + that.timeout; //increment retry interval
            connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
        };

        // websocket onerror event listener
        ws.onerror = err => {
            console.error("UImgmtComp.js: connect() on error: ", err.message, "Closing socket");
            ws.close();
        };

        this.interval = setInterval(() => {
            const {
                ws
            } = this.state;
            const request = {
                method: "keep-alive",
                data: "",
                message: ""
            }
            try {
                // console.log("UImgmtComp.js: connect() send: ", request);
                ws.send(JSON.stringify(request)) //send data to the server
            } catch (error) {
                console.log("UImgmtComp.js: connect() send: error ", error) // catch error
            }
        }, 10000);
    };

    /**
     * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
     */
    check = () => {
        const { ws } = this.state;
        if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
    };

    callUser = (usrId, event) => {
        const {
            ws
        } = this.state;
        const session = uuidv4().toLowerCase()

        this.setState({
            callEvent: event,
            usrID: usrId,
            sessionActive: true,
            sessionName: session
        });

        const request = {
            method: "call-request",
            destination: "user",
            data: usrId,
            message: {
                sessionName: session,
                vetName: this.state.doctor.titel + " " + this.state.vorname + " " + this.state.nachname
            }
        }

        try {
            ws.send(JSON.stringify(request)) //send data to the server
        } catch (error) {
            console.log(error) // catch error
        }
    }

    historyCreateEntry(sessionStart, doc) {
        console.log("UImgmtComp.js: historyCreateEntry() sessionStart ", sessionStart, doc, this.state.callEvent)
        if(sessionStart !== null) {
            const data = {
                vetId: store.get('usrId'),
                usrId: this.state.usrID,
                eventId: this.state.callEvent.eventID,
                petId: this.state.callEvent.petID,
                start: sessionStart.valueOf(),
                end: new Date().valueOf(),
                doc: doc,
                recording: "0"
            }
            DatabaseService.callHistory
                .create(data)
                .catch(e => {
                    console.log(e);
                });
        }
    }

    stopSession = (sessionStart, doc) => {
        this.historyCreateEntry(sessionStart, doc);
        console.log("Session stopped");
        this.setState({
            sessionActive: false
        })
    };

    setMarkers = val => {
        this.setState({
            markers: val
        });
    };

    createChat = async () => {
        try {
            const chatExists = await DatabaseService.chat.exists("vet." + store.get('usrId'), "vet." + this.state.selectedDoctor);
            this.setState({
                currentHeaderKey: 'uebersicht',
                chatSelected: true,
                selectedChat: chatExists.data.chatID
            });
        } catch (e) {
            const chatName = uuidv4();
            const result = await this.state.RocketRestAPI.request('POST', "groups.create", {
                name: chatName,
                members: ["vet." + this.state.selectedDoctor]
            });

            DatabaseService.chat
                .create({
                    chatID: result.group._id,
                    chatName: chatName,
                    usrID: "vet." + store.get('usrId'),
                    type: "message"
                })
                .catch(e => console.log(e));

            DatabaseService.chat
                .create({
                    chatID: result.group._id,
                    chatName: chatName,
                    usrID: "vet." + this.state.selectedDoctor,
                    type: "message"
                })
                .catch(e => console.log(e));

            await this.state.RocketRestAPI.request('POST', "groups.setDescription", {
                roomId: result.group._id,
                description: "Test"
            });
            this.setState({
                currentHeaderKey: 'uebersicht',
                chatSelected: true,
                selectedChat: result.group._id
            });
        }
    }

    setRecording = val => {
        this.setState({
            isRecording: val
        });
    }

    onFinish = values => {
        this.setState({
            markers: this.state.markers.concat({
                time: this.state.timerTime,
                notice: values.bemerkung
            })
        });
        this.setState({
            popoverVisible: false
        });
    };

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    handleVisibleChange = visible => {
        this.setState({
            popoverVisible: visible
        });
    };

    closeUserProfile = () => {
        this.setState({
            userProfileOpen: false
        });
    }

    /**
     * Event handler, Einstellungen haben sich geändert und State muss aktualisiert werden
     * @param {string} key Mögliche Varianten [ meinkonto || meinprofil || praxis || einstellungen ]
     * @param {object} data Ein KeyValue Object
     */
    onSettingsChanged = (key, data) => {
        console.log("UImgmtComp.js: onSettingsChanged() ", key, data, this.state);

        if(key === 'meinkonto') {
            this.setState(state => {
                state.doctor.titel = data.titel;
                state.doctor.vorname = data.vorname;
                state.doctor.nachname = data.nachname;
                state.doctor.email = data.email;

                state.vetProfile.geschlecht = data.geschlecht;
                state.vetProfile.name = data.name;
                state.vetProfile.mobiltelefon = data.mobiltelefon;
                console.log("UImgmtComp.js: onSettingsChanged() meinkonto state ", state);
                return state;
            })
        } else if(key === 'meinprofil') {
            this.setState(state => {
                for (const st in data) {
                    console.log("UImgmtComp.js: onSettingsChanged() meinprofil ", st, data[st]);
                    state.vetProfile[st] = data[st];
                }
                console.log("UImgmtComp.js: onSettingsChanged() meinprofil state ", state);
                return state;
            })
        } else if(key === 'praxis') {
            this.setState(state => {
                for (const st in data) {
                    console.log("UImgmtComp.js: onSettingsChanged() praxis ", st, data[st]);
                    if(st in state.clinic) {
                        state.clinic[st] = data[st];
                    } else if(st in state.clinicProfile) {
                        state.clinicProfile[st] = data[st];
                    }
                }
                console.log("UImgmtComp.js: onSettingsChanged() praxis state ", state);
                return state;
            })
        } else if(key === 'einstellungen') {
            this.setState(state => {
                console.log("UImgmtComp.js: onSettingsChanged() einstellungen state ", state);
                return state;
            })
        }

    }


    render() {
        /*
        console.log("EVENT SIZE: " + this.state.events.length);
        for(var i=0;i<this.state.events.length;i++) {
            console.log("Event (" + i + ") " + this.state.events[i].eventID);
        }
        */
        if(!isLoggedIn()) {
            return (<Redirect to = "/login" />);
        }
        if(!this.state.vetsLoaded) {
            return (
                <Space
                    style = {
                    {
                        height: "100%",
                        width: "100%"
                    }
                }>
                    <Spin size = "large" />
                </Space>
            );
        }
        if(!parseInt(this.state.doctor.verifiziert)) {
            return (<Redirect to = "validation-required" />);
        }

        console.log("UImgmtComp.js render() isMobile ", isMobile);
        if(isMobile) {
            return (
                <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
                    <div className="sitelayoutcontent-mobile">
                        {(this.state.currentHeaderKey === 'uebersicht' || this.state.currentHeaderKey === 'patienten') && <DashboardMainComponent setSessionEnded={() => this.setState({ stopSession: false, sessionActive: false })} stopSession={this.state.stopSession} usrID={this.state.usrID} demoCallActive={this.state.demoCallActive} nextEvent={this.state.nextEvent} currentKey={this.state.currentHeaderKey} timerTime={this.state.timerTime} setMarkers={(val) => this.setMarkers(val)} markers={this.state.markers} sessionActive={this.state.sessionActive} dataLoaded={this.state.dataLoaded} events={this.state.events} doctor={this.state.doctor} startConf={this.callUser} updateEvents={() => this.updateEvents()} />}
                        {this.state.currentHeaderKey === 'profil' && <ProfilePage doctor={this.state.doctor} />}
                        {this.state.currentHeaderKey === 'calendar' && <Calendar style={{ height: "100%", width: "800px" }} updateEvents={() => this.updateEvents()} events={this.state.events} />}
                        {this.state.currentHeaderKey === 'mgmt' && <KlinikMgmt clinicID={this.state.doctor.clinicID} />}
                        {this.state.currentHeaderKey === 'konferenz' && <Conferencing buttonClicked={this.state.createNewButtonPressed} closeModal={() => this.setState({ createNewButtonPressed: false })} />}
                        {this.state.currentHeaderKey === 'settings' && <Settings doctor={this.state.doctor} vetProfile={this.state.vetProfile} clinicProfile={this.state.clinicProfile} onChange={(key, data) => this.onSettingsChanged(key, data)}/>}
                    </div>
                    <BottomNavigation value={this.state.currentHeaderKey} onChange={(event, newValue) => this.setState({ currentHeaderKey: newValue })}>
                        <BottomNavigationAction label="Übersicht" value="uebersicht" icon={<DashboardIcon />} />
                        <BottomNavigationAction label="Patienten" value="patienten" icon={<PetsIcon />} />
                        <BottomNavigationAction label="Kalender" value="calendar" icon={<TodayIcon />} />
                        <BottomNavigationAction label="Konferenz" value="konferenz" icon={<SupervisorAccountIcon />} />
                        <BottomNavigationAction label="Einstellungen" value="settings" icon={<SettingsIcon />} />
                    </BottomNavigation>
                </div>
            );
        }

        return (
            <Layout className="layout" style={{ height: "100vh", backgroundColor: "#fffcf7" }}>
                <Sider width={250} style={{ backgroundColor: "transparent" }}>
                    <div className="headerLayout">
                        <div className="headerChildLeft">
                            <img src={Pfote} height="50" width="190" alt="" loading="eager" style={{ margin: 20 }} />
                            <ButtonBase style={{ marginBottom: 30}} onClick={() => this.setState({ currentHeaderKey: 'settings' })}>
                                <span style={{ fontWeight: "bold", color: this.state.currentHeaderKey === 'settings' ? "#25D2AA" : "black" }} >{this.state.doctor.titel + " " + this.state.vorname + " " + this.state.nachname}</span>
                            </ButtonBase>
                            <div style={{ height: "20px" }} />
                            <AnimateSharedLayout>
                                {this.state.demoCallActive && <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 10 }}>
                                    {this.state.currentHeaderKey === 'videosprechstunde' ? (<motion.div className="verticalLineMenu" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                                    <ButtonBase onClick={() => this.setState({ currentHeaderKey: 'videosprechstunde' })}>
                                        <span style={{ fontWeight: "bold", color: this.state.currentHeaderKey === 'videosprechstunde' ? "#25D2AA" : "black" }} >Videosprechstunde</span>
                                    </ButtonBase>
                                </div>}
                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 10 }}>
                                    {this.state.currentHeaderKey === 'uebersicht' ? (<motion.div className="verticalLineMenu" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                                    <ButtonBase onClick={() => this.setState({ currentHeaderKey: 'uebersicht' })}>
                                        <span style={{ fontWeight: "bold", color: this.state.currentHeaderKey === 'uebersicht' ? "#25D2AA" : "black" }} >Meine Übersicht</span>
                                    </ButtonBase>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 10 }}>
                                    {this.state.currentHeaderKey === 'patienten' ? (<motion.div className="verticalLineMenu" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                                    <ButtonBase onClick={() => this.setState({ currentHeaderKey: 'patienten' })}>
                                        <span style={{ fontWeight: "bold", color: this.state.currentHeaderKey === 'patienten' ? "#25D2AA" : "black" }} >Patienten & Besitzer</span>
                                    </ButtonBase>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 10 }}>
                                    {this.state.currentHeaderKey === 'calendar' ? (<motion.div className="verticalLineMenu" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                                    <ButtonBase onClick={() => this.setState({ currentHeaderKey: 'calendar' })}>
                                        <span style={{ fontWeight: "bold", color: this.state.currentHeaderKey === 'calendar' ? "#25D2AA" : "black" }} >Kalender</span>
                                    </ButtonBase>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 10 }}>
                                    {this.state.currentHeaderKey === 'konferenz' ? (<motion.div className="verticalLineMenu" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                                    <ButtonBase onClick={() => this.setState({ currentHeaderKey: 'konferenz' })}>
                                        <span style={{ fontWeight: "bold", color: this.state.currentHeaderKey === 'konferenz' ? "#25D2AA" : "black" }}>Konferenz</span>
                                    </ButtonBase>
                                </div>
                            </AnimateSharedLayout>
                        </div>
                        <Link to="/">
                        <Button onClick={() => store.remove('loggedIn')} style={{ margin: 20 }}>Logout</Button>
                        </Link>
                    </div>
                </Sider>
                <Layout>
                    <Header style={{ backgroundColor: "#fffcf7", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <AutoComplete style={{ borderRadius: "30px", width: "100%" }} size="large" placeholder="Volltextsuche" prefix={<SearchOutlined />} onSearch={this.volltextsuche} onSelect={this.doctorSelected} >
                            {this.state.volltextsucheResults.map((item) => (
                                <Option key={item.vetID+'_'+item.name} value={item.name}>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Paper elevation={3} style={{ display: "flex", flexDirection: "row", justifyContent: "center", padding: 25, margin: 10, width: 350 }}>
                                            <Avatar size={72} src={item.profilePic ? item.profilePic : profile} />
                                            <div style={{ width: 18 }} />
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <span style={{ fontWeight: "bold", color: "#25D2AA" }}>{item.name}</span>
                                                <span style={{ fontWeight: "bold", marginTop: 7 }}>Facharzt für Hunde</span>
                                                <span style={{ color: "grey", marginTop: 7 }}>{item.addresse}</span>
                                            </div>
                                        </Paper>
                                    </div>
                                </Option>
                            ))}
                        </AutoComplete>

                        {this.state.currentHeaderKey === 'profilePage' ?
                        <>
                            <Button size="large" style={{ borderColor: "black", color: "black", marginLeft: 15 }} ghost shape="round" onClick={() => this.createChat()}>Chat</Button>
                            <Button size="large" style={{ backgroundColor: "#25D2AA", color: "white", marginLeft: 15 }} shape="round">Anruf</Button>
                        </>
                        :
                        <>
                            <Button size="large" style={{ borderColor: "black", color: "black", marginLeft: 15 }} ghost shape="round">+ Patient hinzufügen</Button>
                            <Button size="large" onClick={() => this.setState({ createNewButtonPressed: true })} style={{ backgroundColor: "#25D2AA", color: "white", marginLeft: 15 }} shape="round">{this.state.currentHeaderKey === 'konferenz' ? "+ Konferenz erstellen" : "+ Termin erstellen"}</Button>
                        </>
                        }
                        {this.state.sessionActive && (this.state.launchWindow ?
                        <IconButton color="primary" component="span" onClick={() => this.setState({ launchWindow: false })}>
                            <CancelPresentationIcon />
                        </IconButton>
                        :
                        <IconButton color="primary" component="span" onClick={() => this.setState({ launchWindow: true })}>
                            <OpenInNewIcon />
                        </IconButton>
                        )
                        }
                    </Header>
                    <Content style={{ height: "100%", overflow: "auto", backgroundColor: "#fffcf7" }}>
                        <div className="sitelayoutcontent">
                            {this.state.sessionActive && <div id="callDiv" />}
                            {(this.state.currentHeaderKey === 'uebersicht' || this.state.currentHeaderKey === 'patienten') && <DashboardMainComponent selectedChat={this.state.selectedChat} setSessionEnded={() => this.setState({ stopSession: false, sessionActive: false })} stopSession={this.state.stopSession} usrID={this.state.usrID} demoCallActive={this.state.demoCallActive} currentKey={this.state.currentHeaderKey} timerTime={this.state.timerTime} setMarkers={(val) => this.setMarkers(val)} markers={this.state.markers} sessionActive={this.state.sessionActive} doctor={this.state.doctor} startConf={this.callUser}  />}
                            {this.state.currentHeaderKey === 'profil' && <ProfilePage doctor={this.state.doctor} />}
                            {this.state.currentHeaderKey === 'calendar' && <Calendar style={{ height: "100%", width: "800px" }} updateEvents={() => this.updateEvents()} events={this.state.events} />}
                            {this.state.currentHeaderKey === 'mgmt' && <KlinikMgmt clinicID={this.state.doctor.clinicID} />}
                            {this.state.currentHeaderKey === 'konferenz' && <Conferencing rocketRestApi={this.state.RocketRestAPI} buttonClicked={this.state.createNewButtonPressed} closeModal={() => this.setState({ createNewButtonPressed: false })} />}
                            {this.state.currentHeaderKey === 'settings' && <Settings doctor={this.state.doctor} vetProfile={this.state.vetProfile} clinic={this.state.clinic} clinicProfile={this.state.clinicProfile} onChange={(key, data) => this.onSettingsChanged(key, data)} />}
                            {this.state.currentHeaderKey === 'profilePage' && <VetProfile doctor={this.state.selectedDoctor} selectClinic={this.clinicSelected} />}
                            {this.state.currentHeaderKey === 'praxisProfile' && <PraxisProfile clinic={this.state.selectedClinic} selectDoctor={this.doctorSelected} />}
                        </div>
                    </Content>
                </Layout>
                {this.state.sessionActive &&
                <Portal launchWindow={this.state.launchWindow} windowClosed={() => this.setState({ launchWindow: false })}>
                    <CallComp sessionName={this.state.sessionName} usrID={this.state.usrID} stopSession={this.stopSession} name={this.state.doctor.titel + " " + this.state.vorname + " " + this.state.nachname} doctor={this.state.doctor} />
                </Portal>}
            </Layout>
        );
    }

}

export default connect(null, mapDispatchToProps)(UImgmtComp);
