import React, {Component} from 'react';
import RichTextEditor from 'react-rte';
import Paper from '@material-ui/core/Paper';

class DocumentationCard extends Component {

  state = {
    value: RichTextEditor.createEmptyValue()
  }

  onChange = (value) => {
    this.setState({value});
  };

  componentDidUpdate(prevProps){
    if(!prevProps.sessionEnded && this.props.sessionEnded)
        this.props.setDocumentation(this.state.value.toString('html'));
  }

  render () {
    return (
    <Paper elevation={3} style={{display:"flex", flexDirection:"column", justifyContent:"space-around", padding: 25, overflow:"auto"}}>
     <span style={{ fontSize:20, fontWeight:"bold", marginBottom:30}}>Dokumentation</span>
      <RichTextEditor
        value={this.state.value}
        onChange={this.onChange}
      />
    </Paper>
    );
  }
}

export default DocumentationCard;