
class CallHistoryAction {
    /**
     * CallHistory Action Class
     */
    constructor(axios) {
        this.http = axios;        
    }

    
    /**
     * Create one call history
     * @param {Object} data 
     * @returns Promise object
     */
    create(data) {
        return this.http.post("/api/callhistory/create.php", data);
    };
    /**
     * Read all call history
     * @returns Promise object
     */
    read(id) {
        return this.http.get(`/api/callhistory/read_by_index.php?id=${id}`);
    };
    /**
     * Read one call history
     * @param {Object} id call history id
     * @returns Promise object
     */
    readOne(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Update one call history
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Delete one call history
     * @param {Object} id call history id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };


}

export default CallHistoryAction;