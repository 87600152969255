import React from "react";
import {Input, PageHeader} from 'antd';
// https://github.com/RocketChat/Rocket.Chat.js.SDK
import BotClient from '@rocket.chat/sdk/clients/Bot';
import RocketChatAPI from "@rocket.chat/sdk/lib/api/RocketChat"
import 'react-chat-elements/dist/main.css'
import Messages from "./Messages"
import ChatList from "./ChatList"
import { sha256 } from 'js-sha256';
import 'dotenv/config';

class ChatComp extends React.Component {

    constructor(props){
        super(props);
        console.log("ChatComp.js: props ", this.props);

        this.state = {
            RocketChat: new BotClient({
                host: process.env.REACT_APP_HTTP_CHAT_BASE_URL,
                useSsl: true
            }),
            RocketChatAPI: {},
            selectedChat: "",
            sendMessages: false,
            connected: false
        }
    }

    // TODO: Meldet sich der Vet als bot ein?
    //      warum? dafür gibt es api
    async componentDidMount() {
        this.setState({connected: false});
        this.conn = await this.state.RocketChat.connect();
        // console.log("ChatComp.js: componentDidMount() RocketChat.connect() ", this.conn)
        if(this.conn) {
            // FIXME: Rocket Passwort scheint inkorrekt zu sein, Prüfe Passwort bei der Registrierung und decrypte es vor login
            console.log("ChatComp.js: componentDidMount() RocketChat.connect() ", this.props.doctor.email, this.props.doctor.passwort);
            this.state.RocketChat.login({
                user: this.props.doctor.email,
                password: this.props.doctor.passwort
            })
                .then(user => {
                    this.user = user;
                    console.log("ChatComp.js: RocketChat.login() this.user ", this.user );
                    this.setState({RocketChatAPI: new RocketChatAPI(this.state.RocketChat)}, () => {
                        if(this.state.RocketChatAPI.loggedIn()) {
                            console.log("RestApi logged in")
                        }
                        if(this.props.selectedChat !== "") {
                            this.setState({ selectedChat:this.props.selectedChat, sendMessages: true });
                        }
                    });
                    this.setState({connected: true})
                })
                .catch(error => {
                    console.log("ChatComp.js: componentDidMount() RocketChat.login() error", error);
                })
        } else {
            console.log("ChatComp.js: componentDidMount() RocketChat.connect() error", this.conn);
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.selectedChat !== this.props.selectedChat){
            this.setState({ selectedChat:this.props.selectedChat, sendMessages: true });
        }
    }



    render() {
        if(!this.state.connected)
        return(
            <div/>
        );

        return(
            <div style={{height:"100%", width:"100%"}}>
                {!this.state.sendMessages &&
                    <ChatList rocketChat={this.state.RocketChat} selectChat={ (chat) => this.setState({ selectedChat : chat, sendMessages: true })}/>
                }
                {this.state.sendMessages &&
                    <PageHeader
                        ghost={false}
                        onBack={() => this.setState({ sendMessages: false })}
                        title={this.state.selectedChat}
                    >
                        <Messages rocketChat={this.state.RocketChat} restAPI={this.state.RocketChatAPI} selectedChat={this.state.selectedChat} />
                    </PageHeader>
                }
            </div>
        );
    }

}

export default ChatComp
