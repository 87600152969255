import React from 'react';
import Logo from "./assets/HelloVetLogoUser.png"
import { Layout, Button } from 'antd';
import PhoneIcon from '@material-ui/icons/Phone';
import SoFunktioniertsHeader from './assets/Sofunktionierts.png'
import ErstelleDeinKonto from './assets/ErstelleDeinKonto.png'
import Anleitung from './assets/anleitung.png'
import Path from './assets/Path7.png'
import Dot from './assets/Dot.png'
import Dot2 from './assets/Dot2.png'
import Background from "./assets/Background.png"
import Step1 from "./assets/step1-backg.png"
import Step2 from "./assets/step2-backg.png"
import Step3 from "./assets/step3-backg.png"
import LineFooter from "./assets/LineFooter.png"
import {
  Link
} from "react-router-dom";

const { Header, Content, Footer } = Layout;

function SoFunktionierts(){
  return (
  <Layout className="layout" style={{backgroundColor:"white"}}>
    <Header style={{ backgroundColor: 'transparent' }}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
          <Link to="/">
             <img src={Logo} style={{ width: "200px" }} />
          </Link>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", fontSize: 17 }}>
            <Link to="/manual">
            <span style={{ color:"black" ,paddingRight: 24 }}>So funktioniert's</span>
            </Link>
            <Link to="/qa">
            <span style={{ color:"black" ,paddingRight: 24 }}>Häufige Fragen</span>
            </Link>
            <PhoneIcon style={{ paddingRight: 5, marginTop: 5, fontSize: 22 }} />
            <span style={{ paddingRight: 24 }}>089 12 34 56 78</span>
          <Link to="/newsletter">
            <Button style={{ backgroundColor: "#25D2AA", color: "white" }} shape="round">
              Mehr Infos erhalten
            </Button>
          </Link>
          </div>
        </div>
    </Header>
    <Content >
      <img src={Path} style={{position:"absolute", left:"0%", width:"100%", top:"100px", zIndex:0}}/>
      <img src={Dot} style={{position:"absolute", right:"20%", top:"300px", zIndex:0}}/>
      <img src={Dot2} style={{position:"absolute", right:"0%", top:"60px", zIndex:0}}/>
      <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", zIndex:5}}>
        <div style={{marginTop:200, display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center"}}>
          <span style={{fontSize:70, fontFamily:"Recoleta", zIndex:5}} >So funktioniert’s</span>
          <span style={{marginTop:20, fontSize:24, fontFamily:"WorkSans", zIndex:5}} >Erstelle jetzt dein »Hello Vet« Konto und</span>
          <span style={{fontSize:24, fontFamily:"WorkSans", zIndex:5}} >gewinne eine Jahresfutterration</span> 
          <span style={{fontSize:24, fontFamily:"WorkSans", zIndex:5}} >für deinen Vierbeiner.</span>
        </div>
        <div style={{width:"1100px",marginTop:280, display:"flex", zIndex:10, backgroundImage: `url(${Step1})`, backgroundPosition:"left center", backgroundRepeat:"no-repeat", backgroundSize:"contain"}}>
          <div style={{flex:1}}/>
          <div style={{flex:1, display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
          <span style={{fontSize:48, fontFamily:"Recoleta"}} >Freien Termin wählen</span>
          <span style={{marginTop:15, fontSize:24, fontFamily:"WorkSans"}} >Angesichts der dramatischen und sich weiter verschlechternden Lage im Luftverkehr will die Lufthansa nun das Konzept aus der Tierwelt aufgreifen und weite Teile des Konzerns in einen "Wintermodus" versetzen.</span>
          <span style={{marginTop:15, fontSize:24, fontFamily:"WorkSans"}} >In einem sechsseitigen Schreiben an die Mitarbeiter macht der Vorstand klar, dass die Zeiten für die Fluggesellschaften noch härter werden, als sie ohnehin schon sind. Statt im vierten Quartal wieder die Hälfte des eigentlichen Programms anzubieten, wird es höchstens ein Viertel sein.</span> 
          </div>
        </div>
        <div style={{width:"1100px",marginTop:280, display:"flex", zIndex:10, backgroundImage: `url(${Step2})`, backgroundPosition:"left center", backgroundRepeat:"no-repeat", backgroundSize:"contain"}}>
          <div style={{flex:1, display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
          <span style={{fontSize:48, fontFamily:"Recoleta"}} >Problem beschreiben</span>
          <span style={{marginTop:15, fontSize:24, fontFamily:"WorkSans"}} >Angesichts der dramatischen und sich weiter verschlechternden Lage im Luftverkehr will die Lufthansa nun das Konzept aus der Tierwelt aufgreifen und weite Teile des Konzerns in einen "Wintermodus" versetzen.</span>
          <span style={{marginTop:15, fontSize:24, fontFamily:"WorkSans"}} >In einem sechsseitigen Schreiben an die Mitarbeiter macht der Vorstand klar, dass die Zeiten für die Fluggesellschaften noch härter werden, als sie ohnehin schon sind. Statt im vierten Quartal wieder die Hälfte des eigentlichen Programms anzubieten, wird es höchstens ein Viertel sein.</span> 
          </div>
          <div style={{flex:1}}/>
        </div>
        <div style={{width:"1100px",marginTop:280, display:"flex", zIndex:10, backgroundImage: `url(${Step3})`, backgroundPosition:"left center", backgroundRepeat:"no-repeat", backgroundSize:"contain"}}>
          <div style={{flex:1}}/>
          <div style={{flex:1, display:"flex", marginTop:10 , flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
          <span style={{fontSize:48, fontFamily:"Recoleta"}} >Freien Termin wählen</span>
          <span style={{marginTop:15, fontSize:24, fontFamily:"WorkSans"}} >Angesichts der dramatischen und sich weiter verschlechternden Lage im Luftverkehr will die Lufthansa nun das Konzept aus der Tierwelt aufgreifen und weite Teile des Konzerns in einen "Wintermodus" versetzen.</span>
          <span style={{marginTop:15, fontSize:24, fontFamily:"WorkSans"}} >In einem sechsseitigen Schreiben an die Mitarbeiter macht der Vorstand klar, dass die Zeiten für die Fluggesellschaften noch härter werden, als sie ohnehin schon sind. Statt im vierten Quartal wieder die Hälfte des eigentlichen Programms anzubieten, wird es höchstens ein Viertel sein.</span> 
          </div>
        </div>
        <span style={{marginTop:100, fontSize:48, fontFamily:"Recoleta"}} >Sie haben weitere Fragen?</span>
        <span style={{marginTop:10, fontSize:24, fontFamily:"WorkSans"}} >Kein Problem, wir helfen Ihnen gern weiter.</span>
        <Button style={{marginTop:50, backgroundColor:"#25D2AA", color:"white", height:"60px", width:"320px", fontSize:24}} shape="round">
            Kontaktieren Sie uns
        </Button>
        <span style={{marginTop:60, fontSize:24, fontFamily:"WorkSans"}} >+49 89 12 34 56 78</span>
        <span style={{marginTop:10, fontSize:24, fontFamily:"WorkSans"}} >post@hellovet.com</span>
        <img src={LineFooter} style={{marginTop:50, width:"100%", zIndex:0}}/>
        
        <div style={{width: "80%", display:"flex", flexDirection:"row"}}>
          <div style={{flex: 1 }}>
          <img src={Logo} style={{ width: "200px"}} />
          </div>  
          <div style={{flex: 1, display:"flex", flexDirection: "column" ,justifyContent: "flex-start", alignItems:"flex-start"}}>
            <span className="footer-headings" >Info</span>
            <span className="footer-elements" >Über uns</span>
            <span className="footer-elements" >Presse</span>
          </div>
          <div style={{flex: 1, display:"flex", flexDirection: "column" ,justifyContent: "flex-start", alignItems:"flex-start"}}>
            <span className="footer-headings" >Produkt</span>
            <span className="footer-elements" >So funktiononiert's</span>
            <span className="footer-elements" >Häufige Fragen</span>
            <span className="footer-elements" >Hello Vet Patenient</span>
          </div>
          <div style={{flex: 1, display:"flex", flexDirection: "column" ,justifyContent: "flex-start", alignItems:"flex-start"}}>
            <span className="footer-headings" >Rechtliches</span>
            <span className="footer-elements" >Datenschutz</span>
            <span className="footer-elements" >Nutzungsbedingenungen</span>
            <span className="footer-elements" >Impressum</span>
          </div>
        </div>
        <span style={{marginTop:90, marginBottom: 50, fontSize:16, fontFamily:"WorkSans"}} >Hello Vet GmbH, Alle Rechte vorbehalten.</span>
      </div>
    </Content>
  </Layout>
  );

}

export default SoFunktionierts
