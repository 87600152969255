
class VetPatientAction {
    /**
     * VetPatient Action Class
     */
    constructor(axios) {
        this.http = axios;
    }

    /**
     * Create one vet patient
     * @param {Object} data
     * @returns Promise object
     */
    create(data) {
        return this.http.post("/api/vetpatients/create.php", data);
    };
    /**
     * Read all patients belong to vet
     * @param {Object} vetId vet id
     * @returns Promise object
     */
    read(vetId) {
        return this.http.get(`/api/vetpatients/read_by_index.php?id=${vetId}`);
    };
    /**
     * Read all vets belong to patient
     * @param {Object} patientid patient id
     * @returns Promise object
     */
    readByPatient(patientid) {
        return this.http.get(`/api/vetpatients/read_by_index_pat.php?id=${patientid}`);
    };
    /**
     * Read one vet patient
     * @param {Object} id vet id
     * @returns Promise object
     */
    readOne(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Update one vet patient
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Delete one vet patient
     * @param {Object} id vet id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };

}

export default VetPatientAction;
