import React from "react"
import { List, TimePicker, Divider, Radio, Form, Button, Input, Select, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import store from 'store'
import { Redirect, useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import profile from "../../../Profile_Pic.png";
import DatabaseService from "../../../services/database.service"
import ImageUpload from "../profile/ImageUpload"
import OpeningHours from "../profile/OpeningHours"
import { UserOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Avatar from "../profile/UploadAvatar"
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <span style={{ fontSize: 20, fontWeight: "bold" }}>{children}</span>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class MeinProfil extends React.Component {

    constructor(props) {
        super(props);
        console.log("MeinProfil.js: this.props ", this.props);
        this.state = {
            mitglieder: [],
            anfragen: [],
            fileList: this.parsePicturesJson(),
            clinicInfo: this.props.clinicProfile,
            vetprofile: [],
            editModal: false,
            editAddress: false,
            address: this.props.vetProfile.addresse,
            editSpezialgebiet: false,
            editAbout: false,
            about: this.props.vetProfile.about,
            editLeistungsspektrum: false,
            leistungsspektrumDesc: [],
            leistungsspektrumNew: [],
            leistungsspektrumIdx: {},
            website: this.props.vetProfile.website,
            websiteNew: this.props.vetProfile.website,
            editZugangsinformationen: false,
            zugangsinformationen: this.props.vetProfile.zugangsinformationen,
            editBehandlerprofil: false,
            behandlerprofil: this.props.vetProfile.behandlerprofil,
            oeffnungszeiten: this.props.vetProfile.oeffnungszeiten,
            editSprachen: false,
            leistungsspektrum: this.parsePicturesJson(),
            sprachen: [],
            sprachenNew: [],
            sprachenString: this.props.vetProfile.sprachen,
            showLeistunsspektrum: true,
            showZugangsinformationen: false,
            showBehandlerprofil: true,
            showÖffnungszeiten: true
        }

        this.getClinicVets();
        this.getVet();
    }

    showLeistunsspektrum = () => {
        this.setState({ showLeistunsspektrum: !this.state.showLeistunsspektrum })
    }

    showZugangsinformationen = () => {
        this.setState({ showZugangsinformationen: !this.state.showZugangsinformationen })
    }

    showBehandlerprofil = () => {
        this.setState({ showBehandlerprofil: !this.state.showBehandlerprofil })
    }

    showÖffnungszeiten = () => {
        this.setState({ showÖffnungszeiten: !this.state.showÖffnungszeiten })
    }

    parseLeistungsspektrumJson() {
        try {
            return JSON.parse(this.props.vetProfile.leistungsspektrumTags)
        }
        catch (error) {
            return [];
        }
    }

    parsePicturesJson() {
        try {
            const parsedJson = JSON.parse(this.props.doctor.vetPictures);
            return parsedJson.vetPictures;
        }
        catch (error) {
            return [];
        }
    }

    parseObj(data) {
        if (typeof data !== "undefined" && data != null) {
            var str = data.split("&quot;");
            var output = [];

            for(let i=1;i<str.length;i=i+2) {
                output.push(str[i]);
            }

            return output;
        }

        return [];
    }

    getVet() {
        DatabaseService.vet.profile
            .readOne(store.get('usrId'))
            .then(response => {
                this.setState({
                    vetprofile: response.data,
                });
            })
    }

    getClinicVets() {
        return new Promise((resolve, reject) => {
            console.log("MeinProfil.js: getClinicVets(%s)", this.props.vetProfile.clinicID);
            DatabaseService.clinic.profile
                .readAccepted(this.props.vetProfile.clinicID)
                .then(response => {
                    console.log("MeinProfil.js: clinic.profile.readAccepted()", response);
                    if (response.data.length > 0) {
                        response.data.requests.forEach(item => {
                            DatabaseService.vet
                                .readOne(item.vetID)
                                .then(response => {
                                    if (parseInt(response.data.clinicAccepted, 10)) {
                                        this.state.mitglieder.push(item);
                                    }
                                    else {
                                        this.state.anfragen.push(item);
                                    }
                                })
                        });
                        resolve(true);
                    }
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        })
    }

    addDoctor = vetId => {
        DatabaseService.vet
            .addToClinic(vetId)
            .then(response => {
                console.log(response.data);
                this.setState({
                    mitglieder: [],
                    anfragen: []
                });
                this.getClinicVets();
            })
            .catch(e => {
                console.log(e);
            });
    };

    removeDoctor = vetId => {
        DatabaseService.vet
            .removeFromClinic(vetId)
            .then(response => {
                console.log(response.data);
                this.setState({
                    mitglieder: [],
                    anfragen: []
                });
                this.getClinicVets();
            })
            .catch(e => {
                console.log(e);
            });
    };

    /**
     * @description Speichert die Ausbildung und Fachgebiet im Backend und schließt modales Fenster
     * @param {object} values {ausbildung, fachgebiet}
     */
    handleOk = (values) => {
        let pr = [];
        pr.push(DatabaseService.vet.profile.update({ vetID: this.props.doctor.id, ausbildung: values.ausbildung }));
        pr.push(DatabaseService.vet.profile.update({ vetID: this.props.doctor.id, fachgebiet: values.fachgebiet }));

        Promise.all(pr)
            .then(x => {
                this.props.onChange({
                    ausbildung: values.ausbildung,
                    fachgebiet: values.fachgebiet
                });
            })
            .catch(error => {
                console.log("Unable to update Ausbildung:Fachgebiet ", error);
            })
            .finally(this.setState({ editModal: false }));
    };

    async handleChangeSpektrum() {
        var leistungssp = this.parseObj(this.state.vetprofile.leistungsspektrum);
        leistungssp.push(this.state.leistungsspektrumNew);

        await DatabaseService.vet.profile
            .update({
                vetID: store.get('usrId'),
                leistungsspektrum: JSON.stringify(leistungssp)
            })

        this.getVet();
        this.setState({ leistungsspektrumNew: [] })
    }

    /**
     * @description Öffnet modales Fenster
     */
    showModal = () => {
        this.setState({ editModal: true });
    };

    /**
     * @description Schließt modales Fenster
     */
    handleCancel = () => {
        this.setState({ editModal: false });
    };

    addTag = e => {
        this.setState({
            leistungsspektrum: this.state.leistungsspektrum.concat(e.target.value)
        },
            () => DatabaseService.vet.profile.update({
                vetID: this.props.doctor.id,
                leistungsspektrumTags: JSON.stringify(this.state.leistungsspektrum)
            })
        );
        console.log(e.target.value);
    }

    /**
     * @description Fügt eine neue Sprache hinzu
     * @param {string} val
     */
    /*
    addLanguage = val => {
        this.setState({ sprachen: this.state.sprachen.concat(val) })
    }
    HIER
    */
    /**
     * @description Editiert Sprachen
     */
    editSprachen = () => {
        if (this.state.editSprachen) {
            this.setState({ sprachen: this.state.sprachen.concat(this.state.sprachenNew) });
            var sprachenString = this.state.sprachen.concat(this.state.sprachenNew).join(", ");
            DatabaseService.vet.profile
                .update({ vetID: this.props.doctor.id, sprachen: sprachenString })
                .then(() => {
                    this.props.onChange({
                        sprachen: sprachenString
                    });
                })
                .finally(this.setState({ sprachenString: sprachenString, editSprachen: false, sprachenNew: [] }));
        }
        else {
            this.setState({
                sprachen: this.state.sprachenString ? this.state.sprachenString.split(', ') : []
            },
                () => this.setState({ editSprachen: true })
            )
        }
    }

    /**
     * @description Editiert die Info 'über mich'
     */
    editAbout = () => {
        if (this.state.editAbout) {
            DatabaseService.vet.profile
                .update({ vetID: this.props.doctor.id, about: this.state.about })
                .then(() => {
                    this.props.onChange({
                        about: this.state.about
                    });
                })
                .finally(this.setState({ editAbout: false }));
        }
        else {
            this.setState({ editAbout: true });
        }
    }

    editAddress = () => {
        if (this.state.editAddress) {
            DatabaseService.vet.profile
                .update({ vetID: this.props.doctor.id, addresse: this.state.address })
                .then(() => {
                    this.props.onChange({
                        addresse: this.state.address
                    });
                })
                .finally(this.setState({ editAddress: false }));
        }
        else {
            this.setState({ editAddress: true });
        }
    }

    async editLeistungsspektrum(idx) {
        this.setState({ leistungsspektrumIdx: idx });

        if (this.state.editLeistungsspektrum) {
            var leistungssp = this.parseObj(this.state.vetprofile.leistungsspektrum);

            if (this.state.leistungsspektrumDesc.length > 0) {
                leistungssp[this.state.leistungsspektrumIdx] = this.state.leistungsspektrumDesc;
            }else {
                if (idx > -1) {
                    leistungssp.splice(idx, 1);
                }
            }

            await DatabaseService.vet.profile
                    .update({
                        vetID: store.get('usrId'),
                        leistungsspektrum: JSON.stringify(leistungssp)
                    })

            this.getVet();
            this.setState({ leistungsspektrumDesc: [], editLeistungsspektrum: false });
        }
        else {
            this.setState({ editLeistungsspektrum: true });
        }
    }

    editZugangsinformationen = () => {
        if (this.state.editZugangsinformationen) {
            DatabaseService.vet.profile
                .update({ vetID: this.props.doctor.id, zugangsinformationen: this.state.zugangsinformationen })
                .then(() => {
                    this.props.onChange({
                        zugangsinformationen: this.state.zugangsinformationen
                    });
                })
                .finally(this.setState({ editZugangsinformationen: false }));
        }
        else {
            this.setState({ editZugangsinformationen: true });
        }
    }

    editBehandlerprofil = () => {
        if (this.state.editBehandlerprofil) {
            DatabaseService.vet.profile
                .update({
                    vetID: this.props.doctor.id, behandlerprofil: this.state.behandlerprofil
                })
                .then(() => {
                    this.props.onChange({
                        behandlerprofil: this.state.behandlerprofil
                    });
                })
                .finally(this.setState({ editBehandlerprofil: false }));
        }
        else {
            this.setState({ editBehandlerprofil: true });
        }
    }

    // TODO: Jedes eingegebene Zeichen löst ein Request ans Backend aus
    editWebsite() {
        this.setState({ website: this.state.websiteNew });
        DatabaseService.vet.profile
            .update({ vetID: this.props.doctor.id, website: this.state.websiteNew })
            .then(() => {
                this.props.onChange({
                    website: this.state.websiteNew
                });
            })
    }

    onSaveOpeningsHours = (data) => {
        const openingsHours = data;
        // console.log("MeinProfil.js: onSaveOpeningsHours() openingsHours ", openingsHours);
        DatabaseService.clinic.profile
            .update({ clinicID: this.props.vetProfile.clinicID, oeffnungszeiten: JSON.stringify(openingsHours) })
            .then(() => {
                this.props.onChange({
                    oeffnungszeiten: openingsHours
                });
            })
    }

    renderLeistungsspektrum() {
        if (this.state.vetprofile.leistungsspektrum != null) {
            var leistungssp = this.parseObj(this.state.vetprofile.leistungsspektrum);

            if (leistungssp != "") {
                return leistungssp.map( (element, idx) =>
                    <div>
                        {element}
                        {this.state.editLeistungsspektrum && this.state.leistungsspektrumIdx == idx ?
                            <Input placeholder="Leistungsspektrum" value={this.state.leistungsspektrumDesc} onChange={({ target: { value } }) => this.setState({ leistungsspektrumDesc: value })} /> :
                            <span style={{ color: "grey", marginTop: 7 }}></span>}
                            <IconButton style={{ color: "#25D2AA" }} onClick={() => this.editLeistungsspektrum(idx)}>
                                {this.state.editLeistungsspektrum && this.state.leistungsspektrumIdx == idx ?
                                        <DoneIcon /> :
                                        <EditIcon />
                                }
                            </IconButton>
                    </div>
                )
            }
        }

        return "";
    }

    render() {
        return (
            <>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch", width: "85%" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: 25, margin: 30 }}>
                        <div style={{ height: "100px", width: "100px" }}>
                            <Avatar style={{ height: "100px", width: "100px" }} imageUrl={this.props.vetProfile.profilePic} mode="profil" />
                        </div>
                        <div style={{ display: "flex", marginLeft: 20, flexDirection: "column" }}>
                            <span style={{ fontWeight: "bold", color: "#25D2AA", fontSize: 20 }}>{this.props.doctor.titel + " " + this.props.doctor.vorname + " " + this.props.doctor.nachname}</span>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <span style={{ fontWeight: "bold", marginTop: 7 }}>{this.props.vetProfile.ausbildung} für {this.props.vetProfile.fachgebiet}</span>
                                <IconButton style={{ color: "#25D2AA" }} onClick={this.showModal}>
                                    {this.state.editModal ?
                                        <DoneIcon fontSize="large" /> :
                                        <EditIcon />
                                    }
                                </IconButton>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                {this.state.editAddress ?
                                    <Input placeholder="Addresse" value={this.state.address} onChange={({ target: { value } }) => this.setState({ address: value })} /> :
                                    <span style={{ color: "grey", width: "500px", marginTop: 14 }}>{this.state.address}</span>
                                }
                                <IconButton style={{ color: "#25D2AA" }} onClick={() => this.editAddress()}>
                                    {this.state.editAddress ?
                                        <DoneIcon /> :
                                        <EditIcon />
                                    }
                                </IconButton>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                {this.state.editAbout ?
                                    <Input placeholder="Über mich" value={this.state.about} onChange={({ target: { value } }) => this.setState({ about: value })} /> :
                                    <span style={{ color: "grey", width: "500px", marginTop: 14 }}>{this.state.about}</span>
                                }
                                <IconButton style={{ color: "#25D2AA" }} onClick={() => this.editAbout()}>
                                    {this.state.editAbout ?
                                        <DoneIcon /> :
                                        <EditIcon />
                                    }
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <IconButton style={{ color: "black", alignSelf: "flex-end" }} onClick={() => this.showLeistunsspektrum()}>
                        {this.state.showLeistunsspektrum ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                    {this.state.showLeistunsspektrum &&
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 25, margin: 30, marginTop: 0 }}>
                            <span style={{ fontWeight: "bold", fontSize: 20 }}>Leistungsspektrum</span>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                                {this.renderLeistungsspektrum()}
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", borderRadius: "3px", backgroundColor: "#f4f1ea", padding: 5, margin: 10 }}>
                                    <AddIcon onClick={() => this.handleChangeSpektrum()}/>
                                    <Input placeholder="Hinzufügen" value={this.state.leistungsspektrumNew} onChange={({ target: { value } }) => this.setState({ leistungsspektrumNew: value })} bordered={false} onPressEnter={() => this.handleChangeSpektrum()} />
                                </div>
                            </div>
                        </div>
                    }
                    <Divider />
                    <IconButton style={{ color: "black", alignSelf: "flex-end" }} onClick={() => this.showZugangsinformationen()}>
                        {this.state.showZugangsinformationen ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                    {this.state.showZugangsinformationen &&
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: 25, margin: 30 }}>
                            <span style={{ fontWeight: "bold", fontSize: 20 }}>Addresse und Zugangsinformation</span>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 15 }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                    <span style={{ fontWeight: "bold", marginTop: 20 }}>Hundearzt Praxis Pietsch</span>
                                    <span style={{ color: "grey", marginTop: 7 }}>Herrmann-Hesse-Straße 4,</span>
                                    <span style={{ color: "grey", marginTop: 7 }}>10437 Berlin</span>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginLeft: 70 }}>
                                    <span style={{ fontWeight: "bold", marginTop: 20 }}>Zugangsinformationen</span>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 15 }}>
                                        {this.state.editZugangsinformationen ?
                                            <TextField
                                                value={this.state.zugangsinformationen}
                                                onChange={({ target: { value } }) => this.setState({ zugangsinformationen: value })}
                                                multiline
                                                variant="outlined"
                                            /> :
                                            <>
                                                <span style={{ color: "grey", marginTop: 7 }}>{this.state.zugangsinformationen}</span>
                                            </>}
                                        <IconButton style={{ color: "#25D2AA" }} onClick={() => this.editZugangsinformationen()}>
                                            {this.state.editZugangsinformationen ?
                                                <DoneIcon fontSize="large" /> :
                                                <EditIcon />
                                            }
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <Divider />
                    <IconButton style={{ color: "black", alignSelf: "flex-end" }} onClick={() => this.showBehandlerprofil()}>
                        {this.state.showBehandlerprofil ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                    {this.state.showBehandlerprofil &&
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 25, margin: 30, marginTop: 0 }}>
                            <span style={{ fontWeight: "bold", fontSize: 20 }}>Behandlerprofil</span>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "left" }}>
                                {this.state.editBehandlerprofil ?
                                    <Input placeholder="Behandlerprofil" value={this.state.behandlerprofil} onChange={({ target: { value } }) => this.setState({ behandlerprofil: value })} />
                                    :
                                    <span style={{ marginTop: 10 }}>{this.state.behandlerprofil} </span>
                                }
                                <IconButton style={{ color: "#25D2AA" }} onClick={() => this.editBehandlerprofil()}>
                                    {this.state.editBehandlerprofil ?
                                        <DoneIcon /> :
                                        <EditIcon />
                                    }
                                </IconButton>
                            </div>
                            <div style={{ height: "15px" }} />
                            <ImageUpload doctor={this.props.doctor} vetprofile={this.props.vetProfile} />
                            <div style={{ display: "flex", flexDirection: this.state.editSprachen ? "column" : "row", marginTop: 15 }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <span style={{ fontWeight: "bold", marginTop: 7 }}>Gesprochene Sprachen</span>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "500px" }}>
                                        {this.state.editSprachen ?
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                {this.state.sprachen.map((elem, index) => {
                                                    return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", borderRadius: "3px", backgroundColor: "#f4f1ea", padding: 5, margin: 10 }}>
                                                        <span style={{ fontSize: 14 }}>{elem}</span>
                                                        <IconButton onClick={() => {
                                                            var sprachenArr = this.state.sprachen;
                                                            sprachenArr.splice(index, 1);
                                                            this.setState({ sprachen: sprachenArr })
                                                        }}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </div>
                                                })
                                                }
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", borderRadius: "3px", backgroundColor: "#f4f1ea", padding: 5, margin: 10 }}>
                                                    <AddIcon />
                                                    <Input placeholder="Hinzufügen" value={this.state.sprachenNew} onChange={({ target: { value } }) => this.setState({ sprachenNew: value })} bordered={false} onPressEnter={({ target: { value } }) => this.editSprachen()} />
                                                </div>
                                            </div>
                                            :
                                            <span style={{ marginTop: 7 }}>{this.state.sprachenString}</span>
                                        }
                                        <IconButton style={{ color: "#25D2AA" }} onClick={() => this.editSprachen()}>
                                            {this.state.editSprachen ?
                                                <DoneIcon fontSize="large" /> :
                                                <EditIcon />
                                            }
                                        </IconButton>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <span style={{ fontWeight: "bold", marginTop: 7 }}>Website</span>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Input style={{ marginTop: 15 }} addonBefore="http://" placeholder="beispiel.de" value={this.state.websiteNew} onChange={({ target: { value } }) => this.setState({ websiteNew: value })} />
                                        <IconButton style={{ alignContent: "top", color: "#25D2AA" }} onClick={() => this.editWebsite()}>
                                                <DoneIcon fontSize="large" />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </div>
                <Dialog onClose={this.handleCancel} aria-labelledby="customized-dialog-title" open={this.state.editModal} maxWidth="lg">
                    <DialogTitle id="customized-dialog-title" onClose={this.handleCancel}>
                        Bearbeiten
                    </DialogTitle>
                    <DialogContent>
                        <Form
                            layout="vertical"
                            onFinish={this.handleOk}
                            onFinishFailed={this.onFinishFailed}
                            requiredMark={false}
                            initialValues={{
                                ausbildung: this.props.vetProfile.ausbildung,
                                fachgebiet: this.props.vetProfile.fachgebiet
                            }}
                            style={{ marginLeft: 30, marginRight: 30, width: "350px" }}
                        >
                            <Form.Item
                                label="Ausbildung"
                                name="ausbildung"
                                rules={[{ required: true, message: 'Bitte eine Auswahl treffen!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Fachgebiet"
                                name="fachgebiet"
                                rules={[{ required: true, message: 'Bitte Fachgebiet angeben!' }]}
                            >
                                <Input />
                            </Form.Item>


                            <Form.Item >
                                <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "40px", fontSize: 18 }} shape="round" htmlType="submit" block>
                                    Speichern
                                </Button>
                            </Form.Item>
                        </Form>
                    </DialogContent>
                </Dialog>
            </>
        );
    }


}

export default MeinProfil
