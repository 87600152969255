import React from "react";
import Logo from "./assets/HelloVetLogoPro.png"
import { Layout, Button } from 'antd';
import PhoneIcon from '@material-ui/icons/Phone';
import SoFunktioniertsHeader from './assets/Sofunktionierts.png'
import ErstelleDeinKonto from './assets/ErstelleDeinKonto.png'
import Anleitung from './assets/anleitung.png'
import Path from './assets/Path7.png'
import Dot from './assets/Dot.png'
import Dot2 from './assets/Dot2.png'
import Background from "./assets/Background.png"
import Step1 from "./assets/step1-backg.png"
import Step2 from "./assets/step2-backg.png"
import Step3 from "./assets/step3-backg.png"
import LineFooter from "./assets/LineFooter.png"
import {  Link } from "react-router-dom";

const { Header, Content, Footer } = Layout;

function ValidationRequired(){
  return (
  <Layout className="layout">
    <Header style={{ backgroundColor: 'transparent' }}>
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
    <Link to="/">
  <img src={Logo} style={{ width: "200px", height: "55px" }} />
  </Link>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", fontSize: 17 }}>
      <Link to="/manual">
            <span style={{ paddingRight: 24, color:"black",fontSize:20, fontFamily: "WorkSans" }}>So funktioniert's</span>
        </Link>
        <Link to="/qa">
        <span style={{ paddingRight: 24,color:"black", fontSize:20, fontFamily: "WorkSans" }}>Häufige Fragen</span>
        </Link>
        <PhoneIcon style={{ paddingRight: 5, marginTop: 5, fontSize: 22 }} />
        <span style={{ paddingRight: 24, fontSize:20, fontFamily: "WorkSans" }}>089 12 34 56 78</span>
      </div>
    </div>
    </Header>
    <Content >
      <img src={Path} style={{position:"absolute", left:"0%", width:"100%", top:"100px", zIndex:0}}/>
      <img src={Dot} style={{position:"absolute", right:"20%", top:"300px", zIndex:0}}/>
      <img src={Dot2} style={{position:"absolute", right:"0%", top:"60px", zIndex:0}}/>
      <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", height: "100vh"}}>
        <div style={{flex: 1, marginTop:200, display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center"}}>
          <span style={{fontSize:70, fontFamily:"Recoleta"}} >Bitte bestätigen sie ihr Konto</span>
          <span style={{marginTop:20, fontSize:24, fontFamily:"WorkSans"}} >Wir haben ihnen eine E-Mail zur</span>
          <span style={{fontSize:24, fontFamily:"WorkSans"}} >Bestätigung ihres Accounts gesendet</span>
        </div>

        <div style={{flex: 1, width: "60%", display:"flex", flexDirection:"row", alignItems:"flex-end"}}>
          <div style={{flex: 1 }}>
            <img src={Logo} style={{width:"200px", height: "55px"}}/>
          </div>
          <div style={{flex: 1, display:"flex", flexDirection: "column" ,justifyContent: "flex-start", alignItems:"flex-start"}}>
            <span className="footer-headings" >Info</span>
            <span className="footer-elements" >Über uns</span>
            <span className="footer-elements" >Presse</span>
          </div>
          <div style={{flex: 1, display:"flex", flexDirection: "column" ,justifyContent: "flex-start", alignItems:"flex-start"}}>
            <span className="footer-headings" >Produkt</span>
            <span className="footer-elements" >So funktiononiert's</span>
            <span className="footer-elements" >Häufige Fragen</span>
            <span className="footer-elements" >Hello Vet Patenient</span>
          </div>
          <div style={{flex: 1, display:"flex", flexDirection: "column" ,justifyContent: "flex-start", alignItems:"flex-start"}}>
            <span className="footer-headings" >Rechtliches</span>
            <span className="footer-elements" >Datenschutz</span>
            <span className="footer-elements" >Nutzungsbedingenungen</span>
            <span className="footer-elements" >Impressum</span>
          </div>
        </div>
        <span style={{marginTop:90, marginBottom: 50, fontSize:16, fontFamily:"WorkSans"}} >Hello Vet GmbH, Alle Rechte vorbehalten.</span>
      </div>
    </Content>
  </Layout>
  );

}

export default ValidationRequired