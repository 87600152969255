import React from "react"
import { withStyles } from '@material-ui/core/styles';
import { List, Card, Avatar, Tooltip, Descriptions, Button, Modal, Input, Select } from 'antd';
import { CopyOutlined, CheckOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import DatabaseService from "../../services/database.service"
import { v4 as uuidv4 } from 'uuid';
import store from 'store'
import { Redirect, useParams } from "react-router-dom";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Profile from "../../Profile_Pic.png"
import Axios from "axios"
// import RocketChatAPI from "@rocket.chat/sdk/lib/api/RocketChat"
import whereAmI from "../../whereAmI";
// https://github.com/prettymuchbryce/http-status-codes
import { StatusCodes as HttpStatus } from 'http-status-codes';
import 'dotenv/config';
import { ExceptionMap } from "antd/lib/result";

const { Option } = Select;
const { confirm } = Modal;


function showConfirm() {
    confirm({
        title: 'Einladungen versandt',
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        onOk() {
            console.log('OK');
        },
        onCancel() {
            console.log('Abbrechen');
        },
    });
}

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, 0)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderBottom: '1px solid rgba(0, 0, 0, 0)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);



class Conferencing extends React.Component {

    constructor(props) {
        super(props)
        console.log("Conferencing.js: this.props ", this.props);

        this.state = {
            startMeeting: false,
            data: [],
            meetingName: "",
            modalVisible: false,
            password: "",
            expanded: "panel",
            conferenceName: "",
            currentlyEditing: -1,
            RocketChat: this.props.rocketRestApi,
            connected: false,
            currentEmail: ""
        }
        this.loadConferenceHistory();
    }


    loadConferenceHistory() {
        DatabaseService.vet.meeting
            .read(store.get('usrId'))
            .then(response => {
                console.log("Conferencing.js: vet.meeting.read() response ", response);
                if (response.status === HttpStatus.OK) {
                    var tempConf = response.data.records.map((elem, index) => {
                        elem.vetID = [{ vetID: elem.vetID, name: elem.vorname + " " + elem.nachname, fachgebiet: elem.fachrichtung }];
                        elem.edit = false;
                        elem.index = index
                        return elem
                    });
                    const conferences = tempConf
                        .filter((conf, index, self) => {
                            const result = self.findIndex((t) => (
                                t.meetingID === conf.meetingID
                            ));
                            if (index === result) {
                                return true;
                            }
                            else {
                                self[result].vetID.push(conf.vetID[0]);
                                return false;
                            }
                        })
                        .sort((a, b) => {
                            return a.lastUsed < b.lastUsed;
                        });
                    console.log("Conferencing.js: vet.meeting.read() conferences ", conferences);
                    this.setState({
                        data: conferences
                    });
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    handleChange = panel => {
        if (this.state.expanded == panel)
            this.setState({ expanded: "panel" });
        else
            this.setState({ expanded: panel });
    }

    onChangeName = e => {
        const { value } = e.target;
        this.setState({ conferenceName: value });
    };

    onChange = e => {
        const { value } = e.target;
        this.setState({ password: value });
    };

    onChangeInvite = e => {
        const { value } = e.target;
        this.setState({ currentEmail: value });
    };

    sendInvitation = (sessionLink) => {
        Axios.post(process.env.REACT_APP_MAILER_SERVICE_URL + "/invite", { // "https://hv-mailer-service.herokuapp.com/invite"
            mail: this.state.currentEmail,
            link: sessionLink
        })
            .catch(e => {
                console.log(e);
            });
    }

    handleStartNewConference = () => {
        const newMeetingName = uuidv4();
        const meetingData = {
            meetingName: newMeetingName,
            label: this.state.conferenceName,
            lastUsed: Date.now().valueOf()
        }
        const vetMeeting = {
            meetingID: newMeetingName,
            vetID: store.get('usrId')
        }
        this.setState({ meetingName: newMeetingName })

        // this.state.RocketChat.request('POST', "channels.create", { name: newMeetingName });
        this.state.RocketChat.post("channels.create", { name: newMeetingName })
            .then(response => {
                DatabaseService.meeting
                    .create(meetingData)
                    .then(response => {
                        const createdConference = response.data
                        console.log("Conferencing.js: meeting.create() response ", response);
                        if (response.status === HttpStatus.CREATED) {
                            DatabaseService.vet.meeting
                                .create(vetMeeting)
                                .then(response => {
                                    console.log("Conferencing.js: vet.meeting.create() response ", response);
                                    if (response.status === HttpStatus.CREATED) {
                                        this.setState({
                                            meetingCreated: true
                                        })
                                        this.loadConferenceHistory();
                                        this.props.closeModal();
                                    } else {
                                        throw "rollback conference"
                                    }
                                })
                                .catch(async e => {
                                    console.log("Conferencing.js: vet.meeting.create() e ", e);
                                    await DatabaseService.meeting.delete(meetingData.meetingName);
                                });
                        } else {
                            throw "rollback conference"
                        }
                    })
                    .catch(e => {
                        console.log("Conferencing.js: meeting.create() e ", e);
                    });
            })
            .catch(e => {
                console.log("Conferencing.js: post 'channels.create' e ", e);
            })


    }

    render() {
        return (
            <>
                <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: 25, overflow: "auto", width: "90%", height: "90%" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 30 }}>
                        <span style={{ fontSize: 22, fontWeight: "bold" }}>Konferenzräume [Conferencing.js]</span>
                        <Select defaultValue="heute" style={{ width: 120 }} onChange={this.handleDayChange}>
                            <Option value="heute">Heute</Option>
                        </Select>
                    </div>
                    <List
                        loading={this.state.loading}
                        itemLayout="horizontal"
                        dataSource={this.state.data}

                        renderItem={item => (
                            <List.Item>

                                <Accordion square expanded={this.state.expanded === item.index} onChange={() => this.handleChange(item.index)} style={{ width: "100%" }}>
                                    <AccordionSummary id={item.index} style={{ width: "100%" }}>
                                        <FormControl
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            style={{ width: "100%" }}
                                        >
                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", fontSize: 18 }}>
                                                {this.state.currentlyEditing === item.meetingName ? <Input value={item.label} placeholder="Konferenz name eingeben" /> :
                                                    <>
                                                        <span style={{ fontWeight: "bold", width: "25%" }}>{item.label}</span>
                                                        <Button style={{ backgroundColor: "#25D2AA", color: "white", marginLeft: 20, marginRight: 20 }} shape="round" onClick={() => window.open(whereAmI() + "/konferenz/" + item.meetingName, "_blank")} >Starten</Button>
                                                    </>
                                                }
                                                <Button icon={<CopyOutlined />} onClick={() => { navigator.clipboard.writeText(whereAmI() + "/konferenz/" + item.meetingName) }} style={{ borderColor: "black", color: "black", marginLeft: 20, marginRight: 20 }} ghost shape="round">Link kopieren</Button>
                                                <div />
                                                <span style={{ marginLeft: 20, marginRight: 20 }}>09.01.2021</span>
                                                <Button style={{ backgroundColor: "#25D2AA", color: "white", marginLeft: 20, marginRight: 20 }} shape="round" onClick={() => this.handleChange(item.index)} >Teilnehmer</Button>
                                                <Button style={{ backgroundColor: "#25D2AA", color: "white", marginLeft: 20, marginRight: 20 }} shape="round" onClick={() => this.state.currentlyEditing === item.meetingName ? this.setState({ currentlyEditing: -1 }) : this.setState({ currentlyEditing: item.meetingName })} >Bearbeiten</Button>
                                            </div>
                                        </FormControl>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style={{ display: "flex", flexDirection: "row", overflow: "auto", marginRight: 10 }}>
                                            {item.vetID.map(elem =>
                                                <Paper key={'conference_' + item.id} elevation={3} style={{ minWidth: "250px", display: "flex", flexDirection: "column", justifyContent: "space-around", padding: 25, margin: 10 }}>
                                                    <Avatar size={64} src={Profile} />
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <span style={{ fontWeight: "bold", color: "#25D2AA", marginTop: 10 }}>{elem.name}</span>
                                                        <span style={{ fontWeight: "bold", marginTop: 7 }}>Facharzt für {elem.fachgebiet}</span>
                                                        <span style={{ color: "grey", marginTop: 7 }}>Herrmann-Hesse-Straße 4, 10437 Berlin</span>
                                                    </div>
                                                </Paper>)
                                            }
                                            <Paper elevation={3} style={{ minWidth: "250px", display: "flex", flexDirection: "column", justifyContent: "space-around", padding: 25, margin: 10 }}>
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center", height: "100%" }}>
                                                    <span style={{ fontWeight: "bold", marginTop: 7 }}>Benutzer einladen</span>
                                                    <span style={{ color: "grey", marginTop: 7 }}>Addressen mit , separiert eingeben </span>
                                                    <span style={{ color: "grey" }}>um mehrere Teilnehmer einzuladen</span>
                                                    <Input placeholder="E-Mail" onChange={this.onChangeInvite} />
                                                    <Button style={{ backgroundColor: "#25D2AA", color: "white" }} shape="circle" icon={<CheckOutlined />} onClick={() => { showConfirm(); this.sendInvitation(whereAmI() + "/konferenz/" + item.meetingName); }} />
                                                </div>
                                            </Paper>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </List.Item>
                        )}
                    />
                </Paper>
                <Modal title="Konferenz erstellen" visible={this.props.buttonClicked}
                    cancelButtonProps={{ size: "large", style: { borderColor: "black", color: "black" }, shape: "round" }}
                    okButtonProps={{ size: "large", style: { backgroundColor: "#25D2AA", color: "white" }, shape: "round" }}
                    okText="Erstellen" cancelText="Abbrechen"
                    onOk={() => this.handleStartNewConference()}
                    onCancel={() => this.props.closeModal()}
                >
                    <Input placeholder="Konferenz Name" onChange={this.onChangeName} />
                </Modal>
            </>
        );

        return (
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Button type="primary" style={{ marginBottom: 40 }} size="large" onClick={() => this.setState({ modalVisible: true })}>Neue Konferenz erstellen</Button>
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 4,
                        lg: 4,
                        xl: 6,
                        xxl: 3,
                    }}
                    dataSource={this.state.data}
                    renderItem={item => (
                        <List.Item>
                            <Card hoverable style={{ width: "400px" }} title={<div>
                                <Avatar.Group>
                                    {[1, 2, 3, 4].map(item => {
                                        return (<Tooltip title={<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                                            <div style={{ height: "10px" }} />
                                            <span>Dominik Lettenbichler</span>
                                        </div>} placement="top">
                                            <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                                        </Tooltip>)
                                    })}
                                </Avatar.Group>
                            </div>
                            }
                            >
                                <Descriptions bordered>
                                    <Descriptions.Item label="Datum">{new Date(item.lastUsed).toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Descriptions.Item>
                                </Descriptions>
                            </Card>
                        </List.Item>
                    )}
                />

            </div>
        );
    }

}

export default Conferencing