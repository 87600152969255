import React from "react"
import Remote from "./Remote"
import Local from "./Local"
import { Divider } from "antd"
import Grid from '@material-ui/core/Grid';
import ChatIcon from '@material-ui/icons/Chat';
import _ from 'lodash'
import CameraSelection from "./video-selection/CameraSelection"
import AudioSettings from "./audio-selection/AudioSettingsContent"
import MicIcon from '@material-ui/icons/Mic';
import { motion, AnimateSharedLayout } from "framer-motion"
import SettingsIcon from '@material-ui/icons/Settings';
import VideocamIcon from '@material-ui/icons/Videocam';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RGL, { WidthProvider } from "react-grid-layout";
import CallEndIcon from '@material-ui/icons/CallEnd';
import UploadService from "../../../services/upload.service"
import Markers from "./MarkerCard"
import DocumentationCard from "./DocumentationCard"
import Messages from "../../chat/Messages"
import "./CallComp.css"
import 'dotenv/config';


const ReactGridLayout = WidthProvider(RGL);

class ConferenceComp extends React.Component {
    constructor(props) {
        super(props)
        console.log("ConferenceComp.js: this.props ", this.props);

        this.state = {
            //serverURL: process.env.REACT_APP_SERVER_URL, //'callavet-conferencing.de',
            serverURL: process.env.REACT_APP_CONFERENCE_AUTHORITY,
            roomId: 'general',
            connected: false,
            defaultMicId: '',
            defaultVideoId: '',
            defaultSpeakerId: '',
            deviceList: [],
            loaded: false,
            activeConnection: null,
            activeRoom: null,
            remoteTracks: [],
            currentSpeakerId: -1,
            sessionID: null,
            isRecording: false,
            recordingStart: null,
            markers: [],
            desktopSharingEnabled: false,
            hoveringAudio: false,
            hoveringVideo: false,
            micSettings: false,
            videoSettings: false,
            audioOutputList: [],
            audioInputList: [],
            videoInputList: [],
            localVideoWidth: 6
        }
    }

    componentDidMount() {
        const audioOutput = window.JitsiMeetJS.mediaDevices.getAudioOutputDevice();
        window.JitsiMeetJS.mediaDevices.enumerateDevices((devices) => {
            let newDeviceList = [{ name: "Audio Output", id: audioOutput, type: 'audiooutput' }]
            let audioOutputList = [];
            let audioInputList = [];
            let videoInputList = [];
            for (let device of devices) {
                // if (device.deviceId !== 'default' && device.deviceId !== 'communications') {
                newDeviceList.push({ name: device.label, id: device.deviceId, type: device.kind });

                if (device.kind === 'videoinput')
                    videoInputList.push({ deviceId: device.deviceId, label: device.label });

                if (device.kind === 'audiooutput')
                    audioOutputList.push({ deviceId: device.deviceId, label: device.label });

                if (device.kind === 'audioinput')
                    audioInputList.push({ deviceId: device.deviceId, label: device.label });
                // }
            }
            let micId = (_.find(newDeviceList, { type: 'audioinput' }) || {}).id || 'none'
            let videoId = (_.find(newDeviceList, { type: 'videoinput' }) || {}).id || 'none'
            let speakerId = audioOutput || 'none'
            this.setState({
                deviceList: newDeviceList,
                defaultMicId: micId,
                defaultVideoId: videoId,
                defaultSpeakerId: speakerId,
                loaded: true,
                tracksUpdated: 0,
                audioOutputList: audioOutputList,
                audioInputList: audioInputList,
                videoInputList: videoInputList
            }, () => {
                this.setState({ dataLoaded: true, roomId: this.props.roomName }, () => {
                    this.connect()
                });
            });
        })
    }

    componentWillUnmount() {
        try {
            if (this.state.activeRoom) {
                this.state.activeRoom.leave()
                    .then(() => {
                        if (this.state.activeConnection) {
                            try {
                                this.state.activeConnection.disconnect();
                            }
                            catch (e) {
                                console.log(e)
                            }
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        if (this.state.activeConnection) {
                            try {
                                this.state.activeConnection.disconnect();
                            }
                            catch (e) {
                                console.log(e)
                            }
                        }
                    });
            }
        }
        catch (e) {
            console.log(e)
        }

    }

    onRoomTrackAdded = (track) => {
        if (track.isLocal() === true) {
            return
        }

        let trackInfo = {
            id: track.getId(),
            type: track.getType(),
            track: track
        };

        const tracks = this.state.remoteTracks;
        if (tracks.filter(e => e.key === track.getParticipantId()).length > 0) {
            _.find(tracks, { "key": track.getParticipantId() }).value.push(trackInfo);
            this.setState({ remoteTracks: tracks }, () => this.setState({ tracksUpdated: this.state.tracksUpdated + 1 }));
        }
        else {
            tracks.push({ "key": track.getParticipantId(), "value": [trackInfo], "width": 6 });
            this.setState({ remoteTracks: tracks });
        }

    }

    onRoomTrackRemoved = (track) => {
        if (track.isLocal()) {
            return;
        }
        const tracks = this.state.remoteTracks;
        const participant = _.find(tracks, { "key": track.getParticipantId() })
        if (participant) {
            tracks.splice(participant.value.findIndex(e => e.id === track.getId()), 1);
            this.setState({ remoteTracks: tracks });
        }
    }

    trackAudioLevelChanged = (participantId, audioLevel) => {
        if (audioLevel > 0.6) {
            this.setState({ currentSpeakerId: participantId })
        }
    }

    startRecording = () => {
        this.state.activeRoom.startRecording({ mode: "file" })
            .then(session => {
                this.setState({ sessionID: session.getID(), isRecording: true, recordingStart: new Date() });
            })
            .catch(e => {
                console.log(e);
            });
    }

    stopRecording = () => {
        this.setState({ isRecording: false });
        this.state.activeRoom.stopRecording(this.state.sessionID);
        this.submitMarkers();
    }

    submitMarkers = () => {
        var metadata = ";FFMETADATA1\ntitle=Jitsi Recording\n\n"
        this.state.markers.forEach((item) => {
            metadata = metadata + "[CHAPTER]\nTIMEBASE=1/1000\nSTART=" + item.time + "\nEND=" + (item.time + 3000) + "\ntitle=" + item.notice + "\n\n"
        });
        metadata = metadata + "[STREAM]\ntitle=callavet"
        UploadService
            .submitMarkers({ sessionId: this.state.roomId, markers: metadata })
            .catch(e => {
                console.log(e);
            });
    };

    onConnectionFailed = (a, b, c, d) => {
        // Show error message
    }

    onConnectionDisconnect = () => {
        //return to dashboard
    }

    onConnectionSuccess = () => {
        const { roomId } = this.state;
        console.log("ConferenceComp.js: onConnectionSuccess(): ", roomId);

        try {
            this.setState({
                connected: true,
                activeRoom: this.state.activeConnection.initJitsiConference(roomId, {
                    openBridgeChannel: true,
                })
            }, () => {
                this.state.activeRoom.addEventListener(window.JitsiMeetJS.events.conference.TRACK_ADDED, this.onRoomTrackAdded)
                this.state.activeRoom.addEventListener(window.JitsiMeetJS.events.conference.TRACK_REMOVED, this.onRoomTrackRemoved)
                this.state.activeRoom.addEventListener(window.JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED, this.trackAudioLevelChanged)
                this.state.activeRoom.setDisplayName(this.props.name)
                //Enter password in this Method if password is needed
                this.state.activeRoom.join()
            });
        } catch (error) {
            this.setState({
                status: 'closed',
                lastError: error.message
            })
        }

        //this.setState({ connected: true });
        console.log("STATE SETTED: " + this.state.connected);
    }

    /*
    onConnectionSuccess = () => {
        console.log("CONNECTION SUCCESS");
        const { roomId } = this.state;
        try {
            this.setState({ connected: true });

            this.setState({
                connected: true,
                activeRoom: this.state.activeConnection.initJitsiConference(roomId, {
                    openBridgeChannel: true
                })
            }, () => {
                this.state.activeRoom.addEventListener(window.JitsiMeetJS.events.conference.TRACK_ADDED, this.onRoomTrackAdded)
                this.state.activeRoom.addEventListener(window.JitsiMeetJS.events.conference.TRACK_REMOVED, this.onRoomTrackRemoved)
                this.state.activeRoom.addEventListener(window.JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED, this.trackAudioLevelChanged)
                this.state.activeRoom.setDisplayName(this.props.name)
                //Enter password in this Method if password is needed
                this.state.activeRoom.join()
            });

            console("FULLY CONNECTED");
        } catch (error) {
            console("ERROR SETTING STATE");
            this.setState({
                status: 'closed',
                lastError: error.message
            })

        }
    }
    */
    connect = () => {
        const { roomId, serverURL } = this.state;
        console.log("ConferenceComp.js, connect() this.state: ", this.state);
        let jitsiConfig = {
            hosts: {
                anonymousdomain: "meet.jitsi",
                authdomain: "auth.meet.jitsi",
                domain: "meet.jitsi",
                muc: "muc.meet.jitsi" //muc: `muc.${serverURL}` // FIXME: use XEP-0030
            },
            serviceUrl: `wss://${serverURL}/xmpp-websocket?room=${roomId}`, //`wss://${serverURL}/xmpp-websocket?room=${roomId}`,
            clientNode: `https://${serverURL}`
        }
        console.log("ConferenceComp.js, connect() jitsiConfig: ", jitsiConfig);
        // const jm = window.JitsiMeetJS;
        // console.log("CallComp.js, connect() jm: ", jm);
        // // https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-ljm-api#jitsiconnection
        // jm.setLogLevel(jm.logLevels.TRACE);

        this.setState({
            activeConnection: new window.JitsiMeetJS.JitsiConnection(null, null, jitsiConfig)
        }, () => {
            console.log("ConferenceComp.js, connect() this.state.activeConnection: ", this.state.activeConnection);
            this.state.activeConnection.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, this.onConnectionSuccess)
            this.state.activeConnection.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_FAILED, this.onConnectionFailed)
            this.state.activeConnection.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, this.onConnectionDisconnect)
            this.state.activeConnection.connect()
        });
    }
/*
    connect = () => {
        const { roomId, serverURL } = this.state
        this.setState({
            activeConnection: new window.JitsiMeetJS.JitsiConnection(null, null, {
                hosts: {
                    domain: serverURL,
                    muc: `conference.${serverURL}` // FIXME: use XEP-0030
                },
                serviceUrl: `wss://${serverURL}/xmpp-websocket?room=${roomId}`,
                clientNode: `https://${serverURL}`
            })
        }, () => {
            this.state.activeConnection.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, this.onConnectionSuccess)
            this.state.activeConnection.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_FAILED, this.onConnectionFailed)
            this.state.activeConnection.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, this.onConnectionDisconnect)
            this.state.activeConnection.connect()
        });
    }
*/
    render() {
        console.log("Ich bin's");
        console.log("CONNECTED? " + this.state.connected);
        console.log("LOADED? " + this.state.loaded);

        if (!this.state.loaded) {
            return <div />
        }

        /*
        return (
            <span>Dies ist ein Testtext</span>
        );
        */

        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Grid container spacing={3} style={{ width: "100%" }}>
                    {this.state.connected ?
                        <Grid item xs={6}>
                            <Local deviceList={this.state.deviceList} defaultMicId={this.state.defaultMicId} defaultVideoId={this.state.defaultVideoId} activeRoom={this.state.activeRoom} />
                        </Grid>
                        :
                        null}
                    {this.state.remoteTracks.map(({ key, value }) => {
                        const participant = this.state.activeRoom.getParticipantById(key);
                        return (
                            <Grid item xs={6}>
                                <Remote participant={participant} participantId={key} kickParticipant={this.kickParticipant} tracks={value} currentSpeaker={this.state.currentSpeakerId} tracksUpdated={this.state.tracksUpdated} />
                            </Grid>
                        );
                    })}
                    {this.state.isRecording && <Grid item xs={6}>
                        <Markers recordingStartTime={this.state.recordingStart} markers={this.state.markers} setMarkers={(val) => this.setState({ markers: val })} />
                    </Grid>}
                    {this.state.chatSelected &&
                        <Grid item xs={6}>
                            <Messages rocketChat={this.state.RocketChat} restAPI={this.state.RestAPI} selectedChat={this.state.selectedChat} />
                        </Grid>}
                </Grid>
                <div style={{ display: "flex", justifyContent: "center", marginTop: 20, marginBottom: 10 }}>
                    <AnimateSharedLayout>
                        <motion.div layout style={{ display: "flex", backgroundColor: "#25D2AA", borderRadius: 40, paddingLeft: 15, paddingRight: 15 }}>
                            <motion.div
                                layout
                                style={{ display: "flex", borderRadius: "500px", backgroundColor: "black", margin: 5, padding: 2 }}
                                onHoverStart={e => this.setState({ hoveringAudio: true })}
                                onHoverEnd={e => this.setState({ hoveringAudio: false })}>
                                <motion.div style={{ borderRadius: "50%", backgroundColor: "white" }} layout>
                                    <IconButton component="span">
                                        <MicIcon style={{ color: "black" }} />
                                    </IconButton>
                                </motion.div>
                                {this.state.hoveringAudio && (this.state.audioInputList.length > 1 || this.state.audioOutputList.length > 1) &&
                                    <IconButton component="span" onClick={() => this.setState({ micSettings: true })}>
                                        <SettingsIcon style={{ color: "white" }} />
                                    </IconButton>
                                }
                            </motion.div>
                            <IconButton color="primary" onClick={() => this.stopSession()}>
                                <CallEndIcon style={{ fontSize: 32, marginRight: 20 }} />
                            </IconButton>
                            <motion.div
                                layout
                                style={{ display: "flex", borderRadius: "500px", backgroundColor: "black", margin: 5, padding: 2 }}
                                onHoverStart={e => this.setState({ hoveringVideo: true })}
                                onHoverEnd={e => this.setState({ hoveringVideo: false })}>
                                <motion.div style={{ borderRadius: "50%", backgroundColor: "white" }} layout>
                                    <IconButton component="span">
                                        <VideocamIcon style={{ color: "black" }} />
                                    </IconButton>
                                </motion.div>
                                {this.state.hoveringVideo &&
                                    <IconButton component="span" onClick={() => this.setState({ videoSettings: true })}>
                                        <SettingsIcon style={{ color: "white" }} />
                                    </IconButton>
                                }
                            </motion.div>
                            {this.state.isRecording ?
                                <IconButton onClick={() => this.stopRecording(this.state.sessionStart)}>
                                    <FiberManualRecordIcon className="blob" />
                                </IconButton>
                                :
                                <IconButton onClick={() => this.startRecording()}>
                                    <FiberManualRecordIcon style={{ fontSize: 40, color: "red" }} />
                                </IconButton>
                            }
                        </motion.div>
                    </AnimateSharedLayout >
                </div>
                <Dialog onClose={() => this.setState({ micSettings: false })} open={this.state.micSettings}>
                    <AudioSettings
                        currentMicDeviceId={this.state.defaultMicId}
                        currentOutputDeviceId={this.state.defaultSpeakerId}
                        microphoneDevices={this.state.audioInputList}
                        outputDevices={this.state.audioOutputList}
                        setAudioInputDevice={(deviceID) => this.setState({ defaultMicId: deviceID })}
                        setAudioOutputDevice={(deviceID) => this.setState({ defaultSpeakerId: deviceID })} />
                </Dialog>
                <Dialog onClose={() => this.setState({ videoSettings: false })} open={this.state.videoSettings} maxWidth="xl">
                    <CameraSelection videoInputs={this.state.videoInputList} />
                </Dialog>
            </div>
        );
    }
}

export default ConferenceComp
