import React, { useState } from "react";
import Logo from "./assets/HelloVetLogo.png"
import CheckIcon from "./assets/CheckIcon.png"
import Background from "./assets/BackgroundLeft.png"
import Corner from "./assets/InfosCorner.png"
import { Form, Input, Button, Checkbox, Select } from 'antd';
import { Redirect } from "react-router-dom";
import store from 'store'
import isLoggedIn from '../is_logged_in';
import DatabaseService from "../services/database.service";

function Anmelden() {

    const [loginFailed, setLoginFailed] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const onFinish = values => {
        var data = {
            email: values.email,
            passwort: values.passwort,
        };

        DatabaseService.vet
            .checkAccount(data)
            .then(res => {
                if (!res.data.checkStatus) {
                    return setLoginFailed(true);
                }
                else {
                    store.set('loggedIn', true);
                    store.set('usrId', res.data.id);
                    store.set('redirectPage', "/vetDashboard");
                    setRedirect(true);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    if (redirect) {
        return (<Redirect to={store.get("redirectPage")} />);
    }
    /*
    if (isLoggedIn()) {
        return (<Redirect to={store.get("redirectPage")} />);
    }
    */
    var errorMsg;
    if (loginFailed) {
        errorMsg = (
            <p style={{ color: "red" }}>
                Falsche E-Mail oder Passwort
            </p>
        );
    }
    else {
        errorMsg = (null);
    }
    return (
        <div style={{ width: "100%", height: "100vh", display: "flex", flexDirection: "row" }}>
            <img src={Corner} style={{ position: "absolute", right: "0px", top: "0px", height: "15%", zIndex: 0 }} />
            <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundImage: `url(${Background})`, backgroundPosition: "left center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                <img src={Logo} style={{ width: "400px" }} />
                <div style={{ display: "flex", flexDirection: "column", width: "500px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                        <img src={CheckIcon} style={{ height: "80px", marginTop: 15 }} />
                        <span style={{ marginTop: 15, fontSize: 22, fontFamily: "WorkSans" }} >Bequem von Zuhause ohne Anfahrt und Wartezimmerstress für dein Tier</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                        <img src={CheckIcon} style={{ height: "80px", marginTop: 15 }} />
                        <span style={{ marginTop: 15, fontSize: 22, fontFamily: "WorkSans" }} >Beratung per Videochat durch erfahrene Tierärzte</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                        <img src={CheckIcon} style={{ height: "80px", marginTop: 15 }} />
                        <span style={{ marginTop: 15, fontSize: 22, fontFamily: "WorkSans" }} >Löst viele Probleme ohne weiteren Arztbesuch im Anschluss</span>
                    </div>
                </div>
            </div>
            <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <span style={{ fontSize: 26, fontFamily: "WorkSans", fontWeight: "bold" }} >Anmelden</span>
                <span style={{ marginBottom: 15, fontSize: 22, fontFamily: "WorkSans" }} >Melden sie sich bei Hello Vet an</span>
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    size="large"
                >
                    {errorMsg}
                    <Form.Item
                        label="E-Mail Addresse"
                        name="email"
                        rules={[{ required: true, message: 'Bitte E-Mail eingeben!' }]}
                    >
                        <Input placeholder="max.mustermann@net.de" />
                    </Form.Item>

                    <Form.Item
                        label="Passwort"
                        name="passwort"
                        rules={[{ required: true, message: 'Bitte Passwort eingeben!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item >
                        <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "45px", fontSize: 18 }} shape="round" htmlType="submit" block>
                            Jetzt anmelden
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default Anmelden
