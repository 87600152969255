import React from 'react';
import { Input, Form, Button } from 'antd';
import ImageUpload from "./ImageUpload"
import OpeningHours from "./OpeningHours"
import "./ProfileView.css"
import TextField from '@material-ui/core/TextField'
import Avatar from "./UploadAvatar"
import LineDiagramView from './LineDiagramView';

class ProfilePage extends React.Component {

    constructor(props){
        super(props);
        this.state={
            disabled:true,
            edited:false,
            about: ""
        }
    }

     onFinish = (values) => {
        console.log('Success:', values);
      };
    
      onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    render(){
        const {doctor} = this.props;

        return(
          <div className={this.props.className} style={{ display:"flex", flexDirection:"column", justifyContent: "flex-start", width:"100%", height:"100%" }}>
          <form>
            <div class="container emp-profile">
                <form method="post">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="profile-img">
                                <Avatar style={{height:"100%"}} imageUrl={doctor.profilePic} />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="profile-head">
                                        <h5>
                                            {doctor.titel+ " " +doctor.vorname + " " + doctor.nachname}
                                        </h5>
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">About</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-2">
                        <Button type="primary" shape="round" size="large" onClick={ () => this.setState({disabled:!this.state.disabled}) }>{this.state.disabled ? "Bearbeiten" : "Speichern"}</Button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="profile-work">
                                <p>IN DIESEN PRAXEN ARBEITE ICH</p>
                                <a href="">Praxis Website Link</a><br/>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="tab-content profile-tab" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <Form
                                      name="basic"
                                      onFinish={this.onFinish}
                                      initialValues={{vorname: doctor.vorname, nachname: doctor.nachname, email: doctor.email, fachgebiet:doctor.fachrichtung, zusatzausbildung:doctor.zusatzausbildung}}
                                      onFinishFailed={this.onFinishFailed}
                                          >
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>Vorname</label>
                                                </div>
                                                <div class="col-md-6">
                                                <Form.Item name="vorname">
                                                    <Input bordered={!this.state.disabled} disabled={this.state.disabled}/>
                                                </Form.Item>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>Nachname</label>
                                                </div>
                                                <div class="col-md-6">
                                                <Form.Item name="nachname">
                                                <Input bordered={!this.state.disabled} disabled={this.state.disabled} />
                                                </Form.Item>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>Email</label>
                                                </div>
                                                <div class="col-md-6">
                                                <Form.Item name="email">
                                                <Input bordered={!this.state.disabled} disabled={this.state.disabled} />
                                                </Form.Item>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>Telefon</label>
                                                </div>
                                                <div class="col-md-6">
                                                <Form.Item name="telefon">
                                                <Input bordered={!this.state.disabled} disabled={this.state.disabled} />
                                                </Form.Item>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>Fachgebiet</label>
                                                </div>
                                                <div class="col-md-6">
                                                <Form.Item name="fachgebiet">
                                                <Input bordered={!this.state.disabled} disabled={this.state.disabled} />
                                                </Form.Item>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>Zusatzausbildung</label>
                                                </div>
                                                <div class="col-md-6">
                                                <Form.Item name="zusatzausbildung">
                                                <Input bordered={!this.state.disabled} disabled={this.state.disabled} />
                                                </Form.Item>
                                                </div>
                                            </div>
                                            </Form>
                                </div>
                                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>Experience</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p>Expert</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>Hourly Rate</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p>10$/hr</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>Total Projects</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p>230</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>English Level</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p>Expert</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>Availability</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p>6 months</p>
                                                </div>
                                            </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label>Your Bio</label><br/>
                                            <p>Your detail description</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div classname="textField" noValidate autoComplete="off">
                <h5 style={{textAlign:"center"}}>
                    Über mich
                </h5>
                  <TextField
                    id="standard-full-width"
                    style={{ margin: 8 }}
                    helperText="Erzählen Sie hier ihren Kunden etwas über sich."
                    fullWidth
                    onChange={ (event) => this.setState({edited:true, about: event.target.value}) }
                    multiline
                    rows={10}
                    margin="normal"
                    variant="outlined"
                    disabled={this.state.disabled}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", paddingTop: 10}}>
                      <h5>
                    Praxisbilder Upload
                      </h5>
                    <ImageUpload disabled={this.state.disabled}/>
                    <div style={{height:"50px"}}/>
                    <OpeningHours disabled={this.state.disabled}/>
                </div>
                <div>
                    <LineDiagramView></LineDiagramView>
                </div>
            </div>
          </form>
          </div>
        );
    }

}

export default ProfilePage
