import React from "react"
import Logo from "./assets/HelloVetLogoPro.png"
import Path from './assets/Path7.png'
import Dot from './assets/Dot.png'
import Dot2 from './assets/Dot2.png'
import { Link } from "react-router-dom";
import { Layout, Button, Form, Input } from 'antd';
import DatabaseService from "../services/database.service"
import RocketChatAPI from "@rocket.chat/sdk/lib/api/RocketChat"
import store from 'store'
import ChatMGMT from "../http-chat"

const { Header, Content, Footer } = Layout;

class WaitingRoom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            connected: false,
            checkInterval: null,
            id: -1,
            data: {}
        }
    }

    componentDidUpdate(prevProps) {

    }

    confirm() {
        DatabaseService.vet.meeting
                    .updateConfirmed(this.props.roomID)
                    .then(response => {
                        console.log("WaitingRoom.js: vet.meeting.update() " + response);
                    })
                    .catch(e => {
                        console.log(e);
                    });
    }

    render() {
        return (
            <Layout className="layout" style={{ backgroundColor: 'white', height: "100vh" }}>
                <Header style={{ backgroundColor: 'transparent' }}>
                    <Link to="/">
                        <img src={Logo} style={{ width: "200px", height: "55px" }} />
                    </Link>
                </Header>
                <Content style={{ height: "100%" }}>
                    <img src={Path} style={{ position: "absolute", left: "0%", width: "100%", top: "40%", zIndex: 0 }} />
                    <img src={Dot} style={{ position: "absolute", left: "20%", top: "300px", zIndex: 0 }} />
                    <img src={Dot2} style={{ position: "absolute", right: "0%", top: "60px", zIndex: 0 }} />
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        {!this.props.admin ?
                            <span style={{ fontSize: "3vw", fontFamily: "Recoleta", zIndex: 10 }} >Warten sie, bis sie freigegeben werden</span> :
                            <>

                            <span style={{ fontSize: "3vw", fontFamily: "Recoleta", zIndex: 10 }} >Sie sind Administrator und können die Sitzung starten</span> :
                            <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "45px", fontSize: 18 }} shape="round" onClick={() => { this.confirm(); }}>
                                Konferenz starten
                            </Button>

                            </>}
                    </div>
                </Content>
                <Footer style={{ backgroundColor: 'transparent', width: "100%", zIndex: 10 }}>
                    <span style={{ width: "100%", marginTop: 90, marginBottom: 50, textAlign: "center", fontSize: 16, fontFamily: "WorkSans", zIndex: 10 }} >Hello Vet GmbH, Alle Rechte vorbehalten.</span>
                </Footer>
            </Layout>
        );
    }

}

export default WaitingRoom
