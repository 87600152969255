import React from "react";
import { Input } from 'antd';
import BotClient from '@rocket.chat/sdk/clients/Bot';
import RocketChatAPI from "@rocket.chat/sdk/lib/api/RocketChat"
import 'react-chat-elements/dist/main.css'
import Messages from "./Messages"
import { v4 as uuidv4 } from 'uuid';
import ChatList from "./ChatList"
import ChatMGMT from "../../http-chat"
import 'dotenv/config';
import { sha256 } from 'js-sha256';



const USERNAME = "domi";
const PASSWORT = "Domi1406";

class ChatComp extends React.Component {

    constructor(props) {
        console.log("DashboardMainComponent.js: OBSOLETE??? this.props ", this.props);
        super(props);
        this.state = {
            RocketChat: new BotClient({ host: process.env.REACT_APP_HTTP_CHAT_BASE_URL, useSsl: true }),
            RestAPI: {},
            selectedChat: "",
            sendMessages: false,
            connected: false
        }
    }

    async componentDidMount() {
        try {
            await this.state.RocketChat.connect();
        }
        catch (e) {
            console.error("Connecting to RocketChat failed\nError:\n" + e.toString());
        }

        try {
            await this.state.RocketChat.login({ 
                user: this.props.profile.email, 
                password: sha256(this.props.profile.passwort)
            });
        }
        catch (e) {
            try {
                var response = await ChatMGMT.post('/create-account', {
                    mail: this.props.profile.email,
                    name: this.props.profile.name,
                    password: this.props.profile.passwort,
                    username: uuidv4()
                })
                if (response.data.success)
                    await this.state.RocketChat.login({ 
                        user: this.props.profile.email, 
                        password: sha256(this.props.profile.passwort)
                    });
            }
            catch (e) {
                return;
            }
        }
        this.setState({ RestAPI: new RocketChatAPI(this.state.RocketChat) }, () => {
            if (this.state.RestAPI.loggedIn()) {
                console.log("RestApi logged in")
            }
        });
        this.state.RestAPI.request('GET', "rooms.info", { roomName: this.props.chatName })
            .then(response => {
                console.log(response);
                this.setState({ connected: true, selectedChat: response.room._id });
            });
    }

    render() {
        if (!this.state.connected)
            return (
                <div />
            );

        return (
            <div style={{ height: "100%", width: "100%" }}>
                <Messages rocketChat={this.state.RocketChat} restAPI={this.state.RestAPI} selectedChat={this.state.selectedChat} />
            </div>
        );
    }

}

export default ChatComp