import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import { navigate } from './utils/constants'
import { Button} from 'antd';
import { PlusOutlined, ArrowLeftOutlined,  ArrowRightOutlined} from '@ant-design/icons';

class Toolbar extends React.Component {
  render() {
    let {
      localizer: { messages },
      label,
    } = this.props

    return (
      <div className="rbc-toolbar">
        <Button size="large" style={{ borderColor: "black", color: "black"}} ghost shape="round" icon={<ArrowLeftOutlined />} onClick={this.navigate.bind(null, navigate.PREVIOUS)}/>
        <span className="rbc-toolbar-label">{label}</span>
        <Button size="large" style={{ borderColor: "black", color: "black"}} ghost shape="round" icon={<ArrowRightOutlined />} onClick={this.navigate.bind(null, navigate.NEXT)}/>
      </div>
    )
  }

  navigate = action => {
    this.props.onNavigate(action)
  }

  view = view => {
    this.props.onView(view)
  }

  viewNamesGroup(messages) {
    let viewNames = this.props.views
    const view = this.props.view

    if (viewNames.length > 1) {
      return viewNames.map(name => (
        <button
          type="button"
          key={name}
          className={clsx({ 'rbc-active': view === name })}
          onClick={this.view.bind(null, name)}
        >
          {messages[name]}
        </button>
      ))
    }
  }
}

Toolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
}

export default Toolbar
