import React from "react";
import _ from 'lodash'
import { motion, AnimatePresence } from "framer-motion"

class Local extends React.Component {
    constructor(props){
        super(props);
        console.log("Local.js: props: ", this.props);

        this.state = {
            selectedMicDeviceId: 'none',
            selectedVideoDeviceId: 'none',
            loaded: false,
            trackList: [],
            screenTrack: null,
            mouseIsHovering: false
        }
        this.videoRef = React.createRef();
    }

    componentDidMount () {

        // https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-ljm-api#getting-started
        window.JitsiMeetJS.createLocalTracks({
            devices: ['audio', 'video']
        })
        .then((tracks) => {
            var trackList = [];
            let deviceIds = _.map(this.props.deviceList, (nd) => nd.id)
            for (let track of tracks) {
                if (_.indexOf(deviceIds, track.deviceId) !== -1) {
                    trackList.push(track)
                }
            }
            this.setState({
                loaded: true,
                deviceList: this.props.deviceList,
                selectedMicDeviceId: this.props.defaultMicId,
                selectedVideoDeviceId: this.props.defaultVideoId,
                trackList: trackList
            }, () => {
                this.updateLocalTrack(this.props.defaultVideoId)

                if (this.props.activeRoom) {
                    let videoTrack = _.find(this.state.trackList, (t) => { return t.deviceId === this.props.defaultVideoId })
                    let micTrack = _.find(this.state.trackList, (t) => { return t.deviceId === this.props.defaultMicId })
                    if (videoTrack) {
                        this.props.activeRoom.addTrack(videoTrack)
                    }
                    if (micTrack) {
                        this.props.activeRoom.addTrack(micTrack)
                    }
                }
            })
        })
        .catch(e =>
            console.log(e)
            );


    }

    startDesktopSharing(){
        window.JitsiMeetJS.createLocalTracks({ devices: ['desktop']})
        .then(async (tracks) => {
            const track = tracks[0];
            this.setState({ screenTrack: track })
            let videoTrack = _.find(this.state.trackList, (t) => { return t.deviceId === this.state.selectedVideoDeviceId })
            if (videoTrack) {
                await this.props.activeRoom.removeTrack(videoTrack)
                videoTrack.detach(this.videoRef.current)
            }
            if (track && this.videoRef.current){
                this.props.activeRoom.addTrack(track)
                track.attach(this.videoRef.current)
            }

        })
        .catch(e =>
            console.log(e)
        );
    }

    async stopScreenSharing() {
        if(this.state.screenTrack!==null){
            this.state.screenTrack.detach(this.videoRef.current)
            await this.props.activeRoom.removeTrack(this.state.screenTrack)
            this.state.screenTrack.dispose()
            this.setState({screenTrack: null});
        }
        let videoTrack = _.find(this.state.trackList, (t) => { return t.deviceId === this.state.selectedVideoDeviceId })
        if (videoTrack && this.videoRef.current){
            this.props.activeRoom.addTrack(videoTrack)
            videoTrack.attach(this.videoRef.current)
        }

    }

    componentDidUpdate(prevProps){
        if(!prevProps.desktopSharingEnabled && this.props.desktopSharingEnabled){
            this.startDesktopSharing();
        }
        else if(prevProps.desktopSharingEnabled && !this.props.desktopSharingEnabled){
            this.stopScreenSharing();
        }

        if(this.state.selectedMicDeviceId !== this.props.defaultMicId){
            this.setState({
                selectedMicDeviceId: this.props.defaultMicId,
            }, () => {
                if (this.props.activeRoom) {
                    let micTrack = _.find(this.state.trackList, (t) => { return t.deviceId === this.props.defaultMicId })
                    if (micTrack) {
                        this.props.activeRoom.addTrack(micTrack)
                    }
                }
            })
        }

        if(this.state.selectedVideoDeviceId !== this.props.defaultVideoId){
            this.setState({
                selectedVideoDeviceId: this.props.defaultVideoId
            }, () => {
                this.updateLocalTrack(this.props.defaultVideoId)

                if (this.props.activeRoom) {
                    let videoTrack = _.find(this.state.trackList, (t) => { return t.deviceId === this.props.defaultVideoId })
                    if (videoTrack) {
                        this.props.activeRoom.addTrack(videoTrack)
                    }
                }
            })
        }
    }

    async componentWillUnmount(){
        if (this.props.activeRoom) {
            let videoTrack = _.find(this.state.trackList, (t) => { return t.deviceId === this.state.selectedVideoDeviceId })
            let micTrack = _.find(this.state.trackList, (t) => { return t.deviceId === this.state.selectedMicDeviceId })
            if(this.state.screenTrack!==null){
                this.state.screenTrack.detach(this.videoRef.current)
                await this.props.activeRoom.removeTrack(this.state.screenTrack)
                this.state.screenTrack.dispose()
                this.setState({screenTrack: null});
            }
            else if (videoTrack) {
                videoTrack.detach(this.videoRef.current)
                await this.props.activeRoom.removeTrack(videoTrack)
                videoTrack.dispose()
            }
            if (micTrack) {
                await this.props.activeRoom.removeTrack(micTrack)
                micTrack.dispose()
            }
        }
    }

    updateLocalTrack = (deviceId) => {
        let setTrack = _.find(this.state.trackList, (t) => { return t.deviceId === deviceId });
        if (setTrack && this.videoRef.current) {
            setTrack.attach(this.videoRef.current)
        }
    }

    mouseLeaveEvent = () =>{
        setTimeout(function(){
            this.setState({mouseIsHovering:false});
       }.bind(this),4000);
    }

    render(){
        return(
        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
            <span style={{fontSize:18}}>{ this.props.name ? this.props.name : "Ich" }</span>
            <div
                onMouseEnter={() => this.setState({ mouseIsHovering:true })}
                onMouseLeave={() => this.mouseLeaveEvent()}
                style={{position: "relative", backgroundColor:"white", width:"100%", height:"100%",  borderRadius:5, borderColor:"#25D2AA", borderStyle:"solid", borderWidth:"3px", boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
            >
                <video autoPlay='1' ref={this.videoRef} style={{width:"100%", height:"100%",  borderRadius:2}}/>
                <AnimatePresence>
                {this.state.mouseIsHovering &&
                <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{position: "absolute", bottom: "15px", left:"50%" }}
                >
                    <div style={{position: "relative", left:"-50%" }}>
                        {this.props.children}
                    </div>
                </motion.div>
                }
                </AnimatePresence>
            </div>
         </div>
        )

    }
}

export default Local
