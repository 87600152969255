import React, { useEffect } from "react"
import ConferenceComp from "./doctorsDashboard-redesign/novics-jitsi-integration/ConferenceComp"
import { notification, Card, Avatar, Tooltip, Descriptions, Button, Modal, Input, Select } from 'antd';
import store from 'store'
import { Redirect } from "react-router-dom";
import DatabaseService from "../services/database.service"
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Chat from "./chat/ConferenceChat"
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import VetinarLogo from "./assets/Vetinar-logo.png"
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Poll from './CreatePoll';

const drawerWidth = 600;

const pollQuestion = 'Is react-polls useful?'
const initialPollAnswers = [
    { option: 'Yes', votes: 9 },
    { option: 'No', votes: 4 }
]

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        zIndex: 300
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));


function ConferencePage(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [redirect, setRedirect] = React.useState(false);
    const [pollActive, setPoll] = React.useState(false);
    const [pollAnswers, setPollAnswers] = React.useState([...initialPollAnswers]);

    const handleVote = voteAnswer => {
        const newPollAnswers = pollAnswers.map(answer => {
            if (answer.option === voteAnswer) answer.votes++
            return answer
        });
        setPollAnswers(newPollAnswers);
    }

    const toogleDrawer = () => {
        setOpen(currVal => !currVal);
    };

    const stopSession = () => {
        setRedirect(true);
    };

    const removeOption = index => {
        setPollAnswers(pollAnswers.filter((e, i) => index !== i))
    }

    const addOption = option => {
        setPollAnswers(pollAnswers.concat(option))
    }

    if (redirect)
        return (
            <Redirect push to="/" />
        );

    console.log("ConferencePage: Render ConferenceComp");
    return (
        <body>
            <div className={classes.root}>
                <CssBaseline />
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <IconButton onClick={toogleDrawer} style={{ position: "absolute", top: "10px", right: "10px", zIndex: 20 }}>
                        <ChevronLeftIcon style={{ fontSize: 40, color: "black" }} />
                    </IconButton>
                    <div style={{ height: "100%", width: "100%", marginTop: 20 }}>
                        {props.profilesLoaded ? <Chat chatName={props.roomID} profile={props.profile} /> : null}
                    </div>
                </Drawer>
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: open,
                    })}
                >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1, display: "flex", alignItems: "flex-start" }}>
                            <img width={280} src={VetinarLogo} alt="Logo" />
                        </div>
                        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
                            <span style={{ fontSize: 20, fontWeight: "bold" }}>{props.meeting.label}</span>
                        </div>
                        <div style={{ flex: 1, display: "flex", justifyContent: "center" }} >
                            <img height="100px" src="https://i.pinimg.com/originals/fb/a5/7a/fba57ac41e9f8d98e5e9c977775933e5.jpg" />
                        </div>
                    </div>
                    <ConferenceComp chatOpen={toogleDrawer} name={props.profile && (props.profile.vorname ? props.profile.titel + " " + props.profile.vorname + " " + props.profile.nachname : props.profile.name)} stopSession={stopSession} roomName={props.roomID} />
                </main>
                <Dialog onClose={() => setPoll(false)} open={pollActive}>
                    <DialogTitle>Set backup account</DialogTitle>
                    <Poll question={pollQuestion} answers={pollAnswers} onVote={handleVote} customStyles={{ theme: 'cyan', questionSeparator: true }} removeOption={removeOption} addOption={addOption} />
                </Dialog>
            </div>
        </body>
    )

}


export default ConferencePage
