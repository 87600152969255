import React, { useEffect } from "react"
import "./LineDiagramView.css"
import Chart from "chart.js"

export default function LineDiagramView() {
  useEffect(() => {
    const ctx = document.getElementById("myChart");
    new Chart(ctx, {
        type: 'line',
        data: {
          labels: [2020, 2021, 2022, 2023, 2024, 2025],
          datasets: [{ 
              data: [4, 4.5, 3, 3.5, 4, 4],
              label: "Hase: Fluffy",
              borderColor: "#3e95cd",
              fill: false
            }
          ]
        },
        options: {
          title: {
            display: true,
            text: 'Gewicht der Patienten (in Kilogramm)'
          }
        }
      });
  });
  return (
    <div style={{width:"1000px", height:"1000px"}}>
      <canvas id="myChart" width="900" height="400" />
    </div>
  );
}