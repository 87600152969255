import React from "react";
import { Input, PageHeader, Upload, Popover, Button } from 'antd';
import { SendOutlined, PaperClipOutlined } from '@ant-design/icons';
import { MessageList } from 'react-chat-elements';
import axios from "axios"
import FileManager from "./FileManager"
import Dropzone from 'react-dropzone'
import 'dotenv/config';

const { Search } = Input;
const { Dragger } = Upload;

class Messages extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            selectedFile: null,
            showFiles: false
        }
    }

    downloadFile = (e) => {
        window.location.href = "https://yoursite.com/src/assets/files/exampleDoc.pdf";
    }

    onMessageRecieved(msg) {
        const messageList = msg.fields.args[0].map(msg => {
            if (msg.file && msg.file.type.startsWith("image"))
                return {
                    position: msg.u.username === "domi" ? "right" : "left", type: "photo", text: msg.file.name, date: new Date(msg.ts.$date), data: {
                        uri: process.env.REACT_APP_HTTP_CHAT_BASE_URL + msg.attachments[0].image_url,
                        width: 300,
                        height: 200,
                        status: {
                            loading: 0,
                        }
                    }
                };

            if (msg.file)
                return {
                    position: msg.u.username === "domi" ? "right" : "left", type: "file", text: msg.file.name, date: new Date(msg.ts.$date), data: {
                        uri: process.env.REACT_APP_HTTP_CHAT_BASE_URL + msg.attachments[0].image_url,
                        status: {
                            loading: 0,
                        }
                    }
                };

            if (msg.t)
                return { type: "system", text: msg.msg + "beigetreten" };

            return { position: msg.u.username === "domi" ? "right" : "left", type: "text", text: msg.msg, date: new Date(msg.ts.$date) };
        })
        messageList.reverse();
        this.setState({ messages: this.state.messages.concat(messageList) });
    }

    sendMessage(value) {
        if (value !== "")
            this.props.rocketChat.sendToRoomId(value, this.props.selectedChat);
    }

    sendFile(e) {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const uploadUrl = this.props.restAPI.client.host + "/api/v1/rooms.upload/" + this.props.selectedChat;
        axios({
            method: 'post',
            url: uploadUrl,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data', ...this.props.restAPI.client._headers }
        })
            .then((response) => {
                console.log(response);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    }

    sendDroppedFile(files) {
        const formData = new FormData();
        formData.append("file", files[0]);
        const uploadUrl = this.props.restAPI.client.host + "/api/v1/rooms.upload/" + this.props.selectedChat;
        axios({
            method: 'post',
            url: uploadUrl,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data', ...this.props.restAPI.client._headers }
        })
            .then((response) => {
                console.log(response);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    }

    async componentDidMount() {
        const discussion = await this.props.rocketChat.methodCall("loadHistory", this.props.selectedChat, null, 40);
        const messageList = discussion.messages.map(msg => {
            if (msg.file && msg.file.type.startsWith("image"))
                return {
                    position: msg.u.username === "domi" ? "right" : "left", type: "photo", text: msg.file.name, date: new Date(msg.ts.$date), data: {
                        uri: process.env.REACT_APP_HTTP_CHAT_BASE_URL + msg.attachments[0].image_url,
                        width: 300,
                        height: 200,
                        status: {
                            loading: 0,
                        }
                    }
                };

            if (msg.file)
                return {
                    position: msg.u.username === "domi" ? "right" : "left", type: "file", text: msg.file.name, date: new Date(msg.ts.$date), data: {
                        uri: process.env.REACT_APP_HTTP_CHAT_BASE_URL + msg.attachments[0].image_url,
                        status: {
                            loading: 0,
                        }
                    }
                };

            if (msg.t)
                return { type: "system", text: msg.msg + " ist beigetreten" };

            return { position: msg.u.username === "domi" ? "right" : "left", type: "text", text: msg.msg, date: new Date(msg.ts.$date) };
        })
        messageList.reverse();
        messageList.push();
        this.setState({ messages: messageList });

        const subscription = await this.props.rocketChat.subscribeToMessages();
        subscription.onEvent((msg) => this.onMessageRecieved(msg));
    }

    render() {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <Dropzone onDrop={acceptedFiles => this.sendDroppedFile(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ overflow: "auto", width: "100%" }}>
                                <Button block onClick={() => this.setState({ showFiles: !this.state.showFiles })}>Dateien</Button>
                                {
                                    this.state.showFiles &&
                                    <FileManager restAPI={this.props.restAPI} roomID={this.props.selectedChat} />
                                }
                                <MessageList
                                    className='message-list'
                                    lockable={true}
                                    style={{ width: "100%" }}
                                    toBottomHeight={'100%'}
                                    dataSource={this.state.messages} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", marginTop: 15, marginBottom: 15 }}>
                                <Search
                                    allowClear
                                    enterButton={<SendOutlined />}
                                    size="large"
                                    onSearch={(val) => this.sendMessage(val)}
                                />
                                <Popover placement="topRight" content={<input
                                    type="file"
                                    value={this.state.selectedFile}
                                    onChange={(e) => this.sendFile(e)}
                                />} trigger="click">
                                    <Button size="large" icon={<PaperClipOutlined />} />
                                </Popover>
                            </div>
                        </div>
                    )}
                </Dropzone>
            </div>
        );
    }

}

export default Messages