let defaultMessages = {
  date: 'Datum',
  time: 'Zeit',
  event: 'Event',
  allDay: 'Ganzer Tag',
  week: 'Woche',
  work_week: 'Arbeits Woche',
  day: 'Tag',
  month: 'Monat',
  previous: 'Zurück',
  next: 'Weiter',
  yesterday: 'Gestern',
  tomorrow: 'Morgen',
  today: 'Heute',
  agenda: 'Agenda',

  noEventsInRange: 'There are no events in this range.',

  showMore: total => `+${total} more`,
}

export default function messages(msgs) {
  return {
    ...defaultMessages,
    ...msgs,
  }
}
