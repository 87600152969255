import React from "react"
import { List, Button } from 'antd';
import { FileTwoTone } from '@ant-design/icons';

class FileManager extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            data:[]
        }
    }

    componentDidMount(){
        this.props.restAPI.request('GET', "groups.files", { roomId: this.props.roomID })
        .then(response => {
            this.setState({data:response.files});
        });
    }

    render() {
        return (
            <div>
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.data}
                    renderItem={item => (
                        <List.Item extra={<Button>Download</Button>}>
                            <List.Item.Meta
                                avatar={<FileTwoTone twoToneColor="#25D2AA" />}
                                title={<span>{item.name}</span>}
                            />
                            <span style={{marginRight:20}}>{new Date(item.uploadedAt).toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                        </List.Item>
                    )}
                />
            </div>
        );
    }

}

export default FileManager