import React from "react";
import "./App.css";
import "./landing-page/AppRealease.css"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import { Route, Switch } from "react-router-dom";
import Anmelden from "./components/Anmelden"
import Registrieren from "./components/Registrieren"
import RegistrierenValidated from "./components/RegistrierenStep2"
import DoctorsDashboard from "./components/doctorsDashboard-redesign/UImgmtComp"
import ValidationRequired from "./components/ValidationReqired"
import Conferencing from "./components/ConferenceController"

import UserHome from './landing-page/UserHomeRelease'
import UserHomeMobile from "./landing-page/UserHomeMobile"
import SoFunktionierts from './landing-page/SoFunktionierts'
import Fragen from "./landing-page/Fragen"
import Impressum from "./landing-page/Impressum"
import Datenschutz from "./landing-page/Datenschutz"
import Partner from "./landing-page/Partner"
import {
    BrowserView, MobileView
} from "react-device-detect";

function App() {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/vetDashboard">
          <DoctorsDashboard />
        </Route>
        <Route path="/login" component={Anmelden} />
        <Route path="/register" component={Registrieren} />
        <Route path= "/konferenz/:roomID" component={Conferencing} />
        <Route path="/register-validated/:token/:vetID" component={RegistrierenValidated}/>
        <Route path="/validation-required" component={ValidationRequired}/>
        <Route path="/datenschutz">
        <Datenschutz/>
    </Route>

    <Route path="/impressum">
      <Impressum />
    </Route>

    <Route path="/manual">
      <SoFunktionierts />
    </Route>

    <Route path="/qa">
      <Fragen />
    </Route>

    <Route path="/newsletter">
      <Anmelden />
    </Route>

        <Route path="/partner">
          <Partner/>
        </Route>

    <Route path="/">
        <BrowserView>
          <UserHome />
        </BrowserView>

          <MobileView>
            <UserHomeMobile />
          </MobileView>
    </Route>
      </Switch>
    </React.Fragment>
  );
}

export default App;
