// @flow

import React from 'react';

/**
 * React {@code Component} representing an button used for testing output sound.
 *
 * @returns { ReactElement}
 */
export default function TestButton({ onClick }) {
    return (
        <div
            className = 'audio-preview-test-button'
            onClick = { onClick }>
            Test
        </div>
    );
}
