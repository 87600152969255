import React from 'react';
import { Row, Col, Spin, Space } from 'antd'
import CalendarHistory from "./CalendarHistory"
import AppointmentsrequestCard from "./AppointmentrequestCard"
import NextPatient from "./NextPatient"
import PatientsCard from "./PatientsCard"
import CallHistoryCard from "./CallHistoryCard"
import LastPatient from "./LastPatient"
import ChatCard from "./ChatCard"
import { isMobile } from 'react-device-detect';


class DashboardMainComponent extends React.Component {

    constructor(props) {
        super(props);
        console.log("DashboardMainComponent.js: this.props ", this.props);
        this.state = {
            mobile: isMobile
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.sessionActive && prevProps.sessionActive) {
            console.log("session Deactivated")
        }
    }

    render() {
        if (this.props.currentKey === "patienten")
            return (
                <Row gutter={[32, 32]} style={{ margin: 30 }}>

                    <Col span={this.state.mobile ? 24 : 12}>
                        <LastPatient startConf={this.props.startConf} />
                    </Col>
                    <Col span={this.state.mobile ? 24 : 12}>
                        <NextPatient startConf={this.props.startConf} currentKey={this.props.currentKey} />
                    </Col>
                    <Col span={24}>
                        <PatientsCard startConf={this.props.startConf} />
                    </Col>
                </Row>
            );

        return (
            <Row gutter={[32, 32]} style={{ margin: 30 }}>
                <Col span={this.state.mobile ? 24 : 12}>
                    <NextPatient startConf={this.props.startConf} currentKey={this.props.currentKey} />
                    <div style={{ height: 35 }} />
                    <AppointmentsrequestCard startConf={this.props.startConf} />
                </Col>

                <Col span={this.state.mobile ? 24 : 12}>
                    <CalendarHistory startConf={this.props.startConf} />
                </Col>
                <Col span={24}>
                    <CallHistoryCard startConf={this.props.startConf} />
                </Col>
                <Col span={this.state.mobile ? 24 : 12}>
                    <ChatCard doctor={this.props.doctor} selectedChat={this.props.selectedChat} />
                </Col>
            </Row>
        );
        {/* <PatientsCard startConf={ this.props.startConf } />
                <ColleaguesCard vet={this.props.doctor} />
                <AppointmentsrequestCard events={this.props.events}/>
                <CallHistoryCard/>
                <RecordingsCard vetID={this.props.doctor.id}/>
                <CalendarCard updateEvents={this.props.updateEvents} events={this.props.events}/>
        <MarkerCard markers={this.props.markers} timerTime={this.props.timerTime} setMarkers={this.props.setMarkers}/>*/}

    }


}

export default DashboardMainComponent