import React from 'react';
import Logo from "./assets/HelloVetPetLogo.png"
import { Layout, Row, Col } from 'antd';
import LineFooter from "./assets/LineFooter.png"
import Dot1 from "./assets/Dot1Impressum.png"
import Dot2 from "./assets/Dot2Impressum.png"
import Line from "./assets/LineImpressum.png"
import Background from "./assets/ImpressumBackg.png"
import Partner from "./assets/koop_2x.png"
import Arndt from "./assets/Arndt.jpg"
import Daniel from "./assets/Daniel.jpg"
import Dots from "./assets/DotsImpressum.png"
import RosengartenLogo from "./assets/rosengarten.png"
import TierheimheldenLogo from "./assets/tierheimhelden.svg"
import LogoLMU from "./assets/LogoLMU.jpg"
import LogoEXIST from "./assets/Logo-EXIST.png"
import {
  LinkedinFilled
} from '@ant-design/icons';
import {
  Link
} from "react-router-dom";
import "./Impressum.css"

const { Header, Content, Footer } = Layout;


function Impressum(){
  return (
  <Layout className="layout" style={{ backgroundColor:"white", backgroundImage: `url(${Background})`, backgroundRepeat:"no-repeat", backgroundSize:"contain" }}>
    <Header style={{ backgroundColor: 'transparent' }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
          <Link to="/">
             <img src={Logo} style={{ width: "260px" }} />
          </Link>
        </div>
    </Header>
    <Content >
    <img src={Dot1} className="dot"/>
    <img src={Dot2} style={{position:"absolute", top:"0%", right:"0%", maxWidth:"20vw", maxHeight:"40vh",height:"auto", width:"auto", zIndex:5}}/>

    <img src={Dots} style={{position:"absolute", top:"140%", left:"0%", height:"20vh", zIndex:5}}/>


    <div style={{padding:10, display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", zIndex:5}}>
      <span className="heading">Impressum</span>

        <div>

        <div>
        <span className="impressum-heading">Veticine Ventures GmbH</span>
        </div>
        <div>
        <span className="impressum">Tiergesundheitsportal - HelloVet.de</span>
          </div>
            <div>
        <span className="impressum">Devern 13</span>
          </div>
          <div>
        <span className="impressum">49635 Badbergen</span>
        </div>
        <div>
        <span className="impressum">Deutschland</span>
        </div>
        <div>
        <span className="impressum-heading">Kontakt: </span>
        <span className="impressum">Kontaktformular</span>
        </div>
        <div>
        <span className="impressum-heading">Telefon: </span>
        <span className="impressum">0176-21140756</span>
        </div>
        <div>
        <span className="impressum-heading">E-Mail: </span>
        <span className="impressum"> info@hellovet.de</span>
        </div>
        <div>
        <span className="impressum-heading">Internet: </span>
        <span className="impressum">www.hellovet.de</span>
        </div>
        <div>
        <span className="impressum-heading">Vertretungsberechtigte Geschäftsführer: </span>
        <span className="impressum">Daniel Medding (Tierarzt), Arndt Nietfeld</span>
        </div>
        <div>
        <span className="impressum-heading">Registergericht: </span>
        <span className="impressum">Amtsgericht Osnabrück</span>
        </div>
        <div>
        <span className="impressum-heading">Handelsregisternummer: </span>
        <span className="impressum">HRB 211216</span>
        </div>
        <div>
        <span className="impressum-heading">Finanzamt Osnabrück USt.Id.: </span>
        <span className="impressum">DE310308505</span>
        </div>
        </div>

        <img src={Line} style={{width:"100vw",marginTop:100,  marginBottom: -120, zIndex:0}}/>

        <span className="heading">Gründer</span>
        <div style={{display:"flex", flexDirection:"column", maxWidth:"1100px"}}>
          <div style={{height: "100px"}}/>
          <Row gutter={[48, 48]}>
              <Col span={12} style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <img src={Daniel} style={{borderRadius:"20px", width:"100%", height:"auto"}}/>
                <a href="https://www.linkedin.com/in/danielmedding/">
                <LinkedinFilled style={{marginTop:10, fontSize:40}}/>
                </a>
              </Col>
              <Col span={12}>
                <div style={{ height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                 <span className="gründer-heading">Daniel Medding</span>
                 <span className="gründer-desc">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</span>
                </div>
              </Col>
          </Row>
          <div style={{height: "100px"}}/>
          <Row gutter={[48, 48]}>
             <Col span={12}>
                <div style={{height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                 <span className="gründer-heading">Arndt Nietfeld</span>
                 <span className="gründer-desc">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</span>
                </div>
              </Col>
              <Col span={12} style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <img src={Arndt} style={{borderRadius:"20px", width:"100%", height:"auto"}}/>
                <a href="https://www.linkedin.com/in/arndt-nietfeld-256352144/">
                <LinkedinFilled style={{marginTop:10, fontSize:40}}/>
                </a>
              </Col>
          </Row>
        </div>

        <div style={{height:"100px"}}/>


        <img src={LineFooter} style={{marginTop:50, width:"100%", zIndex:0}}/>

        <div style={{  display: "flex", flexDirection: "row" }}>
          <div style={{paddingRight:30}}>
          <img src={Logo} className="logo" />
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
            <span className="footer-headings" >Rechtliches</span>
            <Link className="footer-elements" to="/datenschutz" >
            <span  >Datenschutz</span>
            </Link>
            <Link className="footer-elements" to="/impressum" >
            <span  >Impressum</span>
            </Link>
          </div>
        </div>
        <span style={{ marginTop: 90, marginBottom: 50, fontSize: 16, fontFamily: "WorkSans" }} >Veticine Ventures GmbH, Alle Rechte vorbehalten.</span>
        </div>
    </Content>
    </Layout>
  );

}

export default Impressum
