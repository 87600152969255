
class MeetingAction {
    /**
     * Meeting Action Class
     */
    constructor(axios) {
        this.http = axios;        
    }


    /**
     * Create one meeting
     * @param {Object} data 
     * @returns Promise object
     */
    create(data) {
        return this.http.post("/api/meetings/create.php", data);
    };
    /**
     * Read all meeting
     * @returns Promise object
     */
    read() {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Read one meeting
     * @param {Object} id meeting id
     * @returns Promise object
     */
    readOne(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Update one meeting
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Delete one meeting
     * @param {String} name meeting name
     * @returns Promise object
     */
    delete(name) {
        return this.http.delete(`/api/meetings/delete.php?id=${name}`);
    };
}

export default MeetingAction;