import http from "../http-node-service";

class UploadService {

    getFileList(id) {
        return http.get(`/getFileList/${id}`);
    }

    getCallDirList(id) {
        return http.get(`/getCallDirList/${id}`);
    }

    getFile(fI) {
        return http.get("/getUsrFile/" + fI, {
                responseType: 'arraybuffer'
            },
        );
    }

    getVetFile(fI) {
        return http.post("/getVetFile", fI, {
                responseType: 'arraybuffer'
            },
        );
    }

    sessionActive(id) {
        return http.get(`/sessionActive/${id}`);
    }

    sessionEnded(id) {
        return http.get(`/sessionEnded/${id}`);
    }

    submitSession(data) {
        return http.post("/submitSession", data);
    }

    submitMarkers(data) {
        return http.post("/submitMarkers", data);
    }
}

export default new UploadService();